import { put, call, takeLatest, all } from 'redux-saga/effects'
import Api from '../Api'
import { Types } from '../ducks/alert'
import { Types as AppTypes } from '../ducks/app'
import { del } from 'request-promise'

function* getAlertActions(action) {
  try {
    let payload = {...action.payload, expired: false}
    const response = yield call(Api.getAlertActions, payload)
    yield put({type: Types.GET_ALERT_ACTIONS_SUCCESS, payload: response})
  } catch (error) {
    yield put({type: Types.GET_ALERT_ACTIONS_FAILURE, payload: error.message})
  }
}

function* getMonitoingAlerts(action) {
  try {
    let payload = {...action.payload, expired: false}
    const response = yield call(Api.getAlerts, payload)
    yield put({type: Types.GET_MONITORING_ALERTS_SUCCESS, payload: response})
  } catch (error) {
    yield put({type: Types.GET_MONITORING_ALERTS_FAILURE, payload: error.message})
  }
}

function* getTriggeredAlerts(action) {
  try {
    let payload = {...action.payload, triggered: true}
    const response = yield call(Api.getAlerts, payload)
    yield put({type: Types.GET_TRIGGERED_ALERTS_SUCCESS, payload: response})
  } catch (error) {
    yield put({type: Types.GET_TRIGGERED_ALERTS_FAILURE, payload: error.message})
  }
}

function* getExpiredAlerts(action) {
  try {
    let payload = {...action.payload, expired: true}
    const response = yield call(Api.getAlerts, payload)
    yield put({type: Types.GET_EXPIRED_ALERTS_SUCCESS, payload: response})
  } catch (error) {
    yield put({type: Types.GET_EXPIRED_ALERTS_FAILURE, payload: error.message})
  }
}

function* saveAlert(action) {
  try {
    const response = yield call(Api.saveAlert, action.payload)
    yield put({type: Types.SAVE_ALERT_SUCCESS, payload: response})
    yield put({type: Types.GET_MONITORING_ALERTS})
    yield put({type: Types.GET_EXPIRED_ALERTS})
    yield put({type: Types.CLOSE_ALERT_FORM})
    yield put({
      type: AppTypes.SHOW_SNACKBAR, 
      payload: {
        type: 'success', 
        title: 'Alerta salvo!', 
        text: response, 
      }
    })
  } catch (error) {
    yield put({type: Types.SAVE_ALERT_FAILURE, payload: error.message})
    yield put({
      type: AppTypes.SHOW_SNACKBAR, 
      payload: {
        type: 'danger', 
        title: 'Ops!', 
        text:  error.message, 
      }
    })
  }
}

function* deleteAlert(action) {
  try {
    const response = yield call(Api.deleteAlert, action.payload)
    yield put({type: Types.DELETE_ALERT_SUCCESS, payload: response})
    yield put({type: Types.GET_MONITORING_ALERTS})
    yield put({type: Types.GET_EXPIRED_ALERTS})
    yield put({
      type: AppTypes.SHOW_SNACKBAR, 
      payload: {
        type: 'success', 
        title: 'Alerta excluido!', 
        text: response, 
      }
    })
  } catch (error) {
    yield put({type: Types.DELETE_ALERT_FAILURE, payload: error.message})
    yield put({
      type: AppTypes.SHOW_SNACKBAR, 
      payload: {
        type: 'danger', 
        title: 'Ops!', 
        text: error.message, 
      }
    })
  }
}

function* getRuleFields(action) {
  try {
    const response = yield call(Api.getRuleFields, action.payload)
    yield put({type: Types.GET_RULE_FIELDS_SUCCESS, payload: response})
  } catch (error) {
    yield put({type: Types.GET_RULE_FIELDS_FAILURE, payload: error.message})
  }
}

function* enableAlert(action) {
  try {
    const response = yield call(Api.enableAlert, action.payload)
    yield put({type: Types.ENABLE_ALERT_SUCCESS, payload: response})
    yield put({type: Types.GET_MONITORING_ALERTS})
    yield put({type: Types.GET_EXPIRED_ALERTS})
  } catch (error) {
    yield put({type: Types.ENABLE_ALERT_FAILURE, payload: error.message})
  }
}

function* disableAlert(action) {
  try {
    const response = yield call(Api.disableAlert, action.payload)
    yield put({type: Types.DISABLE_ALERT_SUCCESS, payload: response})
    yield put({type: Types.GET_MONITORING_ALERTS})
    yield put({type: Types.GET_EXPIRED_ALERTS})
  } catch (error) {
    yield put({type: Types.DISABLE_ALERT_FAILURE, payload: error.message})
  }
}

function* remonitorAlert(action) {
  try {
    const response = yield call(Api.remonitorAlert, action.payload)
    yield put({type: Types.REMONITOR_ALERT_SUCCESS, payload: response})
    yield put({type: Types.GET_MONITORING_ALERTS})
    yield put({type: Types.GET_EXPIRED_ALERTS})
    yield put({
      type: AppTypes.SHOW_SNACKBAR, 
      payload: {
        type: 'success', 
        title: 'Remonitorando alerta!', 
        text: response, 
      }
    })
  } catch (error) {
    yield put({type: Types.REMONITOR_ALERT_FAILURE, payload: error.message})
    yield put({
      type: AppTypes.SHOW_SNACKBAR, 
      payload: {
        type: 'danger', 
        title: 'Ops!', 
        text: error.message, 
      }
    })
  }
}

function* duplicateAlert(action) {
  try {
    const response = yield call(Api.duplicateAlert, action.payload)
    yield put({type: Types.DUPLICATE_ALERT_SUCCESS, payload: response})
    yield put({type: Types.GET_MONITORING_ALERTS})
    yield put({type: Types.GET_EXPIRED_ALERTS})
    yield put({
      type: AppTypes.SHOW_SNACKBAR, 
      payload: {
        type: 'success', 
        title: 'Alerta duplicado!', 
        text: response, 
      }
    })
  } catch (error) {
    yield put({type: Types.DUPLICATE_ALERT_FAILURE, payload: error.message})
    yield put({
      type: AppTypes.SHOW_SNACKBAR, 
      payload: {
        type: 'danger', 
        title: 'Ops!', 
        text: error.message, 
      }
    })
  }
}

function* changeAlertActions(action) {
  try {
    const response = yield call(Api.changeAlertActions, action.payload)
    yield put({type: Types.CHANGE_ALERT_ACTIONS_SUCCESS, payload: response})
    yield put({type: Types.GET_ALERT_ACTIONS})
    // yield put({
    //   type: AppTypes.SHOW_SNACKBAR, 
    //   payload: {
    //     type: 'success', 
    //     title: 'Alerta duplicado!', 
    //     text: response, 
    //   }
    // })
  } catch (error) {
    yield put({type: Types.CHANGE_ALERT_ACTIONS_FAILURE, payload: error.message})
    yield put({
      type: AppTypes.SHOW_SNACKBAR, 
      payload: {
        type: 'danger', 
        title: 'Ops!', 
        text: error.message, 
      }
    })
  }
}

export default function* () {
  yield all([
    takeLatest(Types.GET_ALERT_ACTIONS, getAlertActions),
    takeLatest(Types.GET_MONITORING_ALERTS, getMonitoingAlerts),
    takeLatest(Types.GET_TRIGGERED_ALERTS, getTriggeredAlerts),
    takeLatest(Types.GET_EXPIRED_ALERTS, getExpiredAlerts),
    takeLatest(Types.SAVE_ALERT, saveAlert),
    takeLatest(Types.DELETE_ALERT, deleteAlert),
    takeLatest(Types.GET_RULE_FIELDS, getRuleFields),
    takeLatest(Types.ENABLE_ALERT, enableAlert),
    takeLatest(Types.DISABLE_ALERT, disableAlert),
    takeLatest(Types.REMONITOR_ALERT, remonitorAlert),
    takeLatest(Types.DUPLICATE_ALERT, duplicateAlert),
    takeLatest(Types.CHANGE_ALERT_ACTIONS, changeAlertActions),
  ])
}
