import { delay } from 'redux-saga'
import { put, call, takeLatest, all } from 'redux-saga/effects'
import Api from '../Api'
import { Types as TypesApp } from '../ducks/app'
import { Types } from '../ducks/auth'

function* login(action) {
  try {
    const token = yield call(Api.login, action.payload)
    window.localStorage.setItem('token', token)
    yield call(delay, 1000);
    window.location.reload();
    // yield put({type: Types.LOGIN_SUCCESS})
    // yield put({type: Types.GET_AUTHENTICATED_USER})
  } catch (error) {
    yield put({type: Types.LOGIN_FAILURE, payload: error.message})
    yield put({type: TypesApp.OPEN_ALERT, payload: {title: 'Ops.', message: error.message}})
  }
}

function* logout(action) {
  try {
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('feedbackAskMeLater')
    yield put({type: Types.LOGOUT_SUCCESS})
  } catch (error) {
    yield put({type: Types.LOGOUT_FAILURE})
  }
}

function* getAuthenticatedUser(action) {
  try {
    const response = yield call(Api.getAuthenticatedUser)
    yield put({type: Types.GET_AUTHENTICATED_USER_SUCCESS, payload: response})
  } catch (error) {
    // if(error.code === 401){
      window.localStorage.removeItem('token')
      window.location.reload()
    // }
    yield put({type: Types.GET_AUTHENTICATED_USER_FAILURE, errorMessage: error.message})
  }
}

export default function* () {
  yield all([
    takeLatest(Types.LOGIN, login),
    takeLatest(Types.LOGOUT, logout),
    takeLatest(Types.GET_AUTHENTICATED_USER, getAuthenticatedUser),
  ])
}