import React, { useState } from "react";

import {
  Fab,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Popover,
  Tooltip,
  Button,
} from "@material-ui/core";

import FilterIcon from "@material-ui/icons/FilterList";

import "./style.sass";
import { useSelector } from "react-redux";

const FilterButton = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [disabilityEl, setDisabilityEl] = useState(null);
  const authenticatedUser = useSelector(
    (state) => state.auth.authenticatedUser
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickDisability = (event) => {
    setDisabilityEl(event.currentTarget);
  };

  const handleCloseDisability = () => {
    setDisabilityEl(null);
  };

  const onChangeOrder = (event) => {
    let dataToSend = {
      by: event.target.value.split("_")[0],
      order: event.target.value.split("_")[1],
    };
    props.onChangeOrder(dataToSend);
  };

  const onFilter = (event) => {
    const [type, value] = event.target.value.split("_");
    if (type === "disability") {
      switch (value) {
        case "no":
          props.onFilter({ [`${type}`]: false });
          break;
        case "yes":
          props.onFilter({ [`${type}`]: true });
          break;
        default:
          props.onFilter({ [`${type}`]: undefined });
      }
    }
  };

  return (
    <div className="filter-button">
      <Fab size="small" onClick={handleClick}>
        <Tooltip title="Ordenação">
          <FilterIcon fontSize="small" />
        </Tooltip>
      </Fab>
      <Popover
        className="filter-button-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="filter-button-menu-wrapper">
          <Typography className="title" variant="caption">
            Visualizar
          </Typography>
          <RadioGroup
            className="order-options"
            color="secondary"
            onChange={onChangeOrder}
            defaultValue="evasion_desc"
          >
            <FormControlLabel
              value="name_asc"
              control={<Radio size="small" color="primary" />}
              label="De A-Z"
            />
            <FormControlLabel
              value="evasion_desc"
              control={<Radio size="small" color="primary" />}
              label="Por ordem de maior evasão"
            />
            <FormControlLabel
              value="evasion_asc"
              control={<Radio size="small" color="primary" />}
              label="Por ordem de menor evasão"
            />
          </RadioGroup>
          {authenticatedUser.customFilter === true && (
            <Button fullWidth onClick={handleClickDisability}>
              PCD
            </Button>
          )}
        </div>
      </Popover>
      <Popover
        className="filter-button-menu"
        anchorEl={disabilityEl}
        keepMounted
        open={Boolean(disabilityEl)}
        onClose={handleCloseDisability}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <div className="filter-button-menu-wrapper">
          <RadioGroup
            className="order-options"
            color="secondary"
            onChange={onFilter}
            defaultValue="disability_all"
          >
            <FormControlLabel
              value="disability_no"
              control={<Radio size="small" color="primary" />}
              label="Não"
            />
            <FormControlLabel
              value="disability_yes"
              control={<Radio size="small" color="primary" />}
              label="Sim"
            />
            <FormControlLabel
              value="disability_all"
              control={<Radio size="small" color="primary" />}
              label="Todos"
            />
          </RadioGroup>
        </div>
      </Popover>
    </div>
  );
};

FilterButton.defaultProps = {
  onChangeOrder: () => {},
};

export default FilterButton;
