import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getTutors: ['payload'],
  getTutorsSuccess: ['payload'],
  getTutorsFailure: ['payload'],
  getTutorsNoFiltered: ['payload'],
  getTutorsNoFilteredSuccess: ['payload'],
  getTutorsNoFilteredFailure: ['payload'],
  getAllTutors: ['payload'],
  getAllTutorsSuccess: ['payload'],
  getAllTutorsFailure: ['payload'],
})

const INITIAL_STATE = {
  getTutorsLoading: false,
  page: 0,
  limit: 0,
  total: 0,
  list: [],
  getTutorsErrorMsg: null,
  getTutorsNoFilteredLoading: false,
  listNoFiltered: [],
  getAllTutorsLoading: false,
  listAll: [],
  getAllTutorsErrorMsg: null,
}

const getTutors = (state = INITIAL_STATE, action) => ({...state, getTutorsLoading: true})
const getTutorsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  getTutorsLoading: false,
  getTutorsErrorMsg: INITIAL_STATE.getTutorsErrorMsg,
  page: action.payload.page,
  limit: action.payload.limit,
  total: action.payload.total,
  list: action.payload.list,
})
const getTutorsFailure = (state = INITIAL_STATE, action) => ({
  ...state, 
  page: INITIAL_STATE.page,
  limit: INITIAL_STATE.limit,
  total: INITIAL_STATE.total,
  list: INITIAL_STATE.list,
  getTutorsLoading: false, 
  getTutorsErrorMsg: action.payload
})

const getTutorsNoFiltered = (state = INITIAL_STATE, action) => ({...state, getTutorsNoFilteredLoading: true})
const getTutorsNoFilteredSuccess = (state = INITIAL_STATE, action) => ({
  ...state, 
  getTutorsNoFilteredLoading: false,
  listNoFiltered: action.payload.list,
})
const getTutorsNoFilteredFailure = (state = INITIAL_STATE, action) => ({
  ...state, 
  getTutorsNoFilteredLoading: false,
  listNoFiltered: INITIAL_STATE.listNoFiltered,
})

const getAllTutors = (state = INITIAL_STATE, action) => ({...state, getAllTutorsLoading: true})
const getAllTutorsSuccess = (state = INITIAL_STATE, action) => ({
  ...state, 
  getAllTutorsLoading: false,
  listAll: action.payload.list,
})
const getAllTutorsFailure = (state = INITIAL_STATE, action) => ({
  ...state, 
  getAllTutorsLoading: false,
  listAll: INITIAL_STATE.listAll,
})


export default createReducer(INITIAL_STATE, {
  [Types.GET_TUTORS]: getTutors,
  [Types.GET_TUTORS_SUCCESS]: getTutorsSuccess,
  [Types.GET_TUTORS_FAILURE]: getTutorsFailure,
  
  [Types.GET_TUTORS_NO_FILTERED]: getTutorsNoFiltered,
  [Types.GET_TUTORS_NO_FILTERED_SUCCESS]: getTutorsNoFilteredSuccess,
  [Types.GET_TUTORS_NO_FILTERED_FAILURE]: getTutorsNoFilteredFailure,

  [Types.GET_ALL_TUTORS]: getAllTutors,
  [Types.GET_ALL_TUTORS_SUCCESS]: getAllTutorsSuccess,
  [Types.GET_ALL_TUTORS_FAILURE]: getAllTutorsFailure,
})