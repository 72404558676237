import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getAlertActions: ["payload"],
  getAlertActionsSuccess: ["payload"],
  getAlertActionsFailure: ["payload"],

  getMonitoringAlerts: ["payload"],
  getMonitoringAlertsSuccess: ["payload"],
  getMonitoringAlertsFailure: ["payload"],
  
  getTriggeredAlerts: ["payload"],
  getTriggeredAlertsSuccess: ["payload"],
  getTriggeredAlertsFailure: ["payload"],
  
  getExpiredAlerts: ["payload"],
  getExpiredAlertsSuccess: ["payload"],
  getExpiredAlertsFailure: ["payload"],

  openAlertForm: ["payload"],
  closeAlertForm: ["payload"],

  saveAlert: ["payload"],
  saveAlertSuccess: ["payload"],
  saveAlertFailure: ["payload"],

  deleteAlert: ["payload"],
  deleteAlertSuccess: ["payload"],
  deleteAlertFailure: ["payload"],

  getRuleFields: ["payload"],
  getRuleFieldsSuccess: ["payload"],
  getRuleFieldsFailure: ["payload"],

  enableAlert: ["payload"],
  enableAlertSuccess: ["payload"],
  enableAlertFailure: ["payload"],

  disableAlert: ["payload"],
  disableAlertSuccess: ["payload"],
  disableAlertFailure: ["payload"],

  remonitorAlert: ["payload"],
  remonitorAlertSuccess: ["payload"],
  remonitorAlertFailure: ["payload"],

  duplicateAlert: ["payload"],
  duplicateAlertSuccess: ["payload"],
  duplicateAlertFailure: ["payload"],

  changeAlertActions: ["payload"],
  changeAlertActionsSuccess: ["payload"],
  changeAlertActionsFailure: ["payload"],
});

const INITIAL_STATE = {
  getAlertActionsLoading: false,
  alertActionsPage: 0,
  alertActionsLimit: 0,
  alertActionsTotal: 0,
  alertActionsOrder: null,
  alertActionsList: [],
  getAlertActionsErrorMsg: null,
  
  getMonitoringAlertsLoading: false,
  monitoringPage: 0,
  monitoringLimit: 0,
  monitoringTotal: 0,
  monitoringOrder: null,
  monitoringList: [],
  getMonitoringAlertsErrorMsg: null,
  
  getTriggeredAlertsLoading: false,
  triggeredPage: 0,
  triggeredLimit: 0,
  triggeredTotal: 0,
  triggeredOrder: null,
  triggeredList: [],
  getTriggeredAlertsErrorMsg: null,

  getExpiredredAlertsLoading: false,
  expiredPage: 0,
  expiredLimit: 0,
  expiredTotal: 0,
  expiredOrder: null,
  expiredList: [],
  getExpiredredAlertsErrorMsg: null,

  // getAlertsErrorMsg: null,
  openAlertForm: false,
  selectedAlert: null,

  saveAlertLoading: false,
  saveAlertSuccessMsg: null,
  saveAlertErrorMsg: null,

  deleteAlertLoading: false,
  deleteAlertSuccessMsg: null,
  deleteAlertErrorMsg: null,

  getRuleFieldsLoading: false,
  ruleFieldList: [],

  enableAlertLoading: false,
  enableAlertSuccessMsg: null,
  enableAlertErrorMsg: null,

  disableAlertLoading: false,
  disableAlertSuccessMsg: null,
  disableAlertErrorMsg: null,

  remonitorAlertLoading: false,
  remonitorAlertSuccessMsg: null,
  remonitorAlertErrorMsg: null,

  duplicateAlertLoading: false,
  duplicateAlertSuccessMsg: null,
  duplicateAlertErrorMsg: null,

  changeAlertActionsLoading: false,
  changeAlertActionsSuccessMsg: null,
  changeAlertActionsErrorMsg: null,
    
};

const getAlertActions = (state = INITIAL_STATE, action) => ({
  ...state,
  getAlertActionsLoading: true,
});
const getAlertActionsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  getAlertActionsLoading: false,
  getAlertActionsErrorMsg: INITIAL_STATE.getMonitoringAlertsErrorMsg,
  alertActionsPage: action.payload.page,
  alertActionsLimit: action.payload.limit,
  alertActionsTotal: action.payload.total,
  alertActionsList: action.payload.list,
});
const getAlertActionsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  getAlertActionsLoading: false,
  getAlertActionsErrorMsg: action.payload,
  alertActionsPage: INITIAL_STATE.alertActionsPage,
  alertActionsLimit: INITIAL_STATE.alertActionsLimit,
  alertActionsTotal: INITIAL_STATE.alertActionsTotal,
  alertActionsList: INITIAL_STATE.alertActionsList,
});

const getMonitoringAlerts = (state = INITIAL_STATE, action) => ({
  ...state,
  getMonitoringAlertsLoading: true,
});
const getMonitoringAlertsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  getMonitoringAlertsLoading: false,
  getMonitoringAlertsErrorMsg: INITIAL_STATE.getMonitoringAlertsErrorMsg,
  monitoringPage: action.payload.page,
  monitoringLimit: action.payload.limit,
  monitoringTotal: action.payload.total,
  monitoringList: action.payload.list,
  monitoringOrder: action.payload.order,
});
const getMonitoringAlertsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  getMonitoringAlertsLoading: false,
  getMonitoringAlertsErrorMsg: action.payload,
  monitoringPage: INITIAL_STATE.monitoringPage,
  monitoringLimit: INITIAL_STATE.monitoringLimit,
  monitoringTotal: INITIAL_STATE.monitoringTotal,
  monitoringList: INITIAL_STATE.monitoringList,
  monitoringOrder: INITIAL_STATE.monitoringOrder,
});

const getTriggeredAlerts = (state = INITIAL_STATE, action) => ({
  ...state,
  getTriggeredAlertsLoading: true,
});
const getTriggeredAlertsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  getTriggeredAlertsLoading: false,
  getTriggeredAlertsErrorMsg: INITIAL_STATE.getTriggeredAlertsErrorMsg,
  triggeredPage: action.payload.page,
  triggeredLimit: action.payload.limit,
  triggeredTotal: action.payload.total,
  triggeredList: action.payload.list,
  triggeredOrder: action.payload.order,
});
const getTriggeredAlertsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  getTriggeredAlertsLoading: false,
  getTriggeredAlertsErrorMsg: action.payload,
  triggeredPage: INITIAL_STATE.triggeredPage,
  triggeredLimit: INITIAL_STATE.triggeredLimit,
  triggeredTotal: INITIAL_STATE.triggeredTotal,
  triggeredList: INITIAL_STATE.triggeredList,
  triggeredOrder: INITIAL_STATE.triggeredOrder,
});

const getExpiredAlerts = (state = INITIAL_STATE, action) => ({
  ...state,
  getExpiredAlertsLoading: true,
});
const getExpiredAlertsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  getExpiredAlertsLoading: false,
  getExpiredAlertsErrorMsg: INITIAL_STATE.getExpiredAlertsErrorMsg,
  expiredPage: action.payload.page,
  expiredLimit: action.payload.limit,
  expiredTotal: action.payload.total,
  expiredList: action.payload.list,
  expiredOrder: action.payload.order,
});
const getExpiredAlertsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  getExpiredAlertsLoading: false,
  getExpiredAlertsErrorMsg: action.payload,
  expiredPage: INITIAL_STATE.expiredPage,
  expiredLimit: INITIAL_STATE.expiredLimit,
  expiredTotal: INITIAL_STATE.expiredTotal,
  expiredList: INITIAL_STATE.expiredList,
  expiredOrder: INITIAL_STATE.expiredOrder,
});

const openAlertForm = (state = INITIAL_STATE, action) => ({
  ...state,
  openAlertForm: true,
  saveAlertSuccessMsg: INITIAL_STATE.saveAlertSuccessMsg,
  saveAlertErrorMsg: INITIAL_STATE.saveAlertErrorMsg,
  selectedAlert: action.payload,
});
const closeAlertForm = (state = INITIAL_STATE, action) => ({
  ...state,
  openAlertForm: false,
  saveAlertSuccessMsg: INITIAL_STATE.saveAlertSuccessMsg,
  saveAlertErrorMsg: INITIAL_STATE.saveAlertErrorMsg,
  selectedAlert: INITIAL_STATE.selectedAlert,
});

// const openEditAlertForm = (state = INITIAL_STATE, action) => ({
//   ...state,
//   openAlertForm: true,
// });
// const closeEditAlertForm = (state = INITIAL_STATE, action) => ({
//   ...state,
//   openAlertForm: false,
// });

const saveAlert = (state = INITIAL_STATE, action) => ({
  ...state,
  saveAlertLoading: true,
  saveAlertSuccessMsg: INITIAL_STATE.saveAlertSuccessMsg,
  saveAlertErrorMsg: INITIAL_STATE.saveAlertErrorMsg,
});
const saveAlertSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  saveAlertLoading: false,
  saveAlertSuccessMsg: action.payload,
  saveAlertErrorMsg: INITIAL_STATE.saveAlertErrorMsg,
});
const saveAlertFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  saveAlertLoading: false,
  saveAlertSuccessMsg: INITIAL_STATE.saveAlertSuccessMsg,
  saveAlertErrorMsg: action.payload,
});

const deleteAlert = (state = INITIAL_STATE, action) => ({
  ...state,
  deleteAlertLoading: true,
  deleteAlertSuccessMsg: INITIAL_STATE.deleteAlertSuccessMsg,
  deleteAlertErrorMsg: INITIAL_STATE.deleteAlertErrorMsg,
});
const deleteAlertSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  deleteAlertLoading: false,
  deleteAlertSuccessMsg: action.payload,
  deleteAlertErrorMsg: INITIAL_STATE.deleteAlertErrorMsg,
});
const deleteAlertFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  deleteAlertLoading: false,
  deleteAlertSuccessMsg: INITIAL_STATE.deleteAlertSuccessMsg,
  deleteAlertErrorMsg: action.payload,
});

const getRuleFields = (state = INITIAL_STATE, action) => ({
  ...state,
  getRuleFieldsLoading: true,
  ruleFieldList: INITIAL_STATE.ruleFieldList,
});
const getRuleFieldsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  getRuleFieldsLoading: false,
  ruleFieldList: action.payload,
});
const getRuleFieldsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  getRuleFieldsLoading: false,
  ruleFieldList: INITIAL_STATE.ruleFieldList,
});

const enableAlert = (state = INITIAL_STATE, action) => ({
  ...state,
  enableAlertLoading: true,
  enableAlertSuccessMsg: INITIAL_STATE.enableAlertSuccessMsg,
  enableAlertErrorMsg: INITIAL_STATE.enableAlertErrorMsg,
});
const enableAlertSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  enableAlertLoading: false,
  enableAlertSuccessMsg: action.payload,
  enableAlertErrorMsg: INITIAL_STATE.enableAlertErrorMsg,
});
const enableAlertFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  enableAlertLoading: false,
  enableAlertSuccessMsg: INITIAL_STATE.enableAlertSuccessMsg,
  enableAlertErrorMsg: action.payload,
});

const disableAlert = (state = INITIAL_STATE, action) => ({
  ...state,
  disableAlertLoading: true,
  disableAlertSuccessMsg: INITIAL_STATE.disableAlertSuccessMsg,
  disableAlertErrorMsg: INITIAL_STATE.disableAlertErrorMsg,
});
const disableAlertSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  disableAlertLoading: false,
  disableAlertSuccessMsg: action.payload,
  disableAlertErrorMsg: INITIAL_STATE.disableAlertErrorMsg,
});
const disableAlertFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  disableAlertLoading: false,
  disableAlertSuccessMsg: INITIAL_STATE.disableAlertSuccessMsg,
  disableAlertErrorMsg: action.payload,
});

const remonitorAlert = (state = INITIAL_STATE, action) => ({
  ...state,
  remonitorAlertLoading: true,
  remonitorAlertSuccessMsg: INITIAL_STATE.remonitorAlertSuccessMsg,
  remonitorAlertErrorMsg: INITIAL_STATE.remonitorAlertErrorMsg,
});
const remonitorAlertSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  remonitorAlertLoading: false,
  remonitorAlertSuccessMsg: action.payload,
  remonitorAlertErrorMsg: INITIAL_STATE.remonitorAlertErrorMsg,
});
const remonitorAlertFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  remonitorAlertLoading: false,
  remonitorAlertSuccessMsg: INITIAL_STATE.remonitorAlertSuccessMsg,
  remonitorAlertErrorMsg: action.payload,
});

const duplicateAlert = (state = INITIAL_STATE, action) => ({
  ...state,
  duplicateAlertLoading: true,
  duplicateAlertSuccessMsg: INITIAL_STATE.duplicateAlertSuccessMsg,
  duplicateAlertErrorMsg: INITIAL_STATE.duplicateAlertErrorMsg,
});
const duplicateAlertSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  duplicateAlertLoading: false,
  duplicateAlertSuccessMsg: action.payload,
  duplicateAlertErrorMsg: INITIAL_STATE.duplicateAlertErrorMsg,
});
const duplicateAlertFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  duplicateAlertLoading: false,
  duplicateAlertSuccessMsg: INITIAL_STATE.duplicateAlertSuccessMsg,
  duplicateAlertErrorMsg: action.payload,
});


const changeAlertActions = (state = INITIAL_STATE, action) => ({
  ...state,
  changeAlertActionsLoading: true,
  changeAlertActionsSuccessMsg: INITIAL_STATE.changeAlertActionsSuccessMsg,
  changeAlertActionsErrorMsg: INITIAL_STATE.changeAlertActionsErrorMsg,
});
const changeAlertActionsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  changeAlertActionsLoading: false,
  changeAlertActionsSuccessMsg: action.payload,
  changeAlertActionsErrorMsg: INITIAL_STATE.changeAlertActionsErrorMsg,
});
const changeAlertActionsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  changeAlertActionsLoading: false,
  changeAlertActionsSuccessMsg: INITIAL_STATE.changeAlertActionsSuccessMsg,
  changeAlertActionsErrorMsg: action.payload,
});


export default createReducer(INITIAL_STATE, {
  [Types.GET_ALERT_ACTIONS]: getAlertActions,
  [Types.GET_ALERT_ACTIONS_SUCCESS]: getAlertActionsSuccess,
  [Types.GET_ALERT_ACTIONS_FAILURE]: getAlertActionsFailure,

  [Types.GET_MONITORING_ALERTS]: getMonitoringAlerts,
  [Types.GET_MONITORING_ALERTS_SUCCESS]: getMonitoringAlertsSuccess,
  [Types.GET_MONITORING_ALERTS_FAILURE]: getMonitoringAlertsFailure,

  [Types.GET_TRIGGERED_ALERTS]: getTriggeredAlerts,
  [Types.GET_TRIGGERED_ALERTS_SUCCESS]: getTriggeredAlertsSuccess,
  [Types.GET_TRIGGERED_ALERTS_FAILURE]: getTriggeredAlertsFailure,

  [Types.GET_EXPIRED_ALERTS]: getExpiredAlerts,
  [Types.GET_EXPIRED_ALERTS_SUCCESS]: getExpiredAlertsSuccess,
  [Types.GET_EXPIRED_ALERTS_FAILURE]: getExpiredAlertsFailure,

  [Types.OPEN_ALERT_FORM]: openAlertForm,
  [Types.CLOSE_ALERT_FORM]: closeAlertForm,
  
  [Types.SAVE_ALERT]: saveAlert,
  [Types.SAVE_ALERT_SUCCESS]: saveAlertSuccess,
  [Types.SAVE_ALERT_FAILURE]: saveAlertFailure,
  
  [Types.DELETE_ALERT]: deleteAlert,
  [Types.DELETE_ALERT_SUCCESS]: deleteAlertSuccess,
  [Types.DELETE_ALERT_FAILURE]: deleteAlertFailure,
  
  [Types.DELETE_ALERT]: deleteAlert,
  [Types.DELETE_ALERT_SUCCESS]: deleteAlertSuccess,
  [Types.DELETE_ALERT_FAILURE]: deleteAlertFailure,
  
  [Types.GET_RULE_FIELDS]: getRuleFields,
  [Types.GET_RULE_FIELDS_SUCCESS]: getRuleFieldsSuccess,
  [Types.GET_RULE_FIELDS_FAILURE]: getRuleFieldsFailure,

  [Types.ENABLE_ALERT]: enableAlert,
  [Types.ENABLE_ALERT_SUCCESS]: enableAlertSuccess,
  [Types.ENABLE_ALERT_FAILURE]: enableAlertFailure,

  [Types.DISABLE_ALERT]: disableAlert,
  [Types.DISABLE_ALERT_SUCCESS]: disableAlertSuccess,
  [Types.DISABLE_ALERT_FAILURE]: disableAlertFailure,

  [Types.REMONITOR_ALERT]: remonitorAlert,
  [Types.REMONITOR_ALERT_SUCCESS]: remonitorAlertSuccess,
  [Types.REMONITOR_ALERT_FAILURE]: remonitorAlertFailure,

  [Types.DUPLICATE_ALERT]: duplicateAlert,
  [Types.DUPLICATE_ALERT_SUCCESS]: duplicateAlertSuccess,
  [Types.DUPLICATE_ALERT_FAILURE]: duplicateAlertFailure,

  [Types.CHANGE_ALERT_ACTIONS]: changeAlertActions,
  [Types.CHANGE_ALERT_ACTIONS_SUCCESS]: changeAlertActionsSuccess,
  [Types.CHANGE_ALERT_ACTIONS_FAILURE]: changeAlertActionsFailure,
});
