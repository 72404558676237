import React from 'react'

import {
  Avatar,
  Card,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core'
import Utils from "../../Utils"

import MenuIcon from '@material-ui/icons/MoreVert'

import './style.sass'

const UserCard = (props) => {

  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null)
  // const [showLoading, setShowLoading] = React.useState(false)

  const openMenu = (event) => {
    setMenuAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setMenuAnchorEl(null)
  }

  const changeToMenager = () => {
    // setShowLoading(true)
    setMenuAnchorEl(null)
    props.changeToMenagerAction()
  }
  const changeToTutor = () => {
    // setShowLoading(true)
    setMenuAnchorEl(null)
    props.changeToTutorAction()
  }
  const enable = () => {
    // setShowLoading(true)
    setMenuAnchorEl(null)
    props.enableAction()
  }
  const disable = () => {
    // setShowLoading(true)
    setMenuAnchorEl(null)
    props.disableAction()
  }
  const del = () => {
    // setShowLoading(true)
    setMenuAnchorEl(null)
    props.deleteAction()
  }
  const sendAction = () => {
    // setShowLoading(true)
    setMenuAnchorEl(null)
    props.sendAccessAction()
  }

  return (
    <Card 
      className={
        `user-card block 
        ${props.data.type==='tutor'?'tutor':'menager'} 
        ${props.data.enabled===true?'':'disabled'}`} >
      {/* {showLoading && (<Loading/>)} */}
      <div className="top-bar">
        {!props.itsMe && (
          <IconButton 
            className="menu-button"
            onClick={openMenu}>
            <MenuIcon />
          </IconButton>
        )}
      </div>
      <div className="header">
        <Avatar 
          className="user-picture"
          src={props.data.picture}/>
        <Typography variant="subtitle2">{props.data.name}</Typography>
        <Divider/>
      </div>
      <div className="body">
        <Typography variant="subtitle2">Email: {props.data.email}</Typography>
        <Typography variant="subtitle2">Whatsapp: {Utils.phoneMask(props.data.whatsapp) || 'sem whatsapp'}</Typography>
      </div>

      {!props.itsMe && (
        <Menu
          id="simple-menu"
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={closeMenu}
        >
          { (props.data.type === 'menager' && props.tutorModule === true && props.data.tutorId > -1) && (
            <MenuItem onClick={changeToTutor}>Alterar para tutor</MenuItem>
          )}
          { (props.data.type === 'tutor' && props.tutorModule === true ) && (
            <MenuItem onClick={changeToMenager}>Alterar para gestor</MenuItem>
          )}
          {props.data.firstAccess === false && ( props.tutorModule === true || props.data.type === 'menager' ) && (
            <MenuItem onClick={sendAction}>Enviar acesso</MenuItem>
          )}
          {props.data.firstAccess === true && ( props.tutorModule === true || props.data.type === 'menager' ) && (
            <MenuItem onClick={sendAction}>Enviar novo acesso</MenuItem>
          )}
          {props.data.enabled === true && (
            <MenuItem onClick={disable}>Desativar acesso</MenuItem>
          )}
          {props.data.enabled === false && (
            <MenuItem onClick={enable}>Ativar acesso</MenuItem>
          )}
          <MenuItem onClick={del}>Excluir</MenuItem>
        </Menu>
      )}
    </Card>
  )
}

UserCard.defaultProps = {
  data: {},
  itsMe: false,
  changeToMenagerAction: () => {},
  changeToTutorAction: () => {},
  enableAction: () => {},
  disableAction: () => {},
  deleteAction: () => {},
  sendAccessAction: () => {},
}

export default UserCard