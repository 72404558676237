import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import FilterPopup from '../../components/FilterPopup'

import { Creators as AppActions } from '../../store/ducks/app'
import { Creators as SubjectActions } from '../../store/ducks/subject'

import './style.sass'

const SubjectFilterPopup = (props) => {

  const dispatch = useDispatch()

  const finished = useSelector(state => state.app.finished)
  const startDate = useSelector(state => state.app.startDate)
  const endDate = useSelector(state => state.app.endDate)

  const loading = useSelector(state => state.subject.getSubjectsNoFilteredLoading)
  const subjects = useSelector(state => state.subject.listNoFiltered)
  const selectedSubjects = useSelector(state => state.app.selectedSubjects)
  
  const onSelectSubject = (subjects) => {
    dispatch(AppActions.setSelectedSubject(subjects))
  }

  const onOpen = () => {
    if(!subjects.length){
      dispatch(SubjectActions.getSubjectsNoFiltered({
        limit: 1000, 
        finished,
        startDate, 
        endDate,
      }))
    }
  }

  return (
    <div className="subject-filter-popup">
      <FilterPopup
        label="Disciplinas"
        options={subjects}
        selectedOptions={selectedSubjects}
        loading={loading}
        onOpen={onOpen}
        onConfirm={onSelectSubject}
      />
    </div>
  )
}

SubjectFilterPopup.defaultProps = {}

export default SubjectFilterPopup