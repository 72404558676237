import React, { useState } from 'react';

import {
  TextField
} from '@material-ui/core'

import "./style.sass"

const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column
  return (
    <TextField
      className={"search_column"}
      variant={"outlined"} 
      value={filterValue || ''}
      onChange={(e) => setFilter(e.target.value)}
    />
  )
}

export default ColumnFilter