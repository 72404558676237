import { put, call, takeLatest, all } from 'redux-saga/effects'
import Api from '../Api'
import { Types } from '../ducks/subject'

function* getSubjects(action) {
  try {
    const response = yield call(Api.getSubjects, action.payload)
    yield put({type: Types.GET_SUBJECTS_SUCCESS, payload: response})
  } catch (error) {
    yield put({type: Types.GET_SUBJECTS_FAILURE, errorMessage: error.message})
  }
}

function* getSubjectsNoFiltered(action) {
  try {
    const response = yield call(Api.getSubjects, action.payload)
    yield put({type: Types.GET_SUBJECTS_NO_FILTERED_SUCCESS, payload: response})
  } catch (error) {
    yield put({type: Types.GET_SUBJECTS_NO_FILTERED_FAILURE, errorMessage: error.message})
  }
}

function* getAllSubjects(action) {
  try {
    const response = yield call(Api.getAllSubjects, action.payload)
    yield put({type: Types.GET_ALL_SUBJECTS_SUCCESS, payload: response})
  } catch (error) {
    yield put({type: Types.GET_ALL_SUBJECTS_FAILURE, errorMessage: error.message})
  }
}

export default function* () {
  yield all([
    takeLatest(Types.GET_SUBJECTS, getSubjects),
    takeLatest(Types.GET_SUBJECTS_NO_FILTERED, getSubjectsNoFiltered),
    takeLatest(Types.GET_ALL_SUBJECTS, getAllSubjects),
  ])
}