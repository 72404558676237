/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo} from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  Chip,
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'
import FilterIcon from '@material-ui/icons/Tune'
import TutorFilterPopup from '../../containers/TutorFilterPopup'
import CourseFilterPopup from '../../containers/CourseFilterPopup'
import ClassFilterPopup from '../../containers/ClassFilterPopup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

import BatchList from '../BatchList'
import { Creators as AppActions } from '../../store/ducks/app'
import Utils from '../../Utils'

import './style.sass'

const FiltersBlock = (props) => {

  const dispatch = useDispatch()

  const finished = useSelector(state => state.app.finished)
  const selectedStudents = useSelector(state => state.app.selectedStudents)

  const panelSummaryContent = useMemo(() => (
    <Grid container>
      <Grid item xs={8} className="title">
        <FilterIcon className="icon"/> 
        <Typography variant="h6">Filtros</Typography>
      </Grid>
    </Grid>
  ))

  const setStatus = (event, value) => {
    dispatch(AppActions.changeFinished(value))
  }
  
  const panelDetailsContent = (
    <>
      <Typography variant="h6">Selecione a data da analise</Typography>
      <Typography variant="body2">Cada item dessa lista representa uma analise realizada pelo Assis nos dados da data informada</Typography>
      <BatchList/>

      <Typography variant="body2">Selecione a situação dos dados que deseja ver, escolha entre "Em andamento" e "Finalizado"</Typography>
      <div className="status-wrapper">
        <ToggleButtonGroup
          value={finished}
          exclusive
          onChange={setStatus}
          size="small"
          className="status"
        >
          <ToggleButton value={false} className="button">
            Em andamento
          </ToggleButton>
          <ToggleButton value={true} className="button">
            Finalizado
          </ToggleButton>
        </ToggleButtonGroup>
      </div>

      <Typography variant="body2">Selecione Tutor(es), Curso(s), Turma(s) ou Aluno(s) e obtenha uma analise personalizada cruzando essas informações</Typography>
      
      <Grid container className="filters-wrapper" spacing={1}>
        <Grid item xs={6} className="filters-item">
          <Typography variant="button" className="filter-label">Tutores</Typography>
          <TutorFilterPopup buttonSize="small"/>
        </Grid>
        <Grid item xs={6} className="filters-item"></Grid>
        <Grid item xs={6} className="filters-item">
          <Typography variant="button" className="filter-label">Cursos</Typography>
          <CourseFilterPopup buttonSize="small"/>
        </Grid>
        <Grid item xs={6} className="filters-item"></Grid>
        <Grid item xs={6} className="filters-item">
          <Typography variant="button" className="filter-label">Turmas</Typography>
          <ClassFilterPopup buttonSize="small"/>
        </Grid>
        <Grid item xs={6} className="filters-item"></Grid>
        <Grid item xs={6} className="filters-item">
          <Typography variant="button" className="filter-label">Alunos</Typography>
          <div>
            {selectedStudents.length === 0 && (
              <Chip 
                label="Nenhum aluno selecionado"
                size="small"
                variant="outlined"
                color="primary"
                />
            )}
            {selectedStudents.length === 1 && (
              <Chip 
                label={Utils.capitalize(selectedStudents[0].name)}
                size="small"
                variant="outlined"
                color="primary"
                onDelete={() => dispatch(AppActions.setSelectedStudents([]))}
                />
            )}
            {selectedStudents.length > 1 && (
              <Chip 
                className=""
                label={`${selectedStudents.length} Selecionado(s)`}
                size="small"
                variant="outlined"
                color="primary" 
                onDelete={() => dispatch(AppActions.setSelectedStudents([]))}
                />
            )}
          </div>
        </Grid>
        <Grid item xs={6} className="filters-item"></Grid>
      </Grid>
    </>
  )

  return (
    <Accordion className="filters-block" expanded={true}>
      <AccordionSummary>
        {panelSummaryContent}
      </AccordionSummary>
      <AccordionDetails>
        {panelDetailsContent}
      </AccordionDetails>
    </Accordion>
  )
}

FiltersBlock.defaultProps = {}

export default FiltersBlock