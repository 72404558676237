import { put, call, takeLatest, all } from 'redux-saga/effects'
import Api from '../Api'
import { Types } from '../ducks/feedback'

function* getFeedbackTipOrQuestion(action) {
  try {
    const response = yield call(Api.getFeedbackTipOrQuestion)
    yield put({type: Types.GET_FEEDBACK_TIP_OR_QUESTION_SUCCESS, payload: response})
  } catch (error) {
    yield put({type: Types.GET_FEEDBACK_TIP_OR_QUESTION_FAILURE, payload: error.message})
  }
}

function* confirmFeedbackTipDelivered(action) {
  try {
    const response = yield call(Api.confirmFeedbackTipDelivered, action.payload)
    yield put({type: Types.CONFIRM_FEEDBACK_TIP_DELIVERED_SUCCESS, payload: response})
  } catch (error) {
    yield put({type: Types.CONFIRM_FEEDBACK_TIP_DELIVERED_FAILURE, payload: error.message})
  }
}

function* saveFeedbackAnswer(action) {
  try {
    const response = yield call(Api.saveFeedbackAnswer, action.payload)
    yield put({type: Types.SAVE_FEEDBACK_ANSWER_SUCCESS, payload: response})
  } catch (error) {
    yield put({type: Types.SAVE_FEEDBACK_ANSWER_FAILURE, payload: error.message})
  }
}

export default function* () {
  yield all([
    takeLatest(Types.GET_FEEDBACK_TIP_OR_QUESTION, getFeedbackTipOrQuestion),
    takeLatest(Types.SAVE_FEEDBACK_ANSWER, saveFeedbackAnswer),
    takeLatest(Types.CONFIRM_FEEDBACK_TIP_DELIVERED, confirmFeedbackTipDelivered),
  ])
}
