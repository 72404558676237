import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  stateMessageLoading: ['payload'],
  sendMessage: ['payload'],
  sendMessageSuccess: ['payload'],
  sendMessageFailure: ['payload'],
  autoMessage: ['payload'],
  setFailCount: ['payload'],
  setOpenChat: ['payload'],
  clearMessage: ['payload'],
  sendFilters: ['payload']
})

const INITIAL_STATE = {
  sendMessageLoading: false,
  list: [],
  filter: {},
  order: {},
  failCount: 0,
  openChat: false,
  messageSuccess: {},
  status:''
}
const stateMessageLoading = (state = INITIAL_STATE, action) => ({
  ...state,
  sendMessageLoading: action.payload || false,
  status: 'load'
})
const sendMessage = (state = INITIAL_STATE, action) => ({
    ...state,
    list : [...state.list, action.payload],
    sendMessageLoading: true,
    status: 'load'
  })

  const sendFilters = (state = INITIAL_STATE, action) => ({
    ...state, 
    sendMessageLoading: true,
    // list: [...state.list, action.payload],
    filter: action.payload.filter || {},
    order: action.payload.order || {},
    messageSuccess: action.payload|| {},
    // failCount: 0
    status: 'load'
  })
const sendMessageSuccess = (state = INITIAL_STATE, action) => ({
  ...state, 
  sendMessageLoading: false,
  list: [...state.list, state.messageSuccess],
  failCount: 0,
  status: 'success'
})
const sendMessageFailure = (state = INITIAL_STATE, action) => ({
  ...state, 
  sendMessageLoading: false,
  list: [...state.list, action.errorMessage],
  failCount: state.failCount + 1,
  status: 'failure'
})

const autoMessage = (state = INITIAL_STATE, action) => ({
  ...state,
  list : [...state.list, action.payload],
  sendMessageLoading: false
})

const setOpenChat = (state = INITIAL_STATE, action) => ({
  ...state,
  openChat: action.payload
})

const clearMessage = (state = INITIAL_STATE, action) => ({
  ...state,
  list: [action.payload]
})

export default createReducer(INITIAL_STATE, {
  [Types.SEND_MESSAGE]: sendMessage,
  [Types.STATE_MESSAGE_LOADING]: stateMessageLoading,
  [Types.SEND_MESSAGE_SUCCESS]: sendMessageSuccess,
  [Types.SEND_MESSAGE_FAILURE]: sendMessageFailure,
  [Types.AUTO_MESSAGE]: autoMessage,
  [Types.SET_OPEN_CHAT]: setOpenChat,
  [Types.CLEAR_MESSAGE]: clearMessage,
  [Types.SEND_FILTERS]: sendFilters
})