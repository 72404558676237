import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getNotifications: ['payload'],
  getNotificationsSuccess: ['payload'],
  getNotificationsFailure: ['payload'],

  readNotification: ['payload'],
  readNotificationSuccess: ['payload'],
  readNotificationFailure: ['payload'],

  deleteNotification: ['payload'],
  deleteNotificationSuccess: ['payload'],
  deleteNotificationFailure: ['payload'],
})

const INITIAL_STATE = {
  getNotificationsLoading: false,
  page: 0,
  limit: 0,
  total: 0,
  list: [],
  getNotificationsErrorMsg: null,
  readNotificationLoading: false,
  readNotificationErrorMsg: null,
  deleteNotificationLoading: false,
  deleteNotificationErrorMsg: null,
}
 
const getNotifications = (state = INITIAL_STATE, action) => ({...state, getNotificationsLoading: true})
const getNotificationsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  getNotificationsLoading: false,
  getNotificationsErrorMsg: INITIAL_STATE.getNotificationsErrorMsg,
  page: action.payload.page,
  limit: action.payload.limit,
  total: action.payload.total,
  list: action.payload.list,
})
const getNotificationsFailure = (state = INITIAL_STATE, action) => ({
  ...state, 
  page: INITIAL_STATE.page,
  limit: INITIAL_STATE.limit,
  total: INITIAL_STATE.total,
  list: INITIAL_STATE.list,
  getNotificationsLoading: false, 
  getNotificationsErrorMsg: action.payload
})

 
const readNotification = (state = INITIAL_STATE, action) => ({...state, readNotificationLoading: true})
const readNotificationSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  readNotificationLoading: false,
  readNotificationErrorMsg: INITIAL_STATE.readNotificationErrorMsg,
})
const readNotificationFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  readNotificationLoading: false, 
  readNotificationErrorMsg: action.payload
})

 
const deleteNotification = (state = INITIAL_STATE, action) => ({...state, deleteNotificationLoading: true})
const deleteNotificationSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  deleteNotificationLoading: false,
  deleteNotificationErrorMsg: INITIAL_STATE.deleteNotificationErrorMsg,
})
const deleteNotificationFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  deleteNotificationLoading: false, 
  deleteNotificationErrorMsg: action.payload
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_NOTIFICATIONS]: getNotifications,
  [Types.GET_NOTIFICATIONS_SUCCESS]: getNotificationsSuccess,
  [Types.GET_NOTIFICATIONS_FAILURE]: getNotificationsFailure,
  
  [Types.READ_NOTIFICATION]: readNotification,
  [Types.READ_NOTIFICATION_SUCCESS]: readNotificationSuccess,
  [Types.READ_NOTIFICATION_FAILURE]: readNotificationFailure,
  
  [Types.DELETE_NOTIFICATION]: deleteNotification,
  [Types.DELETE_NOTIFICATION_SUCCESS]: deleteNotificationSuccess,
  [Types.DELETE_NOTIFICATION_FAILURE]: deleteNotificationFailure,

})