import React, { useState, useEffect } from 'react'

import {
  ButtonBase,
  Chip,
  Grid,
  Typography,
  Tooltip,
  Hidden,
} from '@material-ui/core'
import SeeMoreIcon from '@material-ui/icons/DoubleArrow'
import TutorIcon from '@material-ui/icons/School'
import CourseIcon from '@material-ui/icons/MenuBook'
import ClassIcon from '@material-ui/icons/Group'
// import SubjectIcon from '@material-ui/icons/Class'
import StudentsIcon from '@material-ui/icons/Person'
import ArrowIcon from '@material-ui/icons/ArrowDropDown'

import Utils from '../../Utils'

import './style.sass'

const EvasionBar = (props) => {

  // const evarsors = props.data.evarsorConcludents.find(item => item.title === 'Evasores')

  const [evasorData, setEvasorData] = useState({})

  useEffect(() => {
    if(props.data.evarsorConcludents){
      setEvasorData(props.data.evarsorConcludents.find(item => item.title === 'Evasores'))
    }
  }, [props.data])

  return (
    <Grid
      className={`evasion-bar ${props.className} ${props.color}-color`}
      container
    >
      <Grid item container xs={9}>
        <Grid item xs={12} className="name-wrapper">
          {props.data.name && (
            <Typography variant="subtitle2" className="name">{Utils.capitalize(props.data.name)}</Typography>
          )}
        </Grid>
        <Grid item xs={12} className="numbers">
          <Tooltip title="Qtde. de tutores" arrow placement="top">
            <div className="number">
              <TutorIcon className="icon" />
              <Typography className="text" variant="subtitle2">{props.data.tutorCount}{props.data.tutorCount===1?' Tutor':' Tutores'}</Typography>
            </div>
          </Tooltip>
          {props.type !== 'course' && (
            <Tooltip title="Qtde. de cursos" arrow placement="top">
              <div className="number">
                <CourseIcon className="icon" />
                <Typography className="text" variant="subtitle2">{props.data.courseCount}{props.data.courseCount===1?' Curso':' Cursos'}</Typography>
              </div>
            </Tooltip>
          )}
          {props.type !== 'class' && (
            <Tooltip title="Qtde. de turmas" arrow placement="top">
              <div className="number">
                <ClassIcon className="icon" />
                <Typography className="text" variant="subtitle2">{props.data.classCount}{props.data.classCount===1?' Turma':' Turmas'}</Typography>
              </div>
            </Tooltip>
          )}
          {props.type !== 'student' && (
            <Tooltip title="Qtde. de alunos" arrow placement="top">
              <div className="number">
                <StudentsIcon className="icon" />
                <Typography className="text" variant="subtitle2">{props.data.studentCount}{props.data.studentCount===1?' Aluno':' Alunos'}</Typography>
              </div>
            </Tooltip>
          )}
        </Grid>
        {props.type !== 'student' && (
          <>
            <Grid item container xs={8}>
              <div className="classifications">
                {props.data.classifications.map((classificationItem, key) => (
                  <Tooltip key={key} title={classificationItem.title} placement="top">
                    <Chip 
                      size="small" 
                      className="classification-item" 
                      label={`${classificationItem.count} (${((classificationItem.count/props.data.studentCount)*100).toFixed(2)}%)`} 
                      style={{backgroundColor: classificationItem.color}}/>
                  </Tooltip>
                ))}
              </div>
            </Grid>
            <Grid item xs={4} className="evasors">
              <Typography variant="button">{evasorData.count}{evasorData.count===1?' Evasor':' Evasores'}</Typography>
            </Grid>
          </>
        )}
        {props.type === 'student' && (
          <Grid item xs={12}>
            <div className="student-bar">
              <div className="bar-wrapper">
                <ArrowIcon className="arrow" style={{left: `${(props.data.IAEvasion-6)}%`}}/>
                <div className="bar" style={{backgroundImage: `linear-gradient(to right, ${props.classification.map(item => item.color).join()})`}}></div>
              </div>
              <Typography className="classification" variant="caption">
                {props.classification.find(classificationItem => (props.data.IAEvasion >= classificationItem.startEvasion && props.data.IAEvasion <= classificationItem.endEvasion)).title}
              </Typography>
            </div>
          </Grid>
        )}
      </Grid>
      <Grid item xs={3} className="see-more-wrapper">
        <ButtonBase className="see-more" onClick={props.seeMoreAction}>
          <Hidden smDown>
            <Typography variant="button">Ver dados</Typography>
          </Hidden>
          <SeeMoreIcon />
        </ButtonBase>
      </Grid>
    </Grid >
  )
}

EvasionBar.defaultProps = {
  className: '',
  data: {},
  type: null,
  classification: [],
  seeMoreAction: () => { }
}

export default EvasionBar