import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom"
import { Creators as ClientsActions } from "../../../store/ducks/admin/clients";
import moment from 'moment';

import './style.sass';

import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Fab
} from '@material-ui/core'

import Editicon from "@material-ui/icons/Edit"
import AddIcon from "@material-ui/icons/Add"

const Clients = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const rows = useSelector(state => state.admin.clients.clientsList);

  React.useEffect(() => {
    dispatch(ClientsActions.getClients());
  }, [dispatch])

  const toNew = () => {
    history.push("clients/new")
  }


  return (
    <>
      <Fab color="primary" id={"fab-add"} aria-label="add">
        <AddIcon onClick={toNew}/>
      </Fab>
      <TableContainer id={"batchlogs-table-container"} component={Paper}>
        <Table>
          <TableHead className={"table-header"}>
            <TableRow>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">CNPJ</TableCell>
              <TableCell align="center">Iniciou</TableCell>
              <TableCell align="center">Expira em</TableCell>
              <TableCell align="center">Visualizar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row._id}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="center">{row.cnpj}</TableCell>
                <TableCell align="center">{row.startAt && moment(row.startAt).format("DD/MM/YYYY - HH:mm:ss")}</TableCell>
                <TableCell align="center">{row.endAt && moment(row.endAt).format("DD/MM/YYYY - HH:mm:ss")}</TableCell>
                <TableCell align="center"><Link to={`/clients/${row._id}`}> <Editicon /></Link></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default Clients;