import React from 'react'
import _ from "lodash"

// _.shuffle
export const Apresentacao = (props) => _.shuffle([
  {
    intent: 'welcome',
    filters: {},
    message:  <div dangerouslySetInnerHTML={{ __html: `Olá, <strong>${props.userName}!</strong> 
    Sou o Assis, o Assistente Inteligente, e posso dar
    informações sobre a situação de tutores e alunos na sua plataforma. 
   <strong> O que gostaria de saber?</strong>`}}/>  ,
    origin: 'assis'
  },
  {
    intent: 'welcome',
    filters: {},
    message:  <div dangerouslySetInnerHTML={{ __html: `Bem-vindo, <strong>${props.userName}!</strong> Meu nome é Assis e sou o Assistente Inteligente que dá
    informações sobre a situação de tutores e alunos na sua plataforma. 
    <strong>Como posso atendê-lo(a)?</strong>`}}/>,
    origin: 'assis'
  },
  {
    intent: 'welcome',
    filters: {},
    message:  <div dangerouslySetInnerHTML={{ __html: `Olá, <strong>${props.userName}!</strong> Meu nome é Assis e eu posso te ajudar com informações sobre a
    situação de tutores e alunos na sua plataforma. 
    <strong> Vamos começar! O que gostaria de saber?</strong>`}}/>,
    origin: 'assis'
  }
])[0];

export const Especificas = () => _.shuffle([
  'Pronto, irei responder a sua pergunta!',
  'Ok, vamos lá!',
  'Entendido!',
  'Beleza! Aqui está o que você gostaria de saber.',
  'Certo.'
])[0];

export const Fracasso = () => [
  {
    intent: 'Default Fallback Intent',
    filters: {},
    message: `Hm... Acho que não entendi. Você poderia reformular a sua pergunta?`,
    origin: 'assis'
  },
  {
    intent: 'Default Fallback Intent',
    filters: {},
    message: `Desculpe, ainda não entendi. Se você reescrever de forma clara, utilizando
    os parâmetros de informações de alunos, tutores eu posso te ajudar!`,
    origin: 'assis'
  },
  {
    intent: 'Default Fallback Intent',
    filters: {},
    message: `Peço desculpas novamente, mas no momento não me sinto preparado
    para responder o que você quer saber. Abaixo está o e-mail de
    atendimento, onde você pode enviar a sua dúvida e ser respondido pela
    nossa equipe. O e-mail é: contato@assis.com.br`,
    origin: 'assis'
  }
];
export const Instruction = () => [

  {
    
    intent: 'AJUDA ASSIS',

    message: <div dangerouslySetInnerHTML={{ __html: `Posso dar informações sobre a situação e o nível
    de evasão referente a/aos <strong>Tutores, Cursos e
    Alunos</strong> da sua plataforma.`}}/>,
    
    origin: 'assis'
  },
  {
    intent: 'AJUDA ASSIS',

    message: <div dangerouslySetInnerHTML={{ __html: `Com as métricas e o acompanhamento correto,
    <strong> você vai conseguir evitar que o nível de evasão
    aumente!</strong>`}}/>,
    origin: 'assis'
  },
  {
    intent: 'AJUDA ASSIS',
    message: `Clicando no botão de interrocaçao (?) ao lado, você
    pode ver exemplos de perguntas que pode me
    fazer.`,
    origin: 'assis'
  },
  {
    intent: 'AJUDA ASSIS',
    message: <div dangerouslySetInnerHTML={{ __html:  `Vamos lá? <strong>O que você gostaria de saber sobre
    tutores, cursos ou alunos?</strong>`}}/>,
    origin: 'assis'
  },
]

export const Encerramento = () => _.shuffle([
  {
    intent: 'closing',
    filters: {},
    message: `Obrigado(a) pelo seu contato, e se surgir alguma dúvida adicional, basta
    me procurar novamente.`,
    origin: 'assis'
  },
  {
    intent: 'closing',
    filters: {},
    message: `Espero ter conseguido resolver as suas dúvidas. Caso queira perguntar
    outra coisa, estou disponível!`,
    origin: 'assis'
  },
  {
    intent: 'closing',
    filters: {},
    message: `Tudo resolvido? Sinta-se à vontade para entrar em contato sempre que
    precisar.`,
    origin: 'assis'
  },
  {
    intent: 'closing',
    filters: {},
    message: `Se surgir alguma dúvida adicional pode entrar em contato novamente,
    será um imenso prazer atendê-lo(a).`,
    origin: 'assis'
  }
])[0];



export const Guia = () =>  _.shuffle([
  ' Posso te ajudar com algo a mais?',
  ' Você gostaria de saber de mais alguma coisa?',
  ' Te ajudo em algo mais?',
  ' Há outra coisa que gostaria de perguntar?',
  ' Deseja saber algo mais?',
  ' Te ajudo com alguma outra coisa?',
  ' Você quer saber de alguma outra coisa?',
  ' Pode me informar o que deseja saber?',
  ' Como posso te ajudar agora?',
  ' Posso ajudá-lo(a) em mais alguma questão?'
])[0];

export const Resgate = () => (_.shuffle([
  `Olá novamente!${Guia()}`,
  `Oi de novo!${Guia()}`,
  `Opa!${Guia()}`
])[0]);

export const EncerramentoSug = () => _.shuffle([
  "Tudo certo por aqui, não tenho mais perguntas.",
  "Só isso mesmo, obrigado!",
  "Nada mais, obrigado!"
])[0];

//SUGGESTIONS

export const apresentationSuggestion = {
  text: "COMO VOCÊ PODE ME AJUDAR?",
  intent: "AJUDA ASSIS",
};

export const suggestionsWelcome = [
  {
    text: "QUAIS SÃO OS CURSOS COM MAIOR EVASÃO?",
    intent: "LISTA DE CURSOS POR EVADIDOS",
    event: "courses_by_evaded",
  },
  {
    text: "LISTA DE ALUNOS POR DESEMPENHO",
    intent: "LISTA DE ALUNOS POR DESEMPENHO",
    event: "students_by_yield",
  },
  {
    text: "LISTA DE TUTORES POR DESEMPENHO",
    intent: "LISTA DE TUTORES POR DESEMPENHO",
    event: "tutors_by_yield",
  },
];
