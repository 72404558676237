import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  ButtonBase,
  Grid,
  Typography,
  Paper,
} from '@material-ui/core'

import { Creators as AppActions } from '../../store/ducks/app'

import './style.sass'

const MetricBlock = (props) => {

  const dispatch = useDispatch()

  const setSelectStudent = (studentIds) => {
    dispatch(AppActions.setSelectedStudents(studentIds.map(studentId => ({studentId}))))
  }

  return (
    <Grid component={Paper} container className={'metric-block block'}>
      <Grid item xs={12}>
        <Typography variant="subtitle2" className="title">
          {props.icon}
          {props.title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption" className="subtitle">
          {props.subtitle}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {props.classifications.map(classification => (
          <ButtonBase 
            onClick={() => {setSelectStudent(classification.studentIds)}}
            className="classification-button">
            <div className="color-bar" style={{backgroundColor: classification.color}}></div>
            <Typography variant="subtitle2" className="title">
              {classification.title}
            </Typography>
            <Typography variant="caption">
              Total de {classification.total} ({(classification.percent).toFixed(2)}%) | Média de {(classification.avg).toFixed(2)}
            </Typography>
          </ButtonBase>
        ))}
      </Grid>
    </Grid >
  )
}

MetricBlock.defaultProps = {
  className: '',
  title: '',
  subtitle: '',
  classifications: [],
}

export default MetricBlock