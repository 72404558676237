import React, { useState } from 'react';
import { CSVLink } from "react-csv"

import { useSelector, useDispatch } from 'react-redux'
import { Creators as AppActions } from '../../store/ducks/app'


import Table from '../../containers/Table'
import ColumnFilter from '../../components/ColumFilter'
import TutorFilterPopup from '../../containers/TutorFilterPopup'
import Api from '../../store/Api'

import {
  Button,
  Grid,
  TextField,
  Typography,
  CircularProgress
} from "@material-ui/core"

import {
  ToggleButton,
  ToggleButtonGroup
} from "@material-ui/lab"


import TutorIcon from '@material-ui/icons/School'
import CourseIcon from '@material-ui/icons/MenuBook'
import ClassIcon from '@material-ui/icons/People'
import PerfilIcon from '@material-ui/icons/Accessible'
import StatusIcon from '@material-ui/icons/CheckBox'
import DateIcon from '@material-ui/icons/DateRange'
import FilterIcon from '@material-ui/icons/Tune'

import "./style.sass"
import CourseFilterPopup from '../../containers/CourseFilterPopup';
import ClassFilterPopup from '../../containers/ClassFilterPopup';

const Report = (props) => {

  const dispatch = useDispatch()
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [pageCount, setPageCount] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [status, setStatus] = useState("");
  const [perfil, setPerfil] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const selectedCourses = useSelector(state => state.app.selectedCourses)
  const selectedClasses = useSelector(state => state.app.selectedClasses)
  const selectedTutors = useSelector(state => state.app.selectedTutors)
  const customFilters = useSelector(state => state.app.customFilters)

  const columnsCompanies = [
    {
      Header: "Curso",
      accessor: "courseInfo[0].name",
      show: true,
      Filter: ColumnFilter
    },
    {
      Header: "Turma",
      accessor: "classInfo[0].name",
      show: true,
      Filter: ColumnFilter
    },
    {
      Header: "Tutor",
      accessor: "tutorsInfo[0].name",
      show: true,
      Filter: ColumnFilter
    },
    {
      Header: "Aluno",
      accessor: "studentInfo[0].name",
      show: true,
      Filter: ColumnFilter
    },
    {
      Header: "Perfil",
      accessor: "disability",
      show: true,
      Filter: ColumnFilter,
      // cell: ({ value }) => value ? <span>Sim</span> : <span>Não</span>
    },
    {
      Header: "Risco de evasão",
      accessor: "IAEvasion",
      show: true,
      Filter: ColumnFilter,
      Cell: ({ value }) => <span>{value.toFixed(2)}%</span>
    },
    {
      Header: "Tempo online",
      accessor: "accessTime",
      show: true,
      Filter: ColumnFilter,
      Cell: ({ value }) => <span>{value.toFixed(2)}</span>
    },
    {
      Header: "Tentativas",
      accessor: "attempts",
      show: true,
      Filter: ColumnFilter
    },
    {
      Header: "Precedência",
      accessor: "timeLeft",
      show: true,
      Filter: ColumnFilter,
      Cell: ({ value }) => <span>{value.toFixed(2)}</span>
    },
    {
      Header: "Nota",
      accessor: "averageGrade",
      show: true,
      Filter: ColumnFilter
    },
    {
      Header: "Média da turma",
      accessor: "classMean",
      show: true,
      Filter: ColumnFilter,
      Cell: ({ value }) => <span>{value}%</span>
    },
    {
      Header: "Comparativo",
      accessor: "comparative",
      show: true,
      Filter: ColumnFilter,
      Cell: ({ value }) => <span>{value}%</span>
    },
    {
      Header: "Status",
      accessor: "status",
      show: true,
      Filter: ColumnFilter
    }


  ]
  const columnsCompaniesDownload = [
    {
      label: "Curso",
      key: "courseInfo[0].name",
    },
    {
      label: "Turma",
      key: "classInfo[0].name",
    },
    {
      label: "Tutor",
      key: "tutorsInfo[0].name",
    },
    {
      label: "Aluno",
      key: "studentInfo[0].name",
    },
    {
      label: "Perfil",
      key: "disability",
    },
    {
      label: "Risco de evasão",
      key: "IAEvasion"
    },
    {
      label: "Tempo online",
      key: "accessTime"
    },
    {
      label: "Tentativas",
      key: "attempts"
    },
    {
      label: "Precedência",
      key: "timeLeft"
    },
    {
      label: "Nota",
      key: "averageGrade"
    },
    {
      label: "Média da turma",
      key: "classMean"
    },
    {
      label: "Comparativo",
      key: "comparative"
    },
    {
      label: "Status",
      key: "status"
    }
  ]

  const fetchData = async (props) => {

    if (!isFetching) {
      setIsFetching(true);
      const t = await Api.getStudentsReport({
        params: {
          limit: props.pageSize,
          page: props.pageIndex,
          filter: {
            ...customFilters,
            courses: selectedCourses.map(c => c._id),
            classes: selectedClasses.map(c => c._id),
            tutors: selectedTutors.map(t => t._id)
          }
        }
      }).catch(err => console.error(err));
      setPageIndex(props.pageIndex);
      setData(t.data);
      setPageCount(t.pageCount)
      setIsFetching(false);
      setPageSize(props.pageSize)
    }
  }

  React.useEffect(() => {
    dispatch(AppActions.setSelectedCourses([]))
    dispatch(AppActions.setSelectedClasses([]))
    dispatch(AppActions.setSelectedTutors([]))
    dispatch(AppActions.setCustomFilters({}))
    return function cleanup() {
      dispatch(AppActions.setSelectedCourses([]))
      dispatch(AppActions.setSelectedClasses([]))
      dispatch(AppActions.setSelectedTutors([]))
      dispatch(AppActions.setCustomFilters({}))
    }
  }, [])

  React.useEffect(() => {
    fetchData({ pageSize, pageIndex });
  }, [customFilters])

  React.useEffect(() => {
    console.log('data: ', downloadData)
    if (downloadData.length > 0 && !isFetching)
      setTimeout(() => document.getElementById("download-csv").click(), 500);
  }, [downloadData])

  const handleFilter = () => {
    dispatch(AppActions.setCustomFilters({
      status,
      perfil,
      report_startDate: startDate,
      report_endDate: endDate
    }));
  }

  const handleDownload = async () => {
    setIsFetching(true);
    const t = await Api.getStudentsReport({
      params: {
        limit: 99999999999,
        page: 0,
        filter: {
          ...customFilters,
          courses: selectedCourses.map(c => c._id),
          classes: selectedClasses.map(c => c._id),
          tutors: selectedTutors.map(t => t._id)
        },
        download: true
      }
    }).catch(err => console.error(err));
    setIsFetching(false)
    setDownloadData(t.data);
  }

  const handleStatus = (e, value) => {
    setStatus(value)
  }
  const handlePerfil = (e, value) => {
    setPerfil(value)
  }

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  }
  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  }

  return (
    <Grid container spacing={2} className={"report-wrapper"}>
      {!isFetching &&
        <CSVLink id={"download-csv"} data={downloadData} headers={columnsCompaniesDownload} />
      }
      <Grid item md={5} container alignItems={"center"}>
        <Grid item md={1}>
          <FilterIcon fontSize={"large"} />
        </Grid>
        <Grid item md={11}>
          <Typography variant={"h3"}>Filtros</Typography>
        </Grid>
      </Grid>
      <Grid item md={10}>
        Nesta seção você poderá selecionar tutores, cursos, turmas, perfil e status em um determinado período de tempo
      </Grid>
      <Grid item md={12} container spacing={2}>
        <Grid item md={4} container spacing={1}>
          <Grid item md={12} container alignItems={"center"}>
            <Grid item md={1}>
              <TutorIcon className="icon" />
            </Grid>
            <Grid item md={3}>
              <Typography variant="h6">Tutores</Typography>
            </Grid>
            <Grid item md={5}>
              <TutorFilterPopup />
            </Grid>
          </Grid>
          <Grid item md={12} container alignItems={"center"}>
            <Grid item md={1}>
              <CourseIcon className="icon" />
            </Grid>
            <Grid item md={3}>
              <Typography variant="h6">Cursos</Typography>
            </Grid>
            <Grid item md={5}>
              <CourseFilterPopup />
            </Grid>
          </Grid>
          <Grid item md={12} container alignItems={"center"}>
            <Grid item md={1}>
              <ClassIcon className="icon" />
            </Grid>
            <Grid item md={3}>
              <Typography variant="h6">Turmas</Typography>
            </Grid>
            <Grid item md={5}>
              <ClassFilterPopup />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={5} container spacing={1}>
          <Grid item md={12} container alignItems={"center"}>
            <Grid item md={1}>
              <PerfilIcon className="icon" />
            </Grid>
            <Grid item md={3}>
              <Typography variant="h6">Perfil</Typography>
            </Grid>
            <Grid item md={8}>
              {/* <TextField
                placeholder={"Buscar perfil"}
                value={perfil}
                onChange={(e) => setPerfil(e.target.value)}
              /> */}
              <ToggleButtonGroup
                value={perfil}
                exclusive
                onChange={handlePerfil}
                color={"primary"}
              >
                <ToggleButton value={""}>Todos</ToggleButton>
                <ToggleButton value={"false"}>Não PCD</ToggleButton>
                <ToggleButton value={"true"}>PCD</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          <Grid item md={12} container alignItems={"center"}>
            <Grid item md={1}>
              <StatusIcon className="icon" />
            </Grid>
            <Grid item md={3}>
              <Typography variant="h6">Status</Typography>
            </Grid>
            <Grid item md={5}>
              {/* <TextField
                placeholder={"Buscar status"}
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              /> */}
              <ToggleButtonGroup
                value={status}
                exclusive
                onChange={handleStatus}
                color={"primary"}
              >
                <ToggleButton value={""}>Todos</ToggleButton>
                <ToggleButton value={"false"}>Andamento</ToggleButton>
                <ToggleButton value={"true"}>Finalizado</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          <Grid item md={12} container alignItems={"center"}>
            <Grid item md={1}>
              <DateIcon className="icon" />
            </Grid>
            <Grid item md={3}>
              <Typography variant="h6">Datas</Typography>
            </Grid>
            <Grid item md={8} container spacing={1}>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  type={"date"}
                  label={"Inicio"}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={startDate}
                  onChange={handleStartDate}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  type={"date"}
                  label={"Fim"}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={endDate}
                  onChange={handleEndDate}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={3} container spacing={1} justify={"flex-end"}>
          <Grid item md={6} container>
            <Grid item md={12}>
              <Button fullWidth variant={"contained"} color={"primary"} onClick={handleFilter} disabled={isFetching}> Aplicar filtros </Button>
            </Grid>
            <Grid item md={12}>
              <Button fullWidth variant={"contained"} color={"primary"} onClick={handleDownload} disabled={isFetching}> Baixar relatório </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid >
      <Grid item md={12}>
        {isFetching &&
          <CircularProgress />}
        <Table
          columns={columnsCompanies}
          data={data}
          fetchData={fetchData}
          isPagination
          isFetching={isFetching}
          pageCount={pageCount}
          pageIndex={pageIndex}

        />
      </Grid>
    </Grid >
  )
}

export default Report