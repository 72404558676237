import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { 
  Avatar,
  Badge,
  // Grid, 
  ButtonBase, 
  // Button, 
  Typography,
  Menu,
  MenuItem,
} from '@material-ui/core'
import DataIcon from '@material-ui/icons/InsertChart'
import UsersIcon from '@material-ui/icons/People'
import AlertsIcon from '@material-ui/icons/Warning'
import NotificationsIcon from '@material-ui/icons/NotificationsActive'

import NotificationsMenu from '../../containers/NotificationsMenu'
import SpeechContinuos from '../../containers/SpeechContinuos'

import Logo from './img/logo.png'
// import { Creators as ActionsAuth } from '../../store/ducks/auth'
import './style.sass'

const Header = () => {
  // const dispatch = useDispatch()
  const [userMenuAnchorEl, setUserMenuAnchorEl] = React.useState(null)
  const [notificationsMenuAnchorEl, setNotificationsMenuAnchorEl] = React.useState(null)

  const authenticatedUser = useSelector(state => state.auth.authenticatedUser)

  const openUserMenu = event => {
    setUserMenuAnchorEl(event.currentTarget)
  };

  const closeUserMenu = () => {
    setUserMenuAnchorEl(null);
  };

  const openNotificationsMenu = event => {
    setNotificationsMenuAnchorEl(event.currentTarget)
  };

  const closeNotificationsMenu = () => {
    setNotificationsMenuAnchorEl(null);
  };
  
  return (
    <header id="header" className="header">
      <div className="header-content">
        <div className="logo-wrapper">
          <img src={Logo} alt="logo assis" className="logo"/>
        </div>
        <div className="menu-wrapper">
            <ul>
              <li>
                <Link
                  to="/data"
                  className="button">
                  <DataIcon className="icon"/>
                  <Typography variant="caption" className="text">
                    Dados
                  </Typography>
                </Link>
              </li>
              {authenticatedUser.type === 'menager' && (
                <li>
                  <Link
                    to="/users"
                    className="button">
                    <UsersIcon className="icon"/>
                    <Typography variant="caption" className="text">
                      Gestão
                    </Typography>
                  </Link>
                  {/* <ButtonBase>
                  </ButtonBase> */}
                </li>
              )}
              { (authenticatedUser.type === 'menager' && authenticatedUser.adminModule === true) && (
                <li>
                  <Link
                    to="/clients"
                    className="button">
                    <UsersIcon className="icon"/>
                    <Typography variant="caption" className="text">
                      Clientes
                    </Typography>
                  </Link>
                  {/* <ButtonBase>
                  </ButtonBase> */}
                </li>
              )}
              {authenticatedUser.type === 'menager' && (
                <li>
                  <Link
                    to="/alerts"
                    className="button">
                    <AlertsIcon className="icon"/>
                    <Typography variant="caption" className="text">
                      Alertas
                    </Typography>
                  </Link>
                </li>
              )}

              {/* <li>
                <Link
                  to="/speech"
                  className="button">
                  <AlertsIcon className="icon"/>
                  <Typography variant="caption" className="text">
                    Fala
                  </Typography>
                </Link>
              </li> */}

              <li>
                <ButtonBase
                    className="button"
                    onClick={openNotificationsMenu}
                    >
                    <Badge
                      color="error" 
                      variant="dot" 
                      invisible={false}
                      >
                      <NotificationsIcon className="icon"/>
                    </Badge>
                </ButtonBase>
                
                <NotificationsMenu
                  anchorEl={notificationsMenuAnchorEl}
                  onClose={closeNotificationsMenu}
                />
              </li>

              <li>
                <SpeechContinuos className="button"/>
              </li>
              
              <li>
                <ButtonBase
                  className="button"
                  onClick={openUserMenu}>
                  <Badge color="error" variant="dot" invisible={authenticatedUser.whatsapp}>
                    <Avatar src={authenticatedUser.picture}/>
                  </Badge>
                </ButtonBase>
                <Menu
                  anchorEl={userMenuAnchorEl}
                  keepMounted
                  className="user-menu"
                  open={Boolean(userMenuAnchorEl)}
                  onClose={closeUserMenu}
                >
                  <MenuItem onClick={() => { window.location.href = '/profile'}}>
                    <Badge color="error" variant="dot" invisible={authenticatedUser.whatsapp}>
                      Perfil
                    </Badge>
                  </MenuItem>
                  <MenuItem onClick={() => { window.location.href = '/batchlog'}}>
                    Log de Remessas
                  </MenuItem>
                  <MenuItem onClick={() => {
                    window.localStorage.removeItem('token')
                    window.location.href = '/'
                    }}>Sair</MenuItem>
                </Menu>
              </li>
            </ul>
        </div>
      </div>
      
    </header>
  )
}
export default Header