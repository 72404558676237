import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getMetrics: ['payload'],
  getMetricsSuccess: ['payload'],
  getMetricsFailure: ['payload'],
})

const INITIAL_STATE = {
  getMetricsLoading: false,
  list: [],
}

const getMetrics = (state = INITIAL_STATE, action) => ({...state, getMetricsLoading: true})
const getMetricsSuccess = (state = INITIAL_STATE, action) => ({
  ...state, 
  getMetricsLoading: false,
  list: action.payload.metrics,
})
const getMetricsFailure = (state = INITIAL_STATE, action) => ({...state, getMetricsLoading: false })

export default createReducer(INITIAL_STATE, {
  [Types.GET_METRICS]: getMetrics,
  [Types.GET_METRICS_SUCCESS]: getMetricsSuccess,
  [Types.GET_METRICS_FAILURE]: getMetricsFailure,
})