import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getClasses: ['payload'],
  getClassesSuccess: ['payload'],
  getClassesFailure: ['payload'],
  getClassesNoFiltered: ['payload'],
  getClassesNoFilteredSuccess: ['payload'],
  getClassesNoFilteredFailure: ['payload'],
  getAllClasses: ['payload'],
  getAllClassesSuccess: ['payload'],
  getAllClassesFailure: ['payload'],
})

const INITIAL_STATE = {
  getClassesLoading: false,
  page: 0, 
  limit: 0, 
  list: [],
  total: 0,
  getClassesNoFilteredLoading: false,
  listNoFiltered: [],
  getAllClassesLoading: false,
  listAll: [],
  getAllClassesErrorMsg: null,
}

const getClasses = (state = INITIAL_STATE, action) => ({...state, getClassesLoading: true})
const getClassesSuccess = (state = INITIAL_STATE, action) => ({
  ...state, 
  getClassesLoading: false, 
  page: action.payload.page, 
  limit: action.payload.limit,
  list: action.payload.list,
  total: action.payload.total,
})
const getClassesFailure = (state = INITIAL_STATE, action) => ({
  ...state, 
  getClassesLoading: false,
  page: INITIAL_STATE.page, 
  limit: INITIAL_STATE.limit,
  list: INITIAL_STATE.list,
  total: INITIAL_STATE.total,
})

const getClassesNoFiltered = (state = INITIAL_STATE, action) => ({...state, getClassesNoFilteredLoading: true})
const getClassesNoFilteredSuccess = (state = INITIAL_STATE, action) => ({
  ...state, 
  getClassesNoFilteredLoading: false,
  listNoFiltered: action.payload.list,
})
const getClassesNoFilteredFailure = (state = INITIAL_STATE, action) => ({
  ...state, 
  getClassesNoFilteredLoading: false,
  listNoFiltered: INITIAL_STATE.listNoFiltered,
})

const getAllClasses = (state = INITIAL_STATE, action) => ({...state, getAllClassesLoading: true})
const getAllClassesSuccess = (state = INITIAL_STATE, action) => ({
  ...state, 
  getAllClassesLoading: false,
  listAll: action.payload.list,
})
const getAllClassesFailure = (state = INITIAL_STATE, action) => ({
  ...state, 
  getAllClassesLoading: false,
  listAll: INITIAL_STATE.listAll,
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_CLASSES]: getClasses,
  [Types.GET_CLASSES_SUCCESS]: getClassesSuccess,
  [Types.GET_CLASSES_FAILURE]: getClassesFailure,

  [Types.GET_CLASSES_NO_FILTERED]: getClassesNoFiltered,
  [Types.GET_CLASSES_NO_FILTERED_SUCCESS]: getClassesNoFilteredSuccess,
  [Types.GET_CLASSES_NO_FILTERED_FAILURE]: getClassesNoFilteredFailure,

  [Types.GET_ALL_CLASSES]: getAllClasses,
  [Types.GET_ALL_CLASSES_SUCCESS]: getAllClassesSuccess,
  [Types.GET_ALL_CLASSES_FAILURE]: getAllClassesFailure,
})