/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useMemo} from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'
import CourseIcon from '@material-ui/icons/MenuBook'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import EvasionBar from '../../components/EvasionBar'
import FilterButton from '../../components/FilterButton'
import Loading from '../../components/Loading'

import CourseFilterPopup from '../../containers/CourseFilterPopup'

import { Creators as AppActions } from '../../store/ducks/app'
import { Creators as CourseActions } from '../../store/ducks/course'

import './style.sass'

const CoursesBlock = (props) => {

  const dispatch = useDispatch()

  const [order, setOrder] = useState({by: 'evasion', order: 'desc'})
  
  // const [finished, setFinished] = useState(false)
  const finished = useSelector(state => state.app.finished)
  const endDate = useSelector(state => state.app.endDate)
  const startDate = useSelector(state => state.app.startDate)
  const selectedBatch = useSelector(state => state.app.selectedBatch)
  const selectedTutors = useSelector(state => state.app.selectedTutors)
  const selectedCourses = useSelector(state => state.app.selectedCourses)
  const selectedClasses = useSelector(state => state.app.selectedClasses)
  const selectedStudents = useSelector(state => state.app.selectedStudents)
  const filters = useSelector(state => state.app.customFilters)

  const getCoursesLoading = useSelector(state => state.course.getCoursesLoading)
  const courses = useSelector(state => state.course.list)
  const total = useSelector(state => state.course.total)
  
  useEffect(() => {
    if(selectedBatch){
      dispatch(CourseActions.getCourses({
        limit: 1000, 
        finished,
        batchId: selectedBatch,
        tutorIds: selectedTutors.map(selectedTutor => selectedTutor.tutorId),
        courseIds: selectedCourses.map(selectedCourse => selectedCourse.courseId),
        classIds: selectedClasses.map(selectedClass => selectedClass.classId),
        studentIds: selectedStudents.map(selectedStudent => selectedStudent.studentId),
        startDate, 
        endDate,
        order,
        filters
      }))
    }
  }, [
    // dispatch,
    finished,
    selectedBatch,
    selectedTutors,
    selectedCourses,
    selectedClasses,
    selectedStudents,
    // startDate,
    // endDate,
    order, 
    filters
  ])

  const onSelectCourses = (courses) => {
    dispatch(AppActions.setSelectedCourses(courses))
  }

  const panelSummaryContent = useMemo(() => (
    <Grid container>
      <Grid item xs className="title">
        <CourseIcon className="icon"/> 
        <Typography variant="h6">Cursos</Typography>
      </Grid>
      <Grid item xs={4} className="total">
        {getCoursesLoading && (
          <Loading small/>
        )}
        <Typography variant="button">Total: {total}</Typography>
      </Grid>
    </Grid>
  ), [getCoursesLoading])
  
  const panelDetailsContent = useMemo(() => (    
        <React.Fragment>
          <Grid container>
            <Grid item xs={12} className="filters">
              <CourseFilterPopup/>
              <FilterButton onChangeOrder={order => setOrder(order)} />
            </Grid>
            <Grid item xs={12}>
              <div className="scroll">
                {(courses.length === 0) && (
                  <div>Nenhum curso encontrado</div>
                )}
                {courses && courses.map(course => (
                  <EvasionBar
                    key={course._id}
                    data={course}
                    type="course"
                    seeMoreAction={() => onSelectCourses([course])}/>         
                ))}      
              </div>
            </Grid>
          </Grid>
        </React.Fragment>
  ), [courses])

  return (
    <Accordion className="courses-block">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {panelSummaryContent}
      </AccordionSummary>
      <AccordionDetails>
        {getCoursesLoading && (
          <Loading/>
        )}
        {panelDetailsContent}
      </AccordionDetails>
    </Accordion>
  )
}

CoursesBlock.defaultProps = {}

export default CoursesBlock