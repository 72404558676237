/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useMemo} from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'
import SubjectIcon from '@material-ui/icons/Description'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import EvasionBar from '../../components/EvasionBar'
import FilterButton from '../../components/FilterButton'
import Loading from '../../components/Loading'

import SubjectFilterPopup from '../../containers/SubjectFilterPopup'

import { Creators as AppActions } from '../../store/ducks/app'
import { Creators as SubjectActions } from '../../store/ducks/subject'

import './style.sass'

const SubjectsBlock = (props) => {

  const dispatch = useDispatch()

  const [order, setOrder] = useState({by: 'evasion', order: 'desc'})
  
  // const [finished, setFinished] = useState(false)
  const finished = useSelector(state => state.app.finished)
  const endDate = useSelector(state => state.app.endDate)
  const startDate = useSelector(state => state.app.startDate)
  const selectedBatch = useSelector(state => state.app.selectedBatch)
  const selectedCourses = useSelector(state => state.app.selectedCourses)
  const selectedClasses = useSelector(state => state.app.selectedClasses)
  const selectedSubjects = useSelector(state => state.app.selectedSubjects)
  const selectedStudents = useSelector(state => state.app.selectedStudents)
  const selectedTutors = useSelector(state => state.app.selectedTutors)
  const filters = useSelector(state => state.app.customFilters)
  const classification = useSelector(state => state.app.classification)

  const getSubjectsLoading = useSelector(state => state.subject.getSubjectsLoading)
  const subjects = useSelector(state => state.subject.list)
  const total = useSelector(state => state.subject.total)

  useEffect(() => {
    if(selectedBatch){
      dispatch(SubjectActions.getSubjects({
        limit: 1000, 
        finished,
        batchId: selectedBatch,
        tutorIds: selectedTutors.map(selectedTutor => selectedTutor.tutorId),
        courseIds: selectedCourses.map(selectedCourse => selectedCourse.courseId),
        classIds: selectedClasses.map(selectedClass => selectedClass.classId),
        subjectIds: selectedSubjects.map(selectedSubject => selectedSubject.subjectId),
        studentIds: selectedStudents.map(selectedStudent => selectedStudent.studentId),
        // startDate, 
        // endDate,
        order,
        filters
      }))
    }
  }, [
    // dispatch,
    finished,
    selectedBatch,
    selectedTutors,
    selectedCourses,
    selectedClasses,
    selectedSubjects,
    selectedStudents,
    // startDate,
    // endDate,
    order,
    filters
  ])

  const onSelectSubjects = (subject) => {
    dispatch(AppActions.setSelectedSubject(subject))
  }

  const panelSummaryContent = useMemo(() => (
    <Grid container>
      <Grid item xs className="title">
        <SubjectIcon className="icon"/> 
        <Typography variant="h6">Disciplinas</Typography>
      </Grid>
      <Grid item xs={4} className="total">
        {getSubjectsLoading && (
          <Loading small/>
        )}
        <Typography variant="button">Total: {total}</Typography>
      </Grid>
    </Grid>
  ), [getSubjectsLoading])

  const panelDetailsContent = useMemo(() => (
    <Grid container>
      <Grid item xs={12} className="filters">
        <SubjectFilterPopup/>
        <FilterButton onChangeOrder={order => setOrder(order)} />
      </Grid>
      <Grid item xs={12}>
        <div className="scroll">
          {(subjects.length === 0) && (
            <div>Nenhuma disciplina encontrada</div>
          )}
          {subjects && subjects.map(subject => (
            <EvasionBar
              key={subject._id}
              data={subject}
              type="subject"
              classification={`classification`}
              seeMoreAction={() => onSelectSubjects([subject])}/>         
          ))}      
        </div>
      </Grid>
    </Grid>
  ), [subjects])

  return (
    <Accordion className="subjects-block">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {panelSummaryContent}
      </AccordionSummary>
      <AccordionDetails>
        {getSubjectsLoading && (
          <Loading/>
        )}
        {panelDetailsContent}
      </AccordionDetails>
    </Accordion>
  )
}

SubjectsBlock.defaultProps = {}

export default SubjectsBlock