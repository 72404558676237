import React from 'react';
import { useSelector, useDispatch } from 'react-redux'

import "./style.sass";
import { ReactComponent as Chatbot } from './assis-chat-bot.svg'


const Icon = () => {

  const [defaultStyle, setDefaultStyle] = React.useState(false)
  let statusChat  = useSelector(state => state.chat.status)

  React.useEffect( () => {
    setDefaultStyle(false)  
    if(statusChat === 'success' || statusChat === 'failure'){
       setTimeout(() =>{
        setDefaultStyle(true)
     },3000)
    }
  },[statusChat])

  return (
    <div
      class="chatbot-icon-wrapper"
      id="chatbotlogo"
    >
      <div className={`circulo ${defaultStyle ? '' : statusChat} `}><Chatbot height="60px" width="60px" /> </div>
      <div class="chatbot-icon-text"><img src={`${process.env.PUBLIC_URL}/chatbot-icon-logo.svg`} alt="" /></div>
    </div>

  )
}

export default Icon