
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as ChatActions } from "../../store/ducks/chat";
import { Apresentacao } from "../../containers/Chat/chatMessages";
import {
  Encerramento,
  EncerramentoSug,
  apresentationSuggestion,
  suggestionsWelcome
} from "../../containers/Chat/chatMessages";

import "./style.sass";

const Suggestion = (props) => {
  let user = useSelector((state) => state.auth.authenticatedUser);
  const dispatch = useDispatch();


  
  // const makeFirstUpper = (text) => {
  //   let string = text.toLowerCase().split(" ");
  //   for (var i = 0, x = string.length; i < x; i++) {
  //     if (string[i].length > 2)
  //       string[i] = string[i][0].toUpperCase() + string[i].substr(1);
  //   }

  //   return string.join(" ");
  // };

  const makeCharFirstUpper = (text) => {
    let string = text.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleClick = async (prop) => {
    if (prop.intent === "closing") {
      dispatch(ChatActions.autoMessage(prop));
      setTimeout(() => {
        dispatch(
          ChatActions.clearMessage(Apresentacao({ userName: user.name }))
        );
        dispatch(ChatActions.setOpenChat(false));
      }, 4000);
    } else {
      dispatch(
        ChatActions.sendMessage({
          message: makeCharFirstUpper(prop.text),
          origin: "me",
          intent: prop.intent,
        })
      );
    }
  };
  const WelcomeSuggestionComponent = (props) => (
    <>
      <button
        className="suggestion"
        type="button"
        onClick={() => handleClick(apresentationSuggestion)}
      >
        {/* {makeCharFirstUpper(apresentationSuggestion.text)} */}
        Como você pode me ajudar?
      </button>
      {suggestionsWelcome?.map((suggestion, index) => (
        <React.Fragment key={index}>
          <button
            className="suggestion"
            type="button"
            onClick={() => handleClick(suggestion)}
          >
            {makeCharFirstUpper(suggestion.text)}
          </button>
        </React.Fragment>
      ))}
    </>
  );

  const AnswerSuggestionComponent = (props) => (
    <>
      {props.suggestions?.map((item) => (
        <button
          className="suggestion"
          type="button"
          onClick={() => handleClick(item)}
        >
          {makeCharFirstUpper(item.text)}
        </button>
      ))}
      <button
        className="suggestion"
        type="button"
        onClick={() => handleClick(Encerramento(), EncerramentoSug())}
      >
        {makeCharFirstUpper(EncerramentoSug())}
      </button>
    </>
  );

  const withoutSuggestion =
    props.intent === "Default Fallback Intent" ||
    props.intent === "rescue" ||
    props.origin === "me" ||
    props.intent === "AJUDA ASSIS" ||
    props.intent === "closing";

  const welcomeSuggestion = props.intent === "welcome" && !withoutSuggestion;
  const answerSuggestion = !welcomeSuggestion && !withoutSuggestion;

  return (
    <>
      {
        {
          "true": "",
        }[withoutSuggestion]
      }
      {
        {
          "true": <WelcomeSuggestionComponent {...props} />,
        }[welcomeSuggestion]
      }
      {
        {
          "true": <AnswerSuggestionComponent {...props} />,
        }[answerSuggestion]
      }
    </>
  );
};

export default Suggestion;
