/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Badge,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  OutlinedInput,
  Typography,
  Paper,
  Popover,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import Alert from "@material-ui/lab/Alert";

import MenagerIcon from "@material-ui/icons/Grade";
import TutorIcon from "@material-ui/icons/School";
import SearchIcon from "@material-ui/icons/Search";
import AddMenagerIcon from "@material-ui/icons/PersonAdd";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import ClearIcon from "@material-ui/icons/Close";

import UserFormDialog from "../../containers/UserFormDialog";
import SendAccessDialog from "../../containers/SendAccessDialog";
import UserCard from "../../components/UserCard";
import Loading from "../../components/Loading";

import { Creators as UserActions } from "../../store/ducks/user";

import "./style.sass";

const Users = (props) => {
  const dispatch = useDispatch();

  const searchRef = useRef(null);
  // const filterhRef = useRef(null)

  const [search, setSearch] = useState(null);
  const [filter, setFilter] = useState("all");
  const [localMenagerPage, setLocalMenagerPage] = useState(1);
  const [localTutorPage, setLocalTutorPage] = useState(1);
  // const [order, setOrder] = useState({by: 'evasion', order: 'desc'})
  const [infoEl, setInfoEl] = useState(null);

  const [showSendAccessDialog, setShowSendAccessDialog] = useState(false);

  const menagerUsers = useSelector((state) => state.user.menagerList);
  const showMenagerUsersLoading = useSelector(
    (state) => state.user.getMenagerUsersLoading
  );
  const menagerPage = useSelector((state) => state.user.menagerPage);
  const menagerTotal = useSelector((state) => state.user.menagerTotal);
  const [menagerLimit, setMenagerLimit] = useState(10);

  const tutorUsers = useSelector((state) => state.user.tutorList);
  const showTutorUsersLoading = useSelector(
    (state) => state.user.getTutorUsersLoading
  );
  const tutorPage = useSelector((state) => state.user.tutorPage);
  const tutorTotal = useSelector((state) => state.user.tutorTotal);
  const [tutorLimit, setTutorLimit] = useState(10);

  const usersNoAccessTotal = useSelector(
    (state) => state.user.usersNoAccessTotal
  );

  const authenticatedUser = useSelector(
    (state) => state.auth.authenticatedUser
  );

  const [formData, setFormData] = useState({
    search: "",
  });

  const inputChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setFormData({ ...formData, [name]: value });
  };

  const onSearch = (event) => {
    setSearch(formData.search);
  };

  const onChangeFilter = (event) => {
    setFilter(event.target.value);
  };

  useEffect(() => {
    dispatch(UserActions.getUsersNoAccess());
  }, []);

  // useEffect(() => {
  //   let params = {
  //     search,
  //     filter,
  //     page: 1,
  //     limit: 100,
  //   }
  //   dispatch(UserActions.getMenagerUsers(params))
  //   dispatch(UserActions.getTutorUsers(params))
  // }, [search, filter])

  useEffect(() => {
    let params = {
      search,
      filter,
      page: localMenagerPage,
      limit: menagerLimit,
      company: authenticatedUser.company,
    };
    dispatch(UserActions.getMenagerUsers(params));
  }, [localMenagerPage, search, filter]);

  useEffect(() => {
    let params = {
      search,
      filter,
      page: localTutorPage,
      limit: tutorLimit,
      company: authenticatedUser.company,
    };
    dispatch(UserActions.getTutorUsers(params));
  }, [localTutorPage, search, filter]);

  const clearSearch = () => {
    setFormData({ ...formData, search: "" });
    if (search) setSearch("");
  };

  return (
    <div className="users">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <form
            className="search-form"
            noValidate
            onSubmit={(event) => {
              event.preventDefault();
              onSearch();
            }}
          >
            <FormControl size="small" className="search">
              <OutlinedInput
                placeholder="Buscar usuários"
                variant="outlined"
                name="search"
                value={formData.search}
                onChange={inputChange}
                inputRef={searchRef}
              />
            </FormControl>
            {formData.search && (
              <IconButton
                className="clear-button"
                size="small"
                onClick={clearSearch}
              >
                <ClearIcon />
              </IconButton>
            )}
            <Button type="submit" color="primary" variant="outlined">
              <SearchIcon />
            </Button>
          </form>
          <RadioGroup row value={filter} onChange={onChangeFilter}>
            <FormControlLabel
              value="all"
              control={<Radio color="primary" />}
              label="Todos"
            />
            <FormControlLabel
              value="no-access"
              control={<Radio color="primary" />}
              label="Nunca acessaram"
            />
          </RadioGroup>
          <Badge
            className="new-users-button"
            color="error"
            badgeContent={usersNoAccessTotal}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => setShowSendAccessDialog(true)}
            >
              Acesso para novos
            </Button>
          </Badge>
        </Grid>
        <Grid item xs={12}></Grid>

        <Grid item xs={6}>
          <Paper className="block">
            {showMenagerUsersLoading && <Loading />}
            <div className="title">
              <MenagerIcon className="icon" />
              <Typography variant="h6">Gestores</Typography>
              <Button
                className="add-menager-button"
                size="small"
                variant="outlined"
                onClick={() => dispatch(UserActions.openNewUserForm())}
              >
                <AddMenagerIcon fontSize="small" />
                Cadastrar gestor
              </Button>
            </div>
            <div className="list">
              {!showMenagerUsersLoading && !menagerUsers.length && (
                <Alert severity="warning">
                  Nenhum usuário do tipo gestor foi encontrado
                </Alert>
              )}
              <Grid container spacing={2}>
                {menagerUsers &&
                  menagerUsers.map((user) => (
                    <Grid item xs={6} key={user._id}>
                      <UserCard
                        data={user}
                        itsMe={authenticatedUser._id === user._id}
                        changeToMenagerAction={() =>
                          dispatch(
                            UserActions.changeUserRoleToMenager(user._id)
                          )
                        }
                        changeToTutorAction={() =>
                          dispatch(UserActions.changeUserRoleToTutor(user._id))
                        }
                        enableAction={() =>
                          dispatch(UserActions.enableUser(user._id))
                        }
                        disableAction={() =>
                          dispatch(UserActions.disableUser(user._id))
                        }
                        deleteAction={() =>
                          dispatch(UserActions.deleteUser(user._id))
                        }
                        sendAccessAction={() =>
                          dispatch(
                            UserActions.sendAccess({ userIds: [user._id] })
                          )
                        }
                        tutorModule={authenticatedUser.tutorModule}
                      />
                    </Grid>
                  ))}
              </Grid>
            </div>
            <div className="pagination-wrapper">
              <Pagination
                color={"primary"}
                size="small"
                count={Math.ceil(menagerTotal / menagerLimit)}
                siblingCount={3}
                showFirstButton={true}
                showLastButton={true}
                page={menagerPage}
                onChange={(event, page) => setLocalMenagerPage(page)}
              />
            </div>
          </Paper>
        </Grid>

        <Grid item xs={6}>
          <Paper className="block">
            {showTutorUsersLoading && <Loading />}
            <div className="title">
              <TutorIcon className="icon" />
              <Typography variant="h6">Tutores</Typography>
              <IconButton
                className="info-tutor-button"
                size="small"
                color="primary"
                onClick={(event) => setInfoEl(event.currentTarget)}
              >
                <InfoIcon className="icon" />
              </IconButton>
            </div>
            <Popover
              open={Boolean(infoEl)}
              anchorEl={infoEl}
              onClose={() => setInfoEl(null)}
              anchorOrigin={{
                horizontal: "left",
              }}
              transformOrigin={{
                horizontal: "right",
              }}
              disableRestoreFocus
            >
              <Typography className="info-tutor-popover-text" variant="body2">
                Todos os tutores enviados são cadastrados como usuários do tipo
                tutor
              </Typography>
            </Popover>
            <div className="list">
              {!showTutorUsersLoading && !tutorUsers.length && (
                <Alert severity="warning">
                  Nenhum usuário do tipo tutor foi encontrado
                </Alert>
              )}
              <Grid container spacing={2}>
                {tutorUsers &&
                  tutorUsers.map((user) => (
                    <Grid item xs={6} key={user._id}>
                      <UserCard
                        data={user}
                        changeToMenagerAction={() =>
                          dispatch(
                            UserActions.changeUserRoleToMenager(user._id)
                          )
                        }
                        changeToTutorAction={() =>
                          dispatch(UserActions.changeUserRoleToTutor(user._id))
                        }
                        enableAction={() =>
                          dispatch(UserActions.enableUser(user._id))
                        }
                        disableAction={() =>
                          dispatch(UserActions.disableUser(user._id))
                        }
                        deleteAction={() =>
                          dispatch(UserActions.deleteUser(user._id))
                        }
                        sendAccessAction={() =>
                          dispatch(
                            UserActions.sendAccess({ userIds: [user._id] })
                          )
                        }
                        tutorModule={authenticatedUser.tutorModule}
                      />
                    </Grid>
                  ))}
              </Grid>
            </div>
            <div className="pagination-wrapper">
              <Pagination
                color={"primary"}
                size="small"
                count={Math.ceil(tutorTotal / tutorLimit)}
                siblingCount={3}
                showFirstButton={true}
                showLastButton={true}
                page={tutorPage}
                onChange={(event, page) => setLocalTutorPage(page)}
              />
            </div>
          </Paper>
        </Grid>
      </Grid>

      <UserFormDialog />
      {showSendAccessDialog && (
        <SendAccessDialog onClose={() => setShowSendAccessDialog(false)} />
      )}
    </div>
  );
};
export default Users;
