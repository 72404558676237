import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Snackbar,
  Paper,
  Typography,
  IconButton,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import InfoIcon from '@material-ui/icons/Info'
import SuccessIcon from '@material-ui/icons/CheckCircle'
import WarningIcon from '@material-ui/icons/Warning'
import DangerIcon from '@material-ui/icons/Cancel'
import CloseIcon from '@material-ui/icons/Close'

import { Creators as AppActions } from '../../store/ducks/app';

import './style.sass'

const SnackbarGlobal = (props) => {
  const dispatch = useDispatch();

  const show = useSelector(state => state.app.showSnackbar)
  const title = useSelector(state => state.app.snackbarTitle)
  const text = useSelector(state => state.app.snackbarText)
  const type = useSelector(state => state.app.snackbarType)
  
  const handleClose = () => {
    dispatch(AppActions.closeSnackbar())
  }

  return (
    <Snackbar 
      className="snackbar"
      open={show} 
      autoHideDuration={6000}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      onClose={handleClose}>
        <Paper 
          className={`snackbar-content ${type}`}>
          <Grid container spacing={2}>
            <Grid item xs={1} className="icon-wrapper">
              {(type === 'info') && (<InfoIcon/>)}
              {(type === 'success') && (<SuccessIcon/>)}
              {(type === 'warning') && (<WarningIcon/>)}
              {(type === 'danger') && (<DangerIcon/>)}              
            </Grid>
            <Grid item xs>
              <Typography variant="body2" className="title">{title}</Typography>
              <Typography variant="body2">{text}</Typography>
            </Grid>
            <Grid>
              <IconButton size="small" onClick={handleClose}>
                <CloseIcon size="small"/>
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
    </Snackbar>
  )
}

export default SnackbarGlobal