import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import FilterPopup from '../../components/FilterPopup'

import { Creators as AppActions } from '../../store/ducks/app'
import { Creators as TutorActions } from '../../store/ducks/tutor'

import './style.sass'

const TutorFilterPopup = (props) => {

  const dispatch = useDispatch()

  const finished = useSelector(state => state.app.finished)
  const startDate = useSelector(state => state.app.startDate)
  const endDate = useSelector(state => state.app.endDate)

  const loading = useSelector(state => state.tutor.getTutorsNoFilteredLoading)
  const tutors = useSelector(state => state.tutor.listNoFiltered)
  const selectedTutors = useSelector(state => state.app.selectedTutors)
  
  const onSelectTutors = (tutors) => {
    dispatch(AppActions.setSelectedTutors(tutors))
  }

  const onOpen = () => {
    if(!tutors.length){
      dispatch(TutorActions.getTutorsNoFiltered({
        limit: 1000, 
        finished,
        startDate, 
        endDate,
      }))
    }
  }

  return (
    <div className="tutor-filter-popup">
      <FilterPopup
        label="Tutores"
        {...props}
        options={tutors}
        selectedOptions={selectedTutors}
        loading={loading}
        onOpen={onOpen}
        onConfirm={onSelectTutors}
      />
    </div>
  )
}

TutorFilterPopup.defaultProps = {
  buttonSize: 'medium',
  colorSize: 'medium',
}

export default TutorFilterPopup