import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  TextField,
  Paper,
} from '@material-ui/core/'
import Alert from '@material-ui/lab/Alert'

import Loading from '../../components/Loading'

import Utils from '../../Utils'
import Logo from './img/logo.png'
import { Creators as loginActions } from '../../store/ducks/auth'
import './style.sass'

const Login = () => {
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  })

  const inputChange = (event) => {
    let name = event.target.name
    let value = event.target.value
    setFormData({ ...formData, [name]: value })
  }

  const submitAction = (event) => {
    event.preventDefault()
    let result = Utils.validateForm(event.target)
    if (result !== true) {
      setFormData({ ...formData, ...result })
    } else {
      const dataToSend = {
        username: formData.username,
        password: formData.password,
      }
      dispatch(loginActions.login(dataToSend))
    }
  }

  return (
    <div className="login">
      {auth.showLoginLoading && (
        <Loading/>
      )}
      <Paper className="login-form-wrapper">
        <form
          noValidate 
          autoComplete="off"
          onSubmit={submitAction}
        >
          <div className="logo-wrapper">
            <img src={Logo} alt="logo assis" className="logo"/>
          </div>
          {auth.loginFailureMsg !== null && (
            <div className="error-wrapper">
              <Alert severity="error">{auth.loginFailureMsg}</Alert>
            </div>
          )}
          <div>
            <TextField
              name="username"
              label="Email"
              required
              margin="normal"
              variant="outlined"
              fullWidth
              onChange={inputChange}
              value={formData.username}
              error={formData.usernameHasError}
              helperText={formData.usernameErrorText}
            />
          </div>
          <div>
            <TextField
              name="password"
              label="Senha"
              required
              type="password"
              margin="normal"
              variant="outlined"
              fullWidth
              onChange={inputChange}
              value={formData.password}
              error={formData.passwordHasError}
              helperText={formData.passwordErrorText}
            />
          </div>
          <div className="wrapper-button">
            <Button
              type="submit"
              className="login-form-button"
              variant="contained" 
              color="primary">
              Entrar
            </Button>
          </div>
        </form>

      </Paper>

    </div>
  )
}

export default Login