import { put, call, takeLatest, all } from 'redux-saga/effects'
import Api from '../Api'
import { Types } from '../ducks/class'

function* getClasses(action) {
  try {
    const response = yield call(Api.getClasses, action.payload)
    yield put({type: Types.GET_CLASSES_SUCCESS, payload: response})
  } catch (error) {
    yield put({type: Types.GET_CLASSES_FAILURE, errorMessage: error.message})
  }
}

function* getClassesNoFiltered(action) {
  try {
    const response = yield call(Api.getAllClasses, action.payload)
    yield put({type: Types.GET_CLASSES_NO_FILTERED_SUCCESS, payload: response})
  } catch (error) {
    yield put({type: Types.GET_CLASSES_NO_FILTERED_FAILURE, errorMessage: error.message})
  }
}

function* getAllClasses(action) {
  try {
    const response = yield call(Api.getAllClasses, action.payload)
    yield put({type: Types.GET_ALL_CLASSES_SUCCESS, payload: response})
  } catch (error) {
    yield put({type: Types.GET_ALL_CLASSES_FAILURE, errorMessage: error.message})
  }
}

export default function* () {
  yield all([
    takeLatest(Types.GET_CLASSES, getClasses),
    takeLatest(Types.GET_CLASSES_NO_FILTERED, getClassesNoFiltered),
    takeLatest(Types.GET_ALL_CLASSES, getAllClasses),
  ])
}