import { put, call, takeLatest, all } from 'redux-saga/effects'
import Api from '../Api'
import { Types } from '../ducks/course'

function* getCourses(action) {
  try {
    const response = yield call(Api.getCourses, action.payload)
    yield put({type: Types.GET_COURSES_SUCCESS, payload: response})
  } catch (error) {
    yield put({type: Types.GET_COURSES_FAILURE, errorMessage: error.message})
  }
}

function* getCoursesNoFiltered(action) {
  try {
    const response = yield call(Api.getAllCourses, action.payload)
    yield put({type: Types.GET_COURSES_NO_FILTERED_SUCCESS, payload: response})
  } catch (error) {
    yield put({type: Types.GET_COURSES_NO_FILTERED_FAILURE, errorMessage: error.message})
  }
}

function* getAllCourses(action) {
  try {
    const response = yield call(Api.getAllCourses, action.payload)
    yield put({type: Types.GET_ALL_COURSES_SUCCESS, payload: response})
  } catch (error) {
    yield put({type: Types.GET_ALL_COURSES_FAILURE, errorMessage: error.message})
  }
}

export default function* () {
  yield all([
    takeLatest(Types.GET_COURSES, getCourses),
    takeLatest(Types.GET_COURSES_NO_FILTERED, getCoursesNoFiltered),
    takeLatest(Types.GET_ALL_COURSES, getAllCourses),
  ])
}