import React, {useState} from 'react'

import {
  Grid,
  Button,
  Typography,
  LinearProgress,
  // Popover,
  Paper,
} from '@material-ui/core'
// import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

import SeeMoreIcon from '@material-ui/icons/KeyboardArrowDown'
// import StudentsIcon from '@material-ui/icons/People'
import AttemptsIcon from '@material-ui/icons/TransferWithinAStation'
import OnlineTimeIcon from '@material-ui/icons/Schedule'
import GradeIcon from '@material-ui/icons/AssignmentTurnedIn'
import PrecedenceIcon from '@material-ui/icons/Timer'

import './style.sass'

const IndicationBlock = (props) => {
  
  return (
    <Paper 
      className={'block indication-block'+ (props.open?' open-info':'')}
      onClick={props.onClick}
      >
      <Grid 
        className="indication-block-wrapper" 
        container 
        alignItems="center" 
        spacing={2}
        >
        <Grid item xs={12}>
          {props.name && (
            <Typography variant="subtitle2">{props.name}</Typography>
          )}
        </Grid>
        {props.hidePercent === false && (
          <Grid item>
            <Typography variant="h6" className="percent">{props.percent.toFixed(0)}%</Typography> 
          </Grid>
        )}
        <Grid item xs>
          <LinearProgress 
            className="bar"
            variant="determinate"
            value={props.percent}
          />      
        </Grid>
        <Grid item>
          <SeeMoreIcon className="see-more-icon"/>
        </Grid>
      </Grid>
      <Paper className="hover-info">
        {/* <Typography 
          variant="subtitle2" 
          align="center"
          gutterBottom>
            {props.nStudents} Alunos
          </Typography> */}
        <div className="student-button-wrapper">
          <Button 
            size="small"
            onClick={() => {props.studentsClickAction()}}>
            {props.nStudents} Alunos
          </Button>
        </div>
        <Typography 
          variant="caption"
          gutterBottom>
            {props.description}  
        </Typography>
        <Typography 
          variant="subtitle2"
          gutterBottom>
            Métricas
        </Typography>
        <div className="icon-text">
          <OnlineTimeIcon className="icon"/> 
          <Typography variant="caption">
            {props.access}
          </Typography>
        </div>
        <div className="icon-text">
          <AttemptsIcon className="icon"/> 
          <Typography variant="caption">
            {props.attempts}
          </Typography>
        </div>
        <div className="icon-text">
          <GradeIcon className="icon"/> 
          <Typography variant="caption">
            {props.grade}
          </Typography>
        </div>
        <div className="icon-text">
          <PrecedenceIcon className="icon"/> 
          <Typography variant="caption">
            {props.precedency}
          </Typography>
        </div>
      </Paper>
    </Paper>
  )
}

IndicationBlock.defaultProps = {
  className: '', 
  hidePercent: false,
  name: null,
  percent: 0,
  studentsClickAction: () => {}
}

export default IndicationBlock