import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import {
  ButtonBase,
  Typography,
  Paper,
  Tooltip,
} from '@material-ui/core'
import AnalysisIcon from '@material-ui/icons/AssessmentOutlined'
import FilterIcon from '@material-ui/icons/TuneOutlined'
import InfoIcon from '@material-ui/icons/InfoOutlined'

import Loading from '../../components/Loading'

import { Creators as AppActions } from '../../store/ducks/app'
import { Creators as BatchActions } from '../../store/ducks/batch'
import EmptyImg from './imgs/empty.png'

import './style.sass'

const useStyles = makeStyles((theme) => ({
  tooltipWidth: {
    maxWidth: 150,
  },
}));
const EvasionChartBlock = (props) => {

  const dispatch = useDispatch()
  const classes = useStyles()

  const [analisys, setAnalisys] = useState([])
  const [evasorData, setEvasorData] = useState({})

  const selectedBatch = useSelector(state => state.app.selectedBatch)
  const selectedTutors = useSelector(state => state.app.selectedTutors)
  const selectedCourses = useSelector(state => state.app.selectedCourses)
  const selectedClasses = useSelector(state => state.app.selectedClasses)
  const selectedStudents = useSelector(state => state.app.selectedStudents)
  const filters = useSelector(state => state.app.customFilters)
  const finished = useSelector(state => state.app.finished)
  
  const batchs = useSelector(state => state.batch.list)
  const getBatchInfoLoading = useSelector(state => state.batch.getBatchInfoLoading)
  const batchInfo = useSelector(state => state.batch.batchInfo)

  useEffect(() => {
    if(batchs.length)
      dispatch(AppActions.setSelectedBatch(batchs[0]._id))
  }, [batchs])

  
  useEffect(() => {
    if(selectedBatch !== null)
    dispatch(BatchActions.getBatchInfo({
      courseIds: selectedCourses.map(courses => courses.courseId), 
      classIds: selectedClasses.map(selectedClass => selectedClass.classId),
      tutorIds: selectedTutors.map(tutors => tutors.tutorId),
      studentIds: selectedStudents.map(student => student.studentId),
      batchId: selectedBatch,
      finished,
      // startDate,
      // endDate,
      filters      
    }))
  }, [
    selectedBatch,
    selectedTutors,
    selectedCourses,
    selectedClasses,
    selectedStudents,
    filters,
  ])
  
  useEffect(() => {
    if(batchInfo.analisys){
      setAnalisys(batchInfo.analisys || [])
      setEvasorData(batchInfo.evarsorConcludents.find(item => item.title === 'Evasores'))
    }
  }, [batchInfo])
    
  const setSelectStudent = (studentIds) => {
    dispatch(AppActions.setSelectedStudents(studentIds.map(studentId => ({studentId}))))
  }

  return (
    <div className="evasion-chart">
      {getBatchInfoLoading && (<Loading/>)}
      <div item xs className="title-header">
        <AnalysisIcon className="icon"/> 
        <Typography variant="h6">Analise comparativa</Typography>
      </div>
      {!batchInfo.studentCount && (
        <div className="empty-info">
          {batchInfo.studentCount === 0 && (
            <div className="msg">
              <FilterIcon className="filter-icon"/> 
              <Typography variant="h6">Não existem dados para essa consulta</Typography> 
            </div>
          )}
          <img src={EmptyImg} alt="Empty Chart"/>
        </div>
      )}
      {batchInfo.studentCount > 0 && (
        <div className="analysis-chart">
          <div className="analysis">
            <Typography variant="subtitle2">Total de alunos:</Typography>
            <Typography variant="h4">{batchInfo.studentCount}</Typography>
            <ul>
              <li>
                <Typography variant="subtitle2">Em relação à análise anterior:</Typography>
              </li>
              {analisys.map((analisysItem, key) => (
                <li key={key}>
                  <ButtonBase 
                    onClick={() => {setSelectStudent(analisysItem.studentIds)}}
                    className="analysis-item-button">
                    <div className="color-bar" style={{backgroundColor: analisysItem.color}}></div>
                    <div className="classifications">
                      <Typography variant="subtitle2" className="title">
                        {analisysItem.title} ({analisysItem.qty}|{analisysItem.percent.toFixed(2)}%) 
                      </Typography>
                      <Typography variant="subtitle2" className="value">
                        {analisysItem.lastDiff === 0 && (`Permaneceu igual`)}
                        {analisysItem.lastDiff > 0 && (`Aumentou ${(analisysItem.lastDiff).toFixed(2)}%`)}
                        {analisysItem.lastDiff < 0 && (`Diminuiu ${(analisysItem.lastDiff * -1).toFixed(2)}%`)}
                      </Typography>
                    </div>
                    <Typography variant="caption" className="previous">
                      Valor anterior ({analisysItem.previousQty}|{analisysItem.previousPercent.toFixed(2)}%)
                    </Typography>
                  </ButtonBase>
                </li>
              ))}
            </ul>
          </div>
          <div className="chart">
            <div className="bars">
              {analisys.map((analisysItem, key) => (
                <ButtonBase 
                  key={key}
                  className="bar"
                  onClick={() => {setSelectStudent(analisysItem.studentIds)}} 
                  style={{backgroundColor: analisysItem.color, height: ((analisysItem.qty/analisys.reduce((acc, curr) => acc + curr.qty, 0))*100)+'%'}}></ButtonBase>
              ))}
            </div>
            <div className="titles">
            {analisys?.map((analisysItem, key) => (
              <div key={key} className="title">{analisysItem.title}</div>
            ))}
            </div>
          </div>            
        </div>
      )}
      <Paper elevation="2" className="numbers-wrapper">
        <ul className="numbers">
          {analisys.map((analisysItem, key) => (
            <li key={key}>
              <ButtonBase 
                onClick={() => {setSelectStudent(analisysItem.studentIds)}}
                className="numbers-item-button">
                <div className="color-bar" style={{backgroundColor: analisysItem.color}}></div>
                <Typography variant="subtitle2" className="title">{analisysItem.title}</Typography>
                <Typography 
                  variant="h6" 
                  className="value">
                    {analisysItem.qty}
                    <Tooltip 
                      title={analisysItem.description}
                      classes={{ tooltip: classes.tooltipWidth }}
                      placement="right"
                      arrow>
                        <InfoIcon color="primary" className="info-icon"/>
                    </Tooltip>
                </Typography>
              </ButtonBase>
            </li>
          ))}
        </ul>
        {evasorData && (
          <div className="extra-numbers">
            <Typography variant="subtitle2" className="title">{evasorData.title}</Typography>
            <Typography 
              variant="h6" 
              className="value">
                {evasorData.qty}
                <Tooltip 
                  title={evasorData.description}
                  classes={{ tooltip: classes.tooltipWidth }}
                  placement="right"
                  arrow>
                    <InfoIcon color="primary" className="info-icon"/>
                </Tooltip>
            </Typography>
          </div>
        )}
      </Paper>
    </div>
  )
}

EvasionChartBlock.defaultProps = {
  hideCalendar: false
}

// EvasionChartBlock.prototype = {
//   hideCalendar: PropTypes.bool
// }

export default EvasionChartBlock