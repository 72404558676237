import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getIndicators: ['payload'],
  getIndicatorsSuccess: ['payload'],
  getIndicatorsFailure: ['payload'],
})

const INITIAL_STATE = {
  getIndicatorsLoading: false,
  list: [],
}

const getIndicators = (state = INITIAL_STATE, action) => ({...state, getIndicatorsLoading: true})
const getIndicatorsSuccess = (state = INITIAL_STATE, action) => ({
  ...state, 
  getIndicatorsLoading: false,
  list: action.payload.indicators,
})
const getIndicatorsFailure = (state = INITIAL_STATE, action) => ({...state, getIndicatorsLoading: false })

export default createReducer(INITIAL_STATE, {
  [Types.GET_INDICATORS]: getIndicators,
  [Types.GET_INDICATORS_SUCCESS]: getIndicatorsSuccess,
  [Types.GET_INDICATORS_FAILURE]: getIndicatorsFailure,
})