import React from 'react'
import { put, call, takeLatest, all} from 'redux-saga/effects'
import {delay} from 'redux-saga'
import Api from '../Api'
import { Types } from '../ducks/chat'
import '../ducks/index'
import {Guia, Fracasso, Especificas, Instruction} from '../../containers/Chat/chatMessages'

function* sendMessage(action) {
  try {
    let response = {};
    if(action.payload.intent === "AJUDA ASSIS"){
      response = Instruction()
      yield delay(1500)
      yield put({type: Types.SEND_FILTERS, payload: response[0]})
      yield put({type: Types.SEND_MESSAGE_SUCCESS, payload: response[0]})
      yield put({type: Types.STATE_MESSAGE_LOADING, payload: true})

      yield delay(1500)
      yield put({type: Types.SEND_FILTERS, payload: response[1]})
      yield put({type: Types.SEND_MESSAGE_SUCCESS, payload: response[1]})
      yield put({type: Types.STATE_MESSAGE_LOADING, payload: true})

      yield delay(1500)
      yield put({type:Types.SEND_FILTERS, payload: response[2]})
      yield put({type: Types.SEND_MESSAGE_SUCCESS, payload: response[2]})
      yield put({type: Types.STATE_MESSAGE_LOADING, payload: true})
      yield delay(1500)

      yield put({type: Types.SEND_FILTERS, payload: response[3]})
      yield put({type: Types.SEND_MESSAGE_SUCCESS, payload: response[3]})
    return
    }
    if(action.payload.origin === 'me'){
      response = yield call(Api.sendMessage, action.payload)
    }
    if(response.intent === 'Default Fallback Intent'){
      throw Fracasso()[action.payload.failCount];
    }
    if(response.intent === "error"){
      throw {
        message: <div dangerouslySetInnerHTML={{ __html: `${Especificas()} ${response.message} <strong> ${Guia()} </strong>`}}/>,
        origin: "assis",
        intent: "error"
      }
    }
    response.message = <div dangerouslySetInnerHTML={{ __html: `${Especificas()} ${response.intent === "error"? '' : 'Estou mostrando em tela'} ${response.message} <strong> ${Guia()} </strong>`}}/>;

    yield put({type: Types.SEND_FILTERS, payload: response})

  } catch (error) {
    console.error(JSON.stringify(error));
    yield put({type: Types.SEND_MESSAGE_FAILURE, errorMessage: error})
  }
}

export default function* () {
  yield all([
    takeLatest(Types.SEND_MESSAGE, sendMessage),
  ])
}