import React, { useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'

import {
  IconButton,
} from '@material-ui/core'
import MicIcon from "@material-ui/icons/Mic";

import { Creators as AppActions } from '../../store/ducks/app'
import { Creators as ChatActions } from '../../store/ducks/chat'

import './style.sass'

const SpeechContinuos = (props) => {
  const dispatch = useDispatch()

  const appSpeechActive = useSelector(state => state.app.appSpeechActive)  
  const fieldSpeechName = useSelector(state => state.app.fieldSpeechName)

  const commands = [
    {
      command: 'Olá assis',
      callback: () => {
        if(appSpeechActive && (fieldSpeechName === null))
          dispatch(ChatActions.setOpenChat(true))
      }
    },
  ]
  
  useSpeechRecognition({commands})
    
  const changeStatus = (event) => {
    dispatch(AppActions.setAppSpeech(!appSpeechActive))
  }

  useEffect(() => {
    if(appSpeechActive)
      SpeechRecognition.startListening({continuous: true})
    else if(fieldSpeechName === null)
      SpeechRecognition.stopListening()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appSpeechActive]);
    
  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return null
  }

  return (
    <div className={'speech-button '+props.className}>
      <IconButton 
        size="small" 
        className={appSpeechActive?'active':''}
        onClick={changeStatus}>
        <MicIcon className="icon"/>
      </IconButton>
    </div>
  )
}

SpeechContinuos.defaultProps = {
  callback: () => {},
}

export default SpeechContinuos