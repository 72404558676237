import React, {useState, useEffect, useRef} from 'react'

import {
  Chip,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core'

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

import Loading from '../../components/Loading'
import Utils from '../../Utils'

import './style.sass'

const FilterPopup = (props) => {

  const anchorMENU = useRef(null);
  const searchEl = useRef(null);
  const [elOffsetWidth, setElOffsetWidth] = React.useState(500)
  const [selectedOptions, setSelectedOptions] = useState(props.selectedOptions)
  const [filteredOptions, setFilteredOptions] = useState(props.options)
  
  const [open, setOpen] = React.useState(false)
  const [selectedInfo, setSelectedInfo] = React.useState(`Selecionar ${props.label}(s)`)
  const [search, setSearch] = React.useState('')
  
  useEffect(() => {
    setSelectedOptions(props.selectedOptions)
  }, [props.selectedOptions])

  useEffect(() => {
    if(selectedOptions.length === 1)
      setSelectedInfo(Utils.capitalize(selectedOptions[0].name))
    else if(selectedOptions.length > 1)
      setSelectedInfo(`${selectedOptions.length} Selecionado(s)`)
    else
      setSelectedInfo(`Selecionar ${props.label}`)
  }, [selectedOptions])

  useEffect(() => {
    let sortedOptions = props.options.sort((a, b) => {
      if(a.name > b.name)
        return 1
      if(a.name < b.name)
        return -1
      return 0
    })

    setFilteredOptions(sortedOptions)
  }, [props.options])

  const onSearch = (event) => {
    setSearch(event.target.value)
    setFilteredOptions(props.options.filter(option => option.name.toUpperCase().indexOf(event.target.value.toUpperCase()) >= 0 ))
  }

  const onChangeCheckbox = (isChecked, option) => {
    if(!isChecked)
      setSelectedOptions([...selectedOptions, option])
    else
      setSelectedOptions(selectedOptions.filter(pendingOption => pendingOption._id !== option._id))
  }
  
  const onOpen = (event) => {
    setOpen(true)
    props.onOpen()
  }

  const onCancel = (event) => {
    setOpen(false)
    if(selectedOptions !== props.selectedOptions)
      setSelectedOptions(props.selectedOptions)
  }

  const onConfirm = (event) => {
    setOpen(false)
    if(selectedOptions !== props.selectedOptions)
      props.onConfirm(selectedOptions)
  }

  const onRemove = (index) => {
    let options = [...selectedOptions]
    options.splice(index, 1)
    setSelectedOptions(options)
  }

  return (
    <div className="filter-popup">
      {selectedOptions.length > 0 && (
        <Chip 
          className=""
          label={selectedInfo}
          size={props.buttonSize}
          color={props.buttonColor}
          onClick={(event) => onOpen(event)}
          onDelete={() => props.onConfirm([])}
          />
      )}
      {selectedOptions.length === 0 && (
        <Chip 
          className=""
          label={selectedInfo}
          size={props.buttonSize}
          color={props.buttonColor}
          onClick={(event) => onOpen(event)}
          />
      )}
      <Dialog className="filter-popup-dialog" onClose={onCancel} open={open}>
        <DialogContent dividers>
          {props.loading && (<Loading/>)}
          <div ref={anchorMENU} className="search-bar" aria-disabled="true">
            <TextField
                className="search-field"
                size="small"
                variant="outlined"
                label={props.label}
                onChange={onSearch}
                value={search}
                style={{width: elOffsetWidth}}
                inputRef={searchEl}
                autoFocus
              />
          </div>
          <div className="selected-list">
            {selectedOptions.map((selectedOption, index) => (
              <Chip
                key={selectedOption._id}
                className="chip"
                variant="outlined"
                size="small"
                color="primary"
                onDelete={() => onRemove(index)}
                label={Utils.capitalize(selectedOption.name)}
              />
            ))}
          </div>
          <div className="list">
            {filteredOptions.map(option => (
              <FormControlLabel
                key={option._id}
                style={{width: elOffsetWidth}}
                control={
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selectedOptions.some(selectedItem => (selectedItem._id === option._id))}
                    onChange={(event) => onChangeCheckbox(selectedOptions.some(selectedItem => (selectedItem._id === option._id)), option)}
                  />
                }
                label={Utils.capitalize(option.name)}
              />
            ))}
            {filteredOptions.length === 0 && (<div style={{width: elOffsetWidth}} className="empty">Nenhum resultado encontrado</div>)}
          </div>
        </DialogContent>
        <DialogActions className="footer">
          <Button 
            variant="contained" 
            size="small"
            onClick={onCancel}>
            Cancelar
          </Button>
          <Button 
            className="confirm-button"
            variant="contained"
            color="primary"
            size="small"
            onClick={onConfirm}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

FilterPopup.defaultProps = {
  label: null,
  buttonSize: 'medium',
  buttonColor: 'primary',
  options: [],
  selectedOptions: [],
  loading: false,
  onOpen: () => {},
  onConfirm: () => {},
}

export default FilterPopup