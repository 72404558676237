import { put, call, takeLatest, all } from 'redux-saga/effects'
import Api from '../Api'
import { Types } from '../ducks/student'

function* getStudents(action) {
  try {
    const response = yield call(Api.getStudents, action.payload)
    yield put({type: Types.GET_STUDENTS_SUCCESS, payload: response})
  } catch (error) {
    yield put({type: Types.GET_STUDENTS_FAILURE, errorMessage: error.message})
  }
}

// function* getStudentsEvasion(action) {
//   try {
//     const response = yield call(Api.getStudentsEvasion, action.payload)
//     yield put({type: Types.GET_STUDENTS_EVASION_SUCCESS, payload: response})
//   } catch (error) {
//     yield put({type: Types.GET_STUDENTS_EVASION_FAILURE, errorMessage: error.message})
//   }
// }

export default function* () {
  yield all([
    takeLatest(Types.GET_STUDENTS, getStudents),
    // takeLatest(Types.GET_STUDENTS_EVASION, getStudentsEvasion),
  ])
}