import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  Avatar,
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
  Switch,
  FormControlLabel
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

import UserIcon from '@material-ui/icons/Person'
import PasswordIcon from '@material-ui/icons/VpnKey'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import Loading from '../../components/Loading'
import Utils from '../../Utils'

import { Creators as UserActions } from '../../store/ducks/user'
import { Creators as AppActions } from '../../store/ducks/app'

import './style.sass'

const Profile = (props) => {
  const dispatch = useDispatch()

  const formDataInitial = {
    name: '',
    email: '',
    whatsapp: '',
    actualPassword: '',
    password: '',
    optin: false
  }

  const [formData, setFormData] = useState(formDataInitial)
  const [showActualPassword, setShowActualPassword] = useState(false)
  const [showpassword, setShowpassword] = useState(false)
  const [newPicture, setNewPicture] = useState(null)

  const authenticatedUser = useSelector(state => state.auth.authenticatedUser)

  const showChangeProfilePictureLoading = useSelector(state => state.user.changeProfilePictureLoading)

  const showSaveMyDataLoading = useSelector(state => state.user.saveMyDataLoading)
  const saveMyDataSuccessMsg = useSelector(state => state.user.saveMyDataSuccessMsg)
  const saveMyDataErrorMsg = useSelector(state => state.user.saveMyDataErrorMsg)

  const showChangePasswordLoading = useSelector(state => state.user.changePasswordLoading)
  const changePasswordSuccessMsg = useSelector(state => state.user.changePasswordSuccessMsg)
  const changePasswordErrorMsg = useSelector(state => state.user.changePasswordErrorMsg)

  const inputChange = (event) => {
    let name = event.target.name
    let value = event.target.value

    if (name === 'whatsapp')
      value = Utils.phoneMask(value)

    if (name === 'optin')
      value = !formData.optin
  
    setFormData({ ...formData, [name]: value })
  }

  const myDataSubmitAction = (event) => {
    event.preventDefault()
    let result = Utils.validateForm(event.target)
    if (result !== true) {
      setFormData({})
      setFormData({ ...formData, ...result })
    } else {
      const dataToSend = {
        id: authenticatedUser._id,
        data: {
          name: formData.name,
          email: formData.email,
          whatsapp: formData.whatsapp?Utils.digitMask(formData.whatsapp):null,
        }
        // optin: formData.optin
      }
      dispatch(UserActions.saveMyData(dataToSend))
    }
  }

  const passwordSubmitAction = (event) => {
    event.preventDefault()
    let result = Utils.validateForm(event.target)
    if (result !== true) {
      setFormData({})
      setFormData({ ...formData, ...result })
    } else {
      const dataToSend = {
        id: authenticatedUser._id,
        data: {
          actualPassword: formData.actualPassword,
          password: formData.password,
        }
      }
      setFormData({
        ...formData,
        actualPasswordHasError: false,
        actualPasswordErrorText: null,
        passwordHasError: false,
        passwordErrorText: null,
      });
      dispatch(UserActions.changePassword(dataToSend))
    }
  }

  const changePicture = async (event) => {
    let file = event.target.files[0]

    if (file.size > 2000000) {
      dispatch(AppActions.showSnackbar({
        title: "Error ao alterar imagem",
        text: "Tamanho da imagem execedeu o limite, 2 MB.",
        type: "danger"
      }))
    } else {
      if (file) {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => setNewPicture(reader.result)
      } else {
        setNewPicture(null)
      }
    }
  }

  const saveNewPicture = async (event) => {
    dispatch(UserActions.changeProfilePicture({ picture: newPicture }))
    setNewPicture(null)
  }

  useEffect(() => {
    setFormData({
      name: authenticatedUser.name,
      email: authenticatedUser.email,
      whatsapp: Utils.phoneMask(authenticatedUser.whatsapp),
      optin: authenticatedUser.optin
    })
  }, [authenticatedUser])

  return (
    <div className="profile">
      <Grid container spacing={2}>
        <Grid item xs={12}></Grid>
        <Grid item xs={6}>
          {authenticatedUser.type === 'menager' && (
            <Paper className="block">
              <div className="title">
                <UserIcon className="icon" />
                <Typography variant="h6">Imagem do perfil</Typography>
              </div>
              <div className="picture-wrapper">
                {showChangeProfilePictureLoading && (<Loading />)}
                <Avatar src={newPicture || authenticatedUser.picture} className="picture" alt="" />
                <input
                  type="file"
                  id="fileSelector"
                  className="file-selector"
                  onChange={changePicture}
                  accept="image/*"
                />
                {!newPicture && (
                  <React.Fragment>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => document.getElementById('fileSelector').click()}>
                      Selecionar nova imagem
                    </Button>
                  </React.Fragment>
                )}
                {newPicture && (
                  <React.Fragment>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() => {
                        let inputFile = document.getElementById('fileSelector')
                        inputFile.value = ''
                        var event = new Event('change')
                        inputFile.dispatchEvent(event)
                        setNewPicture(null)
                      }}>
                      Cancelar
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={saveNewPicture}>
                      Salvar
                    </Button>
                  </React.Fragment>
                )}
              </div>
            </Paper>
          )}

          <Paper className="block">
            <div className="title">
              <PasswordIcon className="icon" />
              <Typography variant="h6">Senha</Typography>
            </div>
            {showChangePasswordLoading && (<Loading />)}
            {changePasswordSuccessMsg && (<Alert severity="success">{changePasswordSuccessMsg}</Alert>)}
            {changePasswordErrorMsg && (<Alert severity="error">{changePasswordErrorMsg}</Alert>)}
            <form
              id="password-form"
              noValidate
              autoComplete="off"
              onSubmit={passwordSubmitAction}>

              <Grid container spacing={2}>
                <Grid item xs={6} className="password-wrapper">
                  <TextField
                    name="actualPassword"
                    label="Senha atual"
                    required
                    margin="normal"
                    size="small"
                    fullWidth
                    type={showActualPassword ? 'text' : 'password'}
                    onChange={inputChange}
                    value={formData.actualPassword}
                    error={formData.actualPasswordHasError}
                    helperText={formData.actualPasswordErrorText}
                    inputProps={{
                      minLength: 4,
                      maxLength: 15
                    }}
                  />
                  <IconButton
                    className="visibilityButton"
                    onClick={() => setShowActualPassword(!showActualPassword)}
                  >
                    {showActualPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </Grid>
                <Grid item xs={6} className="password-wrapper">
                  <TextField
                    name="password"
                    label="Nova senha"
                    required
                    margin="normal"
                    size="small"
                    fullWidth
                    type={showpassword ? 'text' : 'password'}
                    onChange={inputChange}
                    value={formData.password}
                    error={formData.passwordHasError}
                    helperText={formData.passwordErrorText}
                    inputProps={{
                      minLength: 4,
                      maxLength: 15
                    }}
                  />
                  <IconButton
                    className="visibilityButton"
                    onClick={() => setShowpassword(!showpassword)}
                  >
                    {showpassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary">
                    Alterar senha
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>

        </Grid>
        <Grid item xs={6}>
          <Paper className="block">
            <div className="title">
              <UserIcon className="icon" />
              <Typography variant="h6">Meus Dados</Typography>
            </div>
            {showSaveMyDataLoading && (<Loading />)}
            {saveMyDataSuccessMsg && (<Alert severity="success">{saveMyDataSuccessMsg}</Alert>)}
            {saveMyDataErrorMsg && (<Alert severity="error">{saveMyDataErrorMsg}</Alert>)}
            <form
              id="mydata-form"
              noValidate
              autoComplete="off"
              onSubmit={myDataSubmitAction}>
              <TextField
                name="name"
                label="Nome"
                required
                margin="normal"
                size="small"
                fullWidth
                disabled={authenticatedUser.type !== 'menager'}
                onChange={inputChange}
                value={formData.name}
                error={formData.nameHasError}
                helperText={formData.nameErrorText}
              />
              <TextField
                name="email"
                label="Email"
                required
                margin="normal"
                size="small"
                fullWidth
                type="email"
                disabled={authenticatedUser.type !== 'menager'}
                onChange={inputChange}
                value={formData.email}
                error={formData.emailHasError}
                helperText={formData.emailErrorText}
              />
              <TextField
                name="whatsapp"
                label="Whatsapp"
                // required
                margin="normal"
                size="small"
                fullWidth
                type="text"
                onChange={inputChange}
                value={formData.whatsapp}
                error={formData.whatsappHasError}
                helperText={formData.whatsappErrorText}
                inputProps={{
                  minLength: 15
                }}
              />
              {/* <FormControlLabel
                control={<Switch color="primary" checked={formData.optin} onChange={inputChange}/>}
                label={"Aceito receber notificações via whatsapp do Assis"}
                name={"optin"}
              /> */}
              <br />
              <br />
              <br />
              <Button
                type="submit"
                variant="contained"
                color="primary">
                Salvar
              </Button>
            </form>
          </Paper>
        </Grid>

      </Grid>
    </div>
  )
}
export default Profile