/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useMemo} from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'
import ClassIcon from '@material-ui/icons/People'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import EvasionBar from '../../components/EvasionBar'
import FilterButton from '../../components/FilterButton'
import Loading from '../../components/Loading'

import ClassFilterPopup from '../../containers/ClassFilterPopup'

import { Creators as AppActions } from '../../store/ducks/app'
import { Creators as ClassActions } from '../../store/ducks/class'

import './style.sass'

const ClassesBlock = (props) => {

  const dispatch = useDispatch()

  const [order, setOrder] = useState({by: 'evasion', order: 'desc'})
  
  // const [finished, setFinished] = useState(false)
  const finished = useSelector(state => state.app.finished)
  const endDate = useSelector(state => state.app.endDate)
  const startDate = useSelector(state => state.app.startDate)
  const selectedBatch = useSelector(state => state.app.selectedBatch)
  const selectedCourses = useSelector(state => state.app.selectedCourses)
  const selectedClasses = useSelector(state => state.app.selectedClasses)
  const selectedStudents = useSelector(state => state.app.selectedStudents)
  const selectedTutors = useSelector(state => state.app.selectedTutors)
  const filters = useSelector(state => state.app.customFilters)

  const getClassesLoading = useSelector(state => state.class.getClassesLoading)
  const classes = useSelector(state => state.class.list)
  const total = useSelector(state => state.class.total)

  useEffect(() => {
    if(selectedBatch){
      dispatch(ClassActions.getClasses({
        limit: 1000, 
        finished,
        batchId: selectedBatch,
        tutorIds: selectedTutors.map(selectedTutor => selectedTutor.tutorId),
        courseIds: selectedCourses.map(selectedCourse => selectedCourse.courseId),
        classIds: selectedClasses.map(selectedClass => selectedClass.classId),
        studentIds: selectedStudents.map(selectedStudent => selectedStudent.studentId),
        startDate, 
        endDate,
        order,
        filters
      }))
    }
  }, [
    // dispatch,
    finished,
    selectedBatch,
    selectedTutors,
    selectedCourses,
    selectedClasses,
    selectedStudents,
    // startDate,
    // endDate,
    order, 
    filters
  ])

  const onSelectClasses = (clss) => {
    dispatch(AppActions.setSelectedClasses(clss))
  }

  const panelSummaryContent = useMemo(() => (
    <Grid container>
      <Grid item xs className="title">
        <ClassIcon className="icon"/> 
        <Typography variant="h6">Turmas</Typography>
      </Grid>
      <Grid item xs={4} className="total">
        {getClassesLoading && (
          <Loading small/>
        )}
        <Typography variant="button">Total: {total}</Typography>
      </Grid>
    </Grid>
  ), [getClassesLoading, total])

  const panelDetailsContent = useMemo(() => (
    <Grid container>
      <Grid item xs={12} className="filters">
        <ClassFilterPopup/>
        <FilterButton onChangeOrder={order => setOrder(order)} />
      </Grid>
      <Grid item xs={12}>
        <div className="scroll">
          {(classes.length === 0) && (
            <div>Nenhuma turma encontrado</div>
          )}
          {classes && classes.map(clss => (
            <EvasionBar
              key={clss._id}
              data={clss}
              type="class"
              seeMoreAction={() => onSelectClasses([clss])}/>         
          ))}      
        </div>
      </Grid>
    </Grid>
  ), [classes])

  return (
    <Accordion className="classes-block">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {panelSummaryContent}
      </AccordionSummary>
      <AccordionDetails>
        {getClassesLoading && (
          <Loading/>
        )}
        {panelDetailsContent}
      </AccordionDetails>
    </Accordion>
  )
}

ClassesBlock.defaultProps = {}

export default ClassesBlock