import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

import Utils from '../../Utils'
import { Creators as FeedbackActions } from '../../store/ducks/feedback'
import Loading from '../../components/Loading'
import './style.sass'

const FeedbackTipOrQuestionPopup = (props) => {

  const dispatch = useDispatch()

  const formDataInitial = {
    anotherAnswer: '',
    answers: [],
  }
  const [formData, setFormData] = useState(formDataInitial)

  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState(null);
  const [text, setText] = React.useState(null);
  const [summary, setSummary] = React.useState([]);
  const [actions, setActions] = React.useState([]);
  // const feedbackAskMeLater = window.localStorage.getItem('feedbackAskMeLater') || false;
  const feedbackTipOrQuestion = useSelector(state => state.feedback.tipOrQuestion);
  const confirmFeedbackTipDeliveredLoading = useSelector(state => state.feedback.confirmFeedbackTipDeliveredLoading);
  const saveFeedbackAnswerLoading = useSelector(state => state.feedback.saveFeedbackAnswerLoading);
  // const successMsg = useSelector(state => state.feedback.saveFeedbackSuccessMsg);
  const errorMsg = useSelector(state => state.feedback.saveFeedbackErrorMsg);

  const onClose = () => {
    setOpen(false);
  }

  const onConfirmeTip = () => {
    dispatch(FeedbackActions.confirmFeedbackTipDelivered(feedbackTipOrQuestion._id))
  }

  useEffect(() => {
    if(feedbackTipOrQuestion){
      setOpen(true);
      setType(feedbackTipOrQuestion.type);
      setText(feedbackTipOrQuestion.text);
      setSummary(feedbackTipOrQuestion.summary);
      setActions(feedbackTipOrQuestion.actions);
    }else{
      setOpen(false);
      setType(null);
      setText(null);
      setActions([]);
    }
  }, [feedbackTipOrQuestion])

  const inputChange = (event) => {
    let name = event.target.name
    let value = event.target.value
    if(name === 'answers'){
      if(event.target.checked)
        value = [...formData.answers, value]
      else
        value = formData.answers.filter(answer => answer !== value)
    }
    setFormData({ ...formData, [name]: value })
  }

  const saveFeedbackAnswer = () => {
    dispatch(FeedbackActions.saveFeedbackAnswer({...formData, _id: feedbackTipOrQuestion._id}))
  }

  return (
    <Dialog className="feedback-popup" open={open}>
      <DialogContent dividers>
        {(confirmFeedbackTipDeliveredLoading || saveFeedbackAnswerLoading) && (<Loading/>)}
        {errorMsg && (<Alert severity="error">{errorMsg}</Alert>)}
        <Typography variant="body1">{text}</Typography>
        <ul>
          {summary.map(item => (<li><Typography variant="caption">{item}</Typography></li>))}
        </ul>
        {type==='tip' && (
          <>
            <Typography variant="subtitle2">Em casos como esse recomendamos as seguintes ações para cada cenário:</Typography>
            <ul>
              {actions.map(action => (
                <li>
                  <Typography variant="caption">{action.title}</Typography>
                  <ul>
                    {action.actions.map(action => (<li><Typography variant="caption">{action}</Typography></li>))}
                  </ul>
                </li>
              ))}
            </ul>
          </>
        )}

        {type==='question' && (
          <>
            <Typography variant="subtitle2">Alguma das opções a seguir influenciou nesse resultado?</Typography>
            <FormGroup>
              {actions.map(action => (
                action.actions.map(action => (
                  <FormControlLabel
                    name="actions"
                    control={<Checkbox color="primary" name="answers" onChange={inputChange} value={action} />}
                    label={action}
                    />
                ))
              ))}
            </FormGroup>
            <br/>
            <Typography variant="subtitle2">Houve mais alguma ação realizada que vôce acha que foi importante para o resultado?</Typography>
            <TextField
              name="anotherAnswer"
              label="Descreva aqui"
              margin="normal"
              fullWidth
              multiline
              rows={2}
              maxRows={4}
              onChange={inputChange}
              value={formData.otherActions}
              error={formData.otherActionsHasError}
              helperText={formData.otherActionsErrorText} />
          </>
        )}
      </DialogContent>
      <DialogActions className="footer">
      {type==='tip' && (
        <Button 
          variant="contained" 
          size="small"
          color="primary"
          onClick={onConfirmeTip}>
          Ok
        </Button>
      )}
      
      {type==='question' && (
        // <Button 
        //   variant="contained" 
        //   size="small"
        //   onClick={onClose}>
        //   Fechar
        // </Button>
        // <Button 
        //   variant="contained" 
        //   size="small"
        //   onClick={askMeLater}>
        //   Responder depois
        // </Button>
        <Button 
          className="confirm-button"
          variant="contained"
          color="primary"
          onClick={saveFeedbackAnswer}
          size="small">
          Enviar
        </Button>
      )}
        
       
      </DialogActions>
    </Dialog>
  )
}

FeedbackTipOrQuestionPopup.defaultProps = {
  buttonSize: 'medium',
  colorSize: 'medium',
}

export default FeedbackTipOrQuestionPopup