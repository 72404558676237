import { createStore, compose, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import reducers from './ducks'
import sagas from './saga'

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();

const store = createStore(
    reducers,
    composeEnhancer(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(sagas)
export default store