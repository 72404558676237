import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getSubjects: ['payload'],
  getSubjectsSuccess: ['payload'],
  getSubjectsFailure: ['payload'],
  getSubjectsNoFiltered: ['payload'],
  getSubjectsNoFilteredSuccess: ['payload'],
  getSubjectsNoFilteredFailure: ['payload'],
  getAllSubjects: ['payload'],
  getAllSubjectsSuccess: ['payload'],
  getAllSubjectsFailure: ['payload'],
})

const INITIAL_STATE = {
  getSubjectsLoading: false,
  page: 0, 
  limit: 0, 
  list: [],
  total: 0,
  getSubjectsNoFilteredLoading: false,
  listNoFiltered: [],
  getAllSubjectsLoading: false,
  listAll: [],
  getAllSubjectsErrorMsg: null,
}

const getSubjects = (state = INITIAL_STATE, action) => ({...state, getSubjectsLoading: true})
const getSubjectsSuccess = (state = INITIAL_STATE, action) => ({
  ...state, 
  getSubjectsLoading: false, 
  page: action.payload.page, 
  limit: action.payload.limit,
  list: action.payload.list,
  total: action.payload.total,
})
const getSubjectsFailure = (state = INITIAL_STATE, action) => ({
  ...state, 
  getSubjectsLoading: false,
  page: INITIAL_STATE.page, 
  limit: INITIAL_STATE.limit,
  list: INITIAL_STATE.list,
  total: INITIAL_STATE.total,
})

const getSubjectsNoFiltered = (state = INITIAL_STATE, action) => ({...state, getSubjectsNoFilteredLoading: true})
const getSubjectsNoFilteredSuccess = (state = INITIAL_STATE, action) => ({
  ...state, 
  getSubjectsNoFilteredLoading: false,
  listNoFiltered: action.payload.list,
})
const getSubjectsNoFilteredFailure = (state = INITIAL_STATE, action) => ({
  ...state, 
  getSubjectsNoFilteredLoading: false,
  listNoFiltered: INITIAL_STATE.listNoFiltered,
})

const getAllSubjects = (state = INITIAL_STATE, action) => ({...state, getAllSubjectsLoading: true})
const getAllSubjectsSuccess = (state = INITIAL_STATE, action) => ({
  ...state, 
  getAllSubjectsLoading: false,
  listAll: action.payload.list,
})
const getAllSubjectsFailure = (state = INITIAL_STATE, action) => ({
  ...state, 
  getAllSubjectsLoading: false,
  listAll: INITIAL_STATE.listAll,
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_SUBJECTS]: getSubjects,
  [Types.GET_SUBJECTS_SUCCESS]: getSubjectsSuccess,
  [Types.GET_SUBJECTS_FAILURE]: getSubjectsFailure,

  [Types.GET_SUBJECTS_NO_FILTERED]: getSubjectsNoFiltered,
  [Types.GET_SUBJECTS_NO_FILTERED_SUCCESS]: getSubjectsNoFilteredSuccess,
  [Types.GET_SUBJECTS_NO_FILTERED_FAILURE]: getSubjectsNoFilteredFailure,

  [Types.GET_ALL_SUBJECTS]: getAllSubjects,
  [Types.GET_ALL_SUBJECTS_SUCCESS]: getAllSubjectsSuccess,
  [Types.GET_ALL_SUBJECTS_FAILURE]: getAllSubjectsFailure,
})