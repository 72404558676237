import React, { useMemo } from "react";
import { Grid } from "@material-ui/core";
import Suggestion from "../Suggestion";

import "./style.sass";

const Msg_box = React.forwardRef((props, ref) => {
  return useMemo(() => {
    return (
      <Grid container ref={ref} className={`messages ${props.origin}`}>
        <Grid className={` ${props.origin}`} item>
          {props.message}
        </Grid>
        <Suggestion {...props} />
      </Grid>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.message, Suggestion]);
});

export default Msg_box;
