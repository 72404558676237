import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { MuiThemeProvider } from '@material-ui/core/styles'

import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'

import { DialogTheme } from '../../Themes'
import { Creators as AppActions } from '../../store/ducks/app'

import './style.sass'

function Alert() {
  const dispatch = useDispatch()

  const show = useSelector(state => state.app.showAlert)
  const title = useSelector(state => state.app.alertTitle)
  const text = useSelector(state => state.app.alertText)
  const buttonText = useSelector(state => state.app.alertButtonText)
  
  const close = () => dispatch(AppActions.closeAlert());

  return (
    <MuiThemeProvider theme={DialogTheme}>
      <Dialog
        open={show}
        onClose={close}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText 
            dangerouslySetInnerHTML={{__html: text}}>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            {buttonText}
          </Button>
        </DialogActions>
      </Dialog>    
    </MuiThemeProvider>
  );
}

Alert.defaultProps = {
  // show: '',
  // title: '',
  // message: '',
  // buttonText: 'Ok',
  // closeAlert: () => {}
}

// const mapStateToProps = state => ({
//   show: state.app.showAlert,
//   title: state.app.alertTitle,
//   message: state.app.alertMessage,
//   buttonText: state.app.alertButtonText,
// })
// const mapDispatchToProps = dispatch => bindActionCreators({
//   closeAlert: AppActions.closeAlert,
// }, dispatch)

export default Alert;