import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Paper, Grid, Typography, Fab } from "@material-ui/core";

import IndicationBlock from "../../components/IndicationBlock";
import MetricBlock from "../../components/MetricBlock";
import Loading from "../../components/Loading";

import TutorsBlock from "../../containers/TutorsBlock";
import CoursesBlock from "../../containers/CoursesBlock";
import ClassesBlock from "../../containers/ClassesBlock";
import StudentsBlock from "../../containers/StudentsBlock";
import SubjectsBlock from "../../containers/SubjectsBlock";
import EvasionChartBlock from "../../containers/EvasionChartBlock";
import FiltersBlock from "../../containers/FiltersBlock";
import FeedbackTipOrQuestionPopup from "../../containers/FeedbackTipOrQuestionPopup";

import IndicationIcon from "@material-ui/icons/AssignmentInd";
import MetricsIcon from "@material-ui/icons/Timeline";

import OnlineTimeIcon from "@material-ui/icons/Schedule";
import PersistenceIcon from "@material-ui/icons/TransferWithinAStation";
import ContentIcon from "@material-ui/icons/AssignmentTurnedIn";
import PrecedenceIcon from "@material-ui/icons/Timer";
// import ChatIcon from '@material-ui/icons/Chat'
import ChatIcon from "../../components/ChatbotIcon";

import { Creators as IndicationActions } from "../../store/ducks/indicators";
import { Creators as MetricsActions } from "../../store/ducks/metrics";
import { Creators as AppActions } from "../../store/ducks/app";
import { Creators as ChatActions } from "../../store/ducks/chat";
import { Creators as FeedbackActions } from "../../store/ducks/feedback";
import Chat from "../../containers/Chat";

import "./style.sass";

const Data = (props) => {
  const [actualBatch, setActualBatch] = useState(false);

  const dispatch = useDispatch();

  const indicators = useSelector((state) => state.indicators.list);
  const metrics = useSelector((state) => state.metrics.list);
  const finished = useSelector((state) => state.app.finished);
  const authenticatedUser = useSelector(
    (state) => state.auth.authenticatedUser
  );

  const [selectedTab, setSelectedTab] = useState(
    finished ? "finished" : "in-progress"
  );

  const [selectedIndicator, setSelectedIndicator] = useState(-1);

  const selectedBatch = useSelector((state) => state.app.selectedBatch);
  const selectedTutors = useSelector((state) => state.app.selectedTutors);
  const selectedCourses = useSelector((state) => state.app.selectedCourses);
  const selectedClasses = useSelector((state) => state.app.selectedClasses);
  const selectedStudents = useSelector((state) => state.app.selectedStudents);
  const filters = useSelector((state) => state.app.customFilters);
  const openChat = useSelector((state) => state.chat.openChat);

  const getIndicatorsLoading = useSelector(
    (state) => state.indicators.getIndicatorsLoading
  );
  const getMetricsLoading = useSelector(
    (state) => state.metrics.getMetricsLoading
  );

  // const feedbackQuestion = useSelector(state => state.feedback.question);
  // const feedbackTipOrQuestion = useSelector(state => state.feedback.tipOrQuestion);

  useEffect(() => {
    if (selectedBatch) {
      dispatch(
        IndicationActions.getIndicators({
          courseIds: selectedCourses.map((courses) => courses.courseId),
          classIds: selectedClasses.map(
            (selectedClass) => selectedClass.classId
          ),
          tutorIds: selectedTutors.map((tutors) => tutors.tutorId),
          studentIds: selectedStudents.map((student) => student.studentId),
          batchId: selectedBatch,
          finished,
          // startDate,
          // endDate,
          filters,
        })
      );

      dispatch(
        MetricsActions.getMetrics({
          courseIds: selectedCourses.map((courses) => courses.courseId),
          classIds: selectedClasses.map(
            (selectedClass) => selectedClass.classId
          ),
          tutorIds: selectedTutors.map((tutors) => tutors.tutorId),
          studentIds: selectedStudents.map((student) => student.studentId),
          batchId: selectedBatch,
          finished,
          // startDate,
          // endDate,
          filters,
        })
      );
    }
  }, [
    // dispatch,
    selectedCourses,
    selectedTutors,
    selectedClasses,
    selectedStudents,
    selectedBatch,
    // startDate,
    // endDate,
    finished,
    filters,
  ]);

  useEffect(() => {
    setSelectedTab(finished ? "finished" : "in-progress");
  }, [finished]);

  useEffect(() => {
    if (authenticatedUser.feedbackFeature)
      dispatch(FeedbackActions.getFeedbackTipOrQuestion());
  }, [authenticatedUser]);

  const changeTab = (event, tabValue) => {
    setSelectedTab(tabValue);
    dispatch(AppActions.changeFinished(tabValue === "finished" ? true : false));
  };

  const onChangeTutors = (newValue) => {
    dispatch(AppActions.setSelectedTutors(newValue));
  };

  const onChangeCourses = (newValue) => {
    dispatch(AppActions.setSelectedCourses(newValue));
  };

  const onChangeClasses = (newValue) => {
    dispatch(AppActions.setSelectedClasses(newValue));
  };

  const onChangeStudents = (newValue) => {
    dispatch(AppActions.setSelectedStudents(newValue));
  };

  const setShow = (value) => {
    dispatch(ChatActions.setOpenChat(value));
  };

  return (
    <div className="data">
      <Chat
        show={openChat}
        setShow={setShow}
        anchorX={document.getElementById("fab-chat")?.offsetLeft}
        anchorY={document.getElementById("fab-chat")?.offsetTop}
      />
      <div>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <FiltersBlock />
            <TutorsBlock />
            <CoursesBlock />
            <ClassesBlock />
            {authenticatedUser.subjectModule && (
              <SubjectsBlock />
              // <Typography variant="h6">bloco de disciplinas aqui</Typography>
            )}
            <StudentsBlock />
          </Grid>
          <Grid item xs={7}>
            <EvasionChartBlock />
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Paper className="block indicators">
                  {getIndicatorsLoading && <Loading />}
                  <Typography variant="h6" className="title">
                    <IndicationIcon />
                    Indicadores
                  </Typography>
                  {indicators &&
                    indicators.map((indicator, key) => (
                      <IndicationBlock
                        key={key}
                        {...indicator}
                        studentsClickAction={() =>
                          onChangeStudents(indicator.students)
                        }
                        open={selectedIndicator === key}
                        onClick={() =>
                          selectedIndicator != key
                            ? setSelectedIndicator(key)
                            : setSelectedIndicator(-1)
                        }
                      />
                    ))}
                  {/* <IndicationBlock name="Abandono" percent={55}/> */}
                </Paper>
              </Grid>
              <Grid item xs={9}>
                <Paper className="block metrics">
                  {getMetricsLoading && <Loading />}
                  <Typography variant="h6" className="title">
                    <MetricsIcon />
                    Métricas (Média geral)
                  </Typography>

                  <Grid container spacing={1}>
                    <Grid item className="metric-block-wrapper" xs={6}>
                      <MetricBlock
                        icon={<OnlineTimeIcon />}
                        {...metrics[0]}
                        studentsClickAction={onChangeStudents}
                      />
                    </Grid>
                    <Grid item className="metric-block-wrapper" xs={6}>
                      <MetricBlock
                        icon={<PersistenceIcon />}
                        {...metrics[1]}
                        studentsClickAction={onChangeStudents}
                      />
                    </Grid>
                    <Grid item className="metric-block-wrapper" xs={6}>
                      <MetricBlock
                        icon={<ContentIcon />}
                        {...metrics[3]}
                        studentsClickAction={onChangeStudents}
                      />
                    </Grid>
                    <Grid item className="metric-block-wrapper" xs={6}>
                      <MetricBlock
                        icon={<PrecedenceIcon />}
                        {...metrics[2]}
                        studentsClickAction={onChangeStudents}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Fab
        id="fab-chat"
        className="fab-chat"
        onClick={() => setShow(!openChat)}
      >
        <ChatIcon />
      </Fab>
      <FeedbackTipOrQuestionPopup />
      {/* {feedbackQuestion && (<FeedbackPopup/>)} */}
    </div>
  );
};
export default Data;
