import React from 'react'
import { useTable, useSortBy, usePagination, useBlockLayout } from 'react-table'
import PropsTypes from 'prop-types'
import {
  Table as MuiTable,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TableContainer,
  TableFooter,
  TablePagination,
  Paper,
  Checkbox
} from '@material-ui/core'

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import EditIcon from '@material-ui/icons/Edit'
import "./style.sass"

function Table({
  columns,
  data,
  pageCount: controlledPageCount,
  pageIndex: controlledPageIndex,
  fetchData,
  isPagination,
  isFetching,
  isEdit,
  handleSelectEdit,
  ...rest
}) {
  // Use the state and functions returned from useTable to build your UI

  const [needUpdate, setNeedUpdate] = React.useState(false);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageCount,
    gotoPage,
    setPageSize,
    canNextPage,
    canPreviousPage,
    state: { pageIndex, pageSize }
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: controlledPageIndex },
    manualPagination: true,
    pageCount: controlledPageCount
  },
    useSortBy,
    usePagination,
    useBlockLayout
    // hooks => {
    //   if (isPagination)
    //     hooks.visibleColumns.push(columns => [
    //       // Let's make a column for selection
    //       {
    //         id: 'selection',
    //         // The header can use the table's getToggleAllRowsSelectedProps method
    //         // to render a checkbox
    //         Header: ({ getToggleAllRowsSelectedProps }) => (
    //           <div>
    //             <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
    //           </div>
    //         ),
    //         // The cell can use the individual row's getToggleRowSelectedProps method
    //         // to the render a checkbox
    //         Cell: ({ row }) => (
    //           <div>
    //             <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //           </div>
    //         ),
    //       },
    //       ...columns,
    //     ]);
    // }
  );

  React.useEffect(() => {
    if (isPagination && !isFetching && needUpdate){
      fetchData({ pageIndex, pageSize })
      setNeedUpdate(false)
    }
  }, [pageIndex, pageSize]) 

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value))
    setNeedUpdate(true)
  }

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage)
    setNeedUpdate(true)
  }


  return (
    <>
      <TableContainer component={Paper}>
        <MuiTable {...getTableProps()} {...rest}>
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow className="table-header" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  // <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}> # SE for preciso ordenação na tabela
                  <TableCell className="table-header-cell" {...column.getHeaderProps()}>
                    {column.render('Header')}
                    {column.isSorted ?
                      column.isSortedDesc ?
                        <ArrowDownwardIcon />
                        :
                        <ArrowUpwardIcon />
                      : ''
                    }
                  </TableCell>
                ))}
                {isEdit && (<TableCell></TableCell>)}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <TableRow {...row.getRowProps()} hover>
                  {row.cells.map(cell => {
                    return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                  })}
                  {isEdit && (
                    <TableCell ><EditIcon style={{ cursor: 'pointer' }} onClick={() => handleSelectEdit(row.original)} /></TableCell>
                  )}
                </TableRow>
              )
            })}

          </TableBody>
          {isPagination && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPage={pageSize}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  count={pageSize * pageCount}
                  onChangePage={handleChangePage}
                  page={pageIndex}
                  backIconButtonProps={
                    { disabled: isFetching || !canPreviousPage }
                  }
                  nextIconButtonProps={
                    { disabled: isFetching || !canNextPage }
                  }
                  SelectProps={
                    {
                      inputProps:
                        { disabled: isFetching }
                    }
                  }
                />
              </TableRow>
            </TableFooter>
          )}

        </MuiTable>
      </TableContainer>
      {/* <pre>
        <code>
          {JSON.stringify(
            {
              'selectedFlatRows[].original': selectedFlatRows.map(
                d => d.original
              ),
              'context': tableContext?.selectedIds || []
            },
            null,
            2
          )}
        </code>
      </pre> */}
    </>
  )
}

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, checked, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Checkbox
          color="primary"
          type="checkbox"
          ref={resolvedRef}
          checked={checked}
          indeterminate={indeterminate}
          inputProps={{ ...rest }}
        />
      </>
    )
  }
)

Table.propTypes = {
  columns: PropsTypes.arrayOf(PropsTypes.object).isRequired,
  data: PropsTypes.arrayOf(PropsTypes.object).isRequired
}

export default Table