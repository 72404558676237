import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Creators as BatchLogActions } from "../../store/ducks/batchlog";
import moment from 'moment';

import './style.sass';

import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper
} from '@material-ui/core'

const BatchLog = () => {
  const dispatch = useDispatch();
  const rows = useSelector(state => state.batchlog.rows);

  React.useEffect(() => {
    dispatch(BatchLogActions.getBatchLogs());
  }, [dispatch])


  return (
    <TableContainer id={"batchlogs-table-container"} component={Paper}>
      <Table>
        <TableHead className={"table-header"}>
          <TableRow>
            <TableCell align="center">Nome Remessa</TableCell>
            <TableCell align="center">IP de envio</TableCell>
            <TableCell align="center">Iniciou</TableCell>
            <TableCell align="center">Concluiu</TableCell>
            <TableCell align="center">Resposta</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row._id}>
              <TableCell component="th" scope="row">
                {row.batch}
              </TableCell>
              <TableCell align="center">{row.origin}</TableCell>
              <TableCell align="center">{row.startAt && moment(row.startAt).format("DD/MM/YYYY - HH:mm:ss")}</TableCell>
              <TableCell align="center">{row.endAt && moment(row.endAt).format("DD/MM/YYYY - HH:mm:ss")}</TableCell>
              <TableCell align="center">{row.response}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BatchLog;