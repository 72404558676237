import React, {useState, useEffect} from 'react'
import { useDispatch } from 'react-redux'

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { Creators as AlertActions } from "../../store/ducks/alert"

import './style.sass'

const AlertActionAccordionItem = (props) => {

  const dispatch = useDispatch()

  const [itemExpanded, setItemExpanded] = useState(null)
  const [actions, setActions] = useState([])
  
  const accordionHandle = (id) => {
    setItemExpanded(itemExpanded === id ? null : id);
  }
  
  const actionHandle = (index) => {
    actions[index].done = !actions[index].done    
    setActions([...actions])
    dispatch(AlertActions.changeAlertActions({id: props.data._id, actions}))
  }

  useEffect(() => {
    setActions(props.data.actions)
  }, [props.data])

  return (
    <Accordion 
      className="alert-action-accordion-item"
      expanded={itemExpanded === props.data._id} 
      onChange={() => accordionHandle(props.data._id)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Grid container>
          <Grid item xs="6">
            <Typography variant="subtitle2" className="accordion-title">
              {props.data.alertTitle}
              <Chip
                className="done"
                size="small" 
                label={`${props.data.actions.filter(action => action.done === true).length} Feito(s)`}
                color="primary"
                variant="outlined"
                />

              <Chip
                className="undone-chip"
                size="small" 
                label={`${props.data.actions.filter(action => action.done !== true).length} A fazer`}
                color="primary"
                variant="outlined"
                />
            </Typography>
          </Grid>
          <Grid item xs="6">
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className="alert-details">
        <FormGroup>
          {actions.map((action, index) => (
            <FormControlLabel
              control={<Checkbox checked={action.done} onChange={() => actionHandle(index)} />}
              label={action.description}
            />
          ))}
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  )
}

AlertActionAccordionItem.defaultProps = {
  data: {}
}

export default AlertActionAccordionItem