import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { SnackbarProvider, useSnackbar } from "notistack";
import { ThemeProvider } from "@material-ui/core/styles";

import { AppTheme } from "./Themes";
import "./App.sass";

import Login from "./screens/Login";
import Data from "./screens/Data";
import Users from "./screens/Users";
import Profile from "./screens/Profile";
import Header from "./containers/Header";
import Alert from "./containers/Alert";
import SnackbarGlobal from "./containers/SnackbarGlobal";
// import Confirm from './containers/Confirm'
import AlertsScreen from "./screens/Alerts";
import BatchLogScreen from "./screens/BatchLog";
import ConsultantScreen from "./screens/Consultant";
import ClientScreen from "./screens/Admin/Clients";
import ClientInfoScreen from "./screens/Admin/ClientInfo";
import ReportScreen from "./screens/Report";

import { Creators as AppActions } from "./store/ducks/app";
import { Creators as AuthActions } from "./store/ducks/auth";

function App() {
  const dispatch = useDispatch();

  const authState = useSelector((state) => state.auth);

  useEffect(() => {
    if (authState.isAuthenticated === true) {
      dispatch(AuthActions.getAuthenticatedUser());
      dispatch(AppActions.getClassification());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={AppTheme}>
      <div className="app-wrapper">
        {authState.isAuthenticated === true && (
          // <SnackbarProvider maxSnack={5}>
          <Router>
            <Header />
            <div className="content-wrapper">
              <div className="content">
                <Switch>
                  <Route exact={true} path="/" component={Data} />
                  <Route exact={true} path="/data" component={Data} />
                  <Route exact={true} path="/users" component={Users} />
                  <Route exact={true} path="/profile" component={Profile} />
                  <Route exact={true} path="/alerts" component={AlertsScreen} />
                  <Route
                    exact={true}
                    path="/batchlog"
                    component={BatchLogScreen}
                  />
                  {authState.authenticatedUser.adminModule && (
                    <>
                      <Route
                        exact={true}
                        path="/clients"
                        component={ClientScreen}
                      />
                      <Route
                        exact={true}
                        path="/clients/:id"
                        component={ClientInfoScreen}
                      />
                    </>
                  )}
                  {true === false && (
                    <Route
                      exact={true}
                      path="/consultant"
                      component={ConsultantScreen}
                    />
                  )}
                  {authState.authenticatedUser.reportModule && (
                    <Route
                      exact={true}
                      path="/reports"
                      component={ReportScreen}
                    />
                  )}
                  <Redirect from="*" to="/" />
                </Switch>
              </div>
            </div>
          </Router>
          // </SnackbarProvider>
        )}
        {authState.isAuthenticated === false && (
          <Router>
            <Switch>
              <Route exact={true} path="/" component={Login} />
              <Redirect from="*" to="/" />
            </Switch>
          </Router>
        )}
      </div>
      <Alert />
      <SnackbarGlobal />
      {/* 
      <Confirm/> */}
    </ThemeProvider>
  );
}

export default App;
