import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Util from "../../../Utils";
import Api from "../../../store/Api";

import { Creators as UserActions } from "../../../store/ducks/user";

import "./style.sass";

import { Grid, Typography, Switch, TextField, Button } from "@material-ui/core";
import moment from "moment";

const ClientInfo = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [client, setClient] = React.useState({});
  const [featureFlags, setFeatureFlags] = React.useState([]);
  const [isNew, setIsNew] = React.useState(false);

  const { id } = useParams();

  const getFeatureFlags = async () => {
    const ff = await Api.getFeatureFlags();
    setFeatureFlags(ff);
  };

  const getClient = async () => {
    try {
      const company = await Api.getCompanyById(id);
      setClient(company);
    } catch (err) {
      history.goBack();
    }
  };

  React.useEffect(() => {
    // const client = clientsList.find((c) => c._id === id);
    // if(!client) history.goBack()
    if (id !== "new") getClient();
    else setIsNew(true);
    getFeatureFlags();
  }, []);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const formData = new FormData(event.target);
      let formProps = Object.fromEntries(formData);

      if (formProps.phone) formProps.phone = Util.digitMask(formProps.phone);

      formProps.featureFlags = [];
      Object.keys(formProps).forEach((k) =>
        k.includes("featureFlags-") && formProps[k]
          ? formProps.featureFlags.push({
              id: k.split("Flags-")[1],
              date: formProps[k],
            })
          : ""
      );
      if (isNew) {
        const company = await Api.createCompany(formProps);
        const dataToSend = {
          name: formProps.sponsor,
          email: formProps.email,
          whatsapp: Util.digitMask(formProps.phone),
          enabled: true,
          company: company.company._id,
        };
        dispatch(UserActions.newUser(dataToSend));
        history.goBack();
      } else {
        await Api.updateCompany({ ...formProps, id });
        history.goBack();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const phoneHandle = (event) => {
    event.target.value = Util.phoneMask(event.target.value);
  };

  /**
   * campo para gerar os switch
   * [
   * {
   *  title: "titulo do campo"
   *  name: "Nome do campo",
   *  value: true / false
   * }
   * ]
   */

  // Enviar id para trazer as informações do cliente especifico

  // Montar estrutura de switchs para cada módulo

  // no back fazer a associação no momento de cadastrar um novo usuário indicar à que empresa ele pertence

  return (
    <form onSubmit={handleSubmit}>
      {(client?.name || isNew) && ( // TROCAR POR SKELETON
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant={"h4"}>Informações da empresa</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name={"name"}
              fullWidth
              label={"Nome da empresa"}
              defaultValue={client.name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name={"sponsor"}
              fullWidth
              label={"Responsável"}
              defaultValue={client.sponsor}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name={"cnpj"}
              fullWidth
              label={"CNPJ"}
              defaultValue={client.cnpj}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name={"email"}
              fullWidth
              label={"Email"}
              defaultValue={client.email}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name={"phone"}
              fullWidth
              label={"Telefone"}
              onChange={phoneHandle}
              defaultValue={Util.phoneMask(client.phone)}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              name={"database"}
              fullWidth
              label={"Endereço Banco"}
              defaultValue={client.database}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              type={"date"}
              name={"endAt"}
              label={"Termino do contrato"}
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={
                isNew
                  ? ""
                  : client.endAt
                  ? moment(client.endAt).add(3, "h").format("YYYY-MM-DD")
                  : ""
              }
            />
          </Grid>

          {featureFlags.map((c, i) => (
            <Grid key={i} container item xs={4}>
              <Grid item xs={12}>
                <Typography>{c.name}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type={"date"}
                  name={`featureFlags-${c._id}`}
                  defaultValue={
                    isNew
                      ? ""
                      : client.featureFlags.some((cf) => cf.id === c._id)
                      ? moment(
                          client.featureFlags.find((cf) => cf.id === c._id)
                            ?.date
                        )
                          .add(3, "h")
                          .format("YYYY-MM-DD")
                      : ""
                  }
                />
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button variant={"contained"} type={"submit"}>
              Enviar
            </Button>
          </Grid>
        </Grid>
      )}
    </form>
  );
};

export default ClientInfo;
