import React, { useState, useEffect } from 'react'

import {
  Avatar,
  ButtonBase,
  Card,
  Typography,
  Chip,
  Tooltip,
} from '@material-ui/core'

import Utils from '../../Utils'

import './style.sass'

const TutorCard = (props) => {
  
  const [openedInfo, setOpenedInfo] = useState(false)
  const [evasorData, setEvasorData] = useState({})

  useEffect(() => {
    if(props.data.evarsorConcludents){
      setEvasorData(props.data.evarsorConcludents.find(item => item.title === 'Evasores'))
    }
  }, [props.data])
  
  return (
    <Card className={`tutor-card ${(openedInfo?' open-info':'')} ${props.color}-color`}>
      <div className="picture-numbers-wrapper">
        <Avatar className="picture" size="" src={props.data.picture || process.env.PUBLIC_URL + '/default_perfil.svg'} />
        <div className="numbers">
          {props.data.classifications.map((classificationItem, key) => (
            <Tooltip key={key} title={classificationItem.title} placement="top">
              <Chip 
                size="small" 
                className="number" 
                label={`${classificationItem.count} (${((classificationItem.count/props.data.studentCount)*100).toFixed(2)}%)`} 
                style={{backgroundColor: classificationItem.color}}/>
            </Tooltip>
          ))}
          <Typography variant="button">{evasorData.count}{evasorData.count===1?' Evasor':' Evasores'}</Typography>
        </div>
      </div>
      <Typography className="name" gutterBottom variant="subtitle2">{Utils.capitalize(props.data.name)}</Typography>
      <ButtonBase className="see-more"
        onClick={props.seeMoreAction}>
        <Typography variant="button">Ver mais</Typography>
      </ButtonBase>
    </Card>
  )
}

TutorCard.defaultProps = {
  data: {},
  seeMoreAction: () => {}
}

export default TutorCard