import { put, call, takeLatest, all } from 'redux-saga/effects'
import Api from '../Api'
import { Types } from '../ducks/notification'

function* getNotifications(action) {
  try {
    const response = yield call(Api.getNotifications, action.payload)
    yield put({type: Types.GET_NOTIFICATIONS_SUCCESS, payload: response})
  } catch (error) {
    yield put({type: Types.GET_NOTIFICATIONS_FAILURE, payload: error.message})
  }
}

function* readNotification(action) {
  try {
    const response = yield call(Api.readNotification, action.payload)
    yield put({type: Types.READ_NOTIFICATION_SUCCESS, payload: response})
  } catch (error) {
    yield put({type: Types.READ_NOTIFICATION_FAILURE, payload: error.message})
  }
}

function* deleteNotification(action) {
  try {
    const response = yield call(Api.deleteNotification, action.payload)
    yield put({type: Types.DELETE_NOTIFICATION_SUCCESS, payload: response})
    yield put({type: Types.GET_NOTIFICATIONS})
  } catch (error) {
    yield put({type: Types.DELETE_NOTIFICATION_FAILURE, payload: error.message})
  }
}

export default function* () {
  yield all([
    takeLatest(Types.GET_NOTIFICATIONS, getNotifications),
    takeLatest(Types.READ_NOTIFICATION, readNotification),
    takeLatest(Types.DELETE_NOTIFICATION, deleteNotification),
  ])
}
