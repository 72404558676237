import { put, call, takeLatest, all } from 'redux-saga/effects'
import Api from '../Api'
import { Types } from '../ducks/tutor'

function* getTutors(action) {
  try {
    const response = yield call(Api.getTutors, action.payload)
    yield put({type: Types.GET_TUTORS_SUCCESS, payload: response})
  } catch (error) {
    yield put({type: Types.GET_TUTORS_FAILURE, payload: error.message})
  }
}

function* getTutorsNoFiltered(action) {
  try {
    const response = yield call(Api.getAllTutors, action.payload)
    yield put({type: Types.GET_TUTORS_NO_FILTERED_SUCCESS, payload: response})
  } catch (error) {
    yield put({type: Types.GET_TUTORS_NO_FILTERED_FAILURE, errorMessage: error.message})
  }
}

function* getAllTutors(action) {
  try {
    const response = yield call(Api.getAllTutors, action.payload)
    yield put({type: Types.GET_ALL_TUTORS_SUCCESS, payload: response})
  } catch (error) {
    yield put({type: Types.GET_ALL_TUTORS_FAILURE, errorMessage: error.message})
  }
}

export default function* () {
  yield all([
    takeLatest(Types.GET_TUTORS, getTutors),
    takeLatest(Types.GET_TUTORS_NO_FILTERED, getTutorsNoFiltered),
    takeLatest(Types.GET_ALL_TUTORS, getAllTutors),
  ])
}