import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getCourses: ['payload'],
  getCoursesSuccess: ['payload'],
  getCoursesFailure: ['payload'],
  getCoursesNoFiltered: ['payload'],
  getCoursesNoFilteredSuccess: ['payload'],
  getCoursesNoFilteredFailure: ['payload'],
  getCoursesEvasion: ['payload'],
  getCoursesEvasionSuccess: ['payload'],
  getCoursesEvasionFailure: ['payload'],
  getAllCourses: ['payload'],
  getAllCoursesSuccess: ['payload'],
  getAllCoursesFailure: ['payload'],
})

const INITIAL_STATE = {
  getCoursesLoading: false,
  page: 0, 
  limit: 0, 
  total: 0, 
  list: [],
  getCoursesNoFilteredLoading: false,
  listNoFiltered: [],
  getAllCoursesLoading: false,
  listAll: [],
  getAllCoursesErrorMsg: null,
}

const getCourses = (state = INITIAL_STATE, action) => ({...state, getCoursesLoading: true})
const getCoursesSuccess = (state = INITIAL_STATE, action) => ({
  ...state, 
  getCoursesLoading: false, 
  page: action.payload.page, 
  limit: action.payload.limit,
  list: action.payload.list,
  total: action.payload.total,
})
const getCoursesFailure = (state = INITIAL_STATE, action) => ({
  ...state, 
  getCoursesLoading: false,
  page: INITIAL_STATE.page, 
  limit: INITIAL_STATE.limit,
  list: INITIAL_STATE.list,
  total: INITIAL_STATE.total,
})

const getCoursesNoFiltered = (state = INITIAL_STATE, action) => ({...state, getCoursesNoFilteredLoading: true})
const getCoursesNoFilteredSuccess = (state = INITIAL_STATE, action) => ({
  ...state, 
  getCoursesNoFilteredLoading: false,
  listNoFiltered: action.payload.list,
})
const getCoursesNoFilteredFailure = (state = INITIAL_STATE, action) => ({
  ...state, 
  getCoursesNoFilteredLoading: false,
  listNoFiltered: INITIAL_STATE.listNoFiltered,
})

const getAllCourses = (state = INITIAL_STATE, action) => ({...state, getAllCoursesLoading: true})
const getAllCoursesSuccess = (state = INITIAL_STATE, action) => ({
  ...state, 
  getAllCoursesLoading: false,
  listAll: action.payload.list,
})
const getAllCoursesFailure = (state = INITIAL_STATE, action) => ({
  ...state, 
  getAllCoursesLoading: false,
  listAll: INITIAL_STATE.listAll,
})


export default createReducer(INITIAL_STATE, {
  [Types.GET_COURSES]: getCourses,
  [Types.GET_COURSES_SUCCESS]: getCoursesSuccess,
  [Types.GET_COURSES_FAILURE]: getCoursesFailure,
  
  [Types.GET_COURSES_NO_FILTERED]: getCoursesNoFiltered,
  [Types.GET_COURSES_NO_FILTERED_SUCCESS]: getCoursesNoFilteredSuccess,
  [Types.GET_COURSES_NO_FILTERED_FAILURE]: getCoursesNoFilteredFailure,

  [Types.GET_ALL_COURSES]: getAllCourses,
  [Types.GET_ALL_COURSES_SUCCESS]: getAllCoursesSuccess,
  [Types.GET_ALL_COURSES_FAILURE]: getAllCoursesFailure,
})