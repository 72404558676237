import { put, call, takeLatest, all } from 'redux-saga/effects'
import Api from '../../Api'
import { Types } from '../../ducks/admin/clients'
import { del } from 'request-promise'

function* getAlertActions(action) {
  try {
    const response = yield call(Api.getClients)
    yield put({type: Types.GET_CLIENTS_SUCCESS, payload: response})
  } catch (error) {
    yield put({type: Types.GET_CLIENTS_FAILURE, payload: error.message})
  }
}

export default function* () {
  yield all([
    takeLatest(Types.GET_CLIENTS, getAlertActions),
  ])
}
