import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import FilterPopup from '../../components/FilterPopup'

import { Creators as AppActions } from '../../store/ducks/app'
import { Creators as ClassActions } from '../../store/ducks/class'

import './style.sass'

const ClassFilterPopup = (props) => {

  const dispatch = useDispatch()

  const finished = useSelector(state => state.app.finished)
  const startDate = useSelector(state => state.app.startDate)
  const endDate = useSelector(state => state.app.endDate)

  const loading = useSelector(state => state.class.getClassesNoFilteredLoading)
  const classes = useSelector(state => state.class.listNoFiltered)
  const selectedClasses = useSelector(state => state.app.selectedClasses)
  
  const onSelectClasses = (classes) => {
    dispatch(AppActions.setSelectedClasses(classes))
  }

  const onOpen = () => {
    if(!classes.length){
      dispatch(ClassActions.getClassesNoFiltered({
        limit: 1000, 
        finished,
        startDate, 
        endDate,
      }))
    }
  }

  return (
    <div className="class-filter-popup">
      <FilterPopup
        label="Turmas"
        {...props}
        options={classes}
        selectedOptions={selectedClasses}
        loading={loading}
        onOpen={onOpen}
        onConfirm={onSelectClasses}
      />
    </div>
  )
}

ClassFilterPopup.defaultProps = {
  buttonSize: 'medium',
  colorSize: 'medium'
}

export default ClassFilterPopup