import { put, call, takeLatest, all } from 'redux-saga/effects'
import { select } from 'redux-saga/effects'
import Api from '../Api'
import { Types } from '../ducks/user'
import { Types as AuthTypes } from '../ducks/auth'
import { Types as AppTypes } from '../ducks/app'

function* reloadUsers() {
  const getUserState = (state) => state.user
  const userState = yield select(getUserState)

  let getMenagerUserPayload = {
    search: userState.search,
    page: userState.menagerPage,
    limit: userState.menagerLimit,
    filter: userState.menagerFilter,
  }
  let getTutorUserPayload = {
    search: userState.search,
    page: userState.tutorPage,
    limit: userState.tutorLimit,
    filter: userState.tutorFilter,
  }
  
  yield put({type: Types.GET_MENAGER_USERS, payload: getMenagerUserPayload})
  yield put({type: Types.GET_TUTOR_USERS, payload: getTutorUserPayload})

}

function* getMenagerUsers(action) {
  try {
    action.payload.role = 'menager'
    const response = yield call(Api.getUsers, action.payload)
    yield put({type: Types.GET_MENAGER_USERS_SUCCESS, payload: response})
  } catch (error) {
    yield put({type: Types.GET_MENAGER_USERS_FAILURE, payload: error.message})
  }
}

function* getTutorUsers(action) {
  try {
    action.payload.role = 'tutor'
    const response = yield call(Api.getUsers, action.payload)
    yield put({type: Types.GET_TUTOR_USERS_SUCCESS, payload: response})
  } catch (error) {
    yield put({type: Types.GET_TUTOR_USERS_FAILURE, payload: error.message})
  }
}

function* getUsersNoAccess(action) {
  try {
    action.payload = {filter: 'no-access', limit: 10000}
    const response = yield call(Api.getUsers, action.payload)
    yield put({type: Types.GET_USERS_NO_ACCESS_SUCCESS, payload: response})
  } catch (error) {
    yield put({type: Types.GET_USERS_NO_ACCESS_FAILURE, payload: error.message})
  }
}

function* enableUser(action) {
  try {
    let payload = {id: action.payload, data: {enabled: true}}
    const response = yield call(Api.updateUser, payload)
    yield put({type: Types.ENABLE_USER_SUCCESS, payload: response})
    yield reloadUsers()
    yield put({type: AppTypes.SHOW_ALERT, payload: {
        title: 'Acesso do usuário', 
        text: 'Acesso do usuário habilitado com sucesso'
      }
    })
  } catch (error) {
    yield put({type: Types.ENABLE_USER_FAILURE, payload: error.message})
  }
}

function* disableUser(action) {
  try {
    let payload = {id: action.payload, data: {enabled: false}}
    const response = yield call(Api.updateUser, payload)
    yield put({type: Types.DISABLE_USER_SUCCESS, payload: response})
    yield reloadUsers()
    yield put({type: AppTypes.SHOW_ALERT, payload: {
      title: 'Acesso do usuário', 
      text: 'Acesso do usuário desabilitado com sucesso'
    }
  })
  } catch (error) {
    yield put({type: Types.DISABLE_USER_FAILURE, payload: error.message})
  }
}

function* changeUserRoleToMenager(action) {
  try {
    let payload = {id: action.payload, data: {type: 'menager'}};
    const response = yield call(Api.updateUser, payload)
    yield put({type: Types.CHANGE_USER_ROLE_TO_MENAGER_SUCCESS, payload: response})
    yield reloadUsers()
  } catch (error) {
    yield put({type: Types.CHANGE_USER_ROLE_TO_MENAGER_FAILURE, payload: error.message})
  }
}

function* changeUserRoleToTutor(action) {
  try {
    let payload = {id: action.payload, data: {type: 'tutor'}};
    const response = yield call(Api.updateUser, payload)
    yield put({type: Types.CHANGE_USER_ROLE_TO_TUTOR_SUCCESS, payload: response})
    yield reloadUsers()
  } catch (error) {
    yield put({type: Types.CHANGE_USER_ROLE_TO_TUTOR_FAILURE, payload: error.message})
  }
}

function* deleteUser(action) {
  try {
    const response = yield call(Api.deleteUser, action.payload)
    yield put({type: Types.DELETE_USER_SUCCESS, payload: response})
    yield put({type: AppTypes.SHOW_ALERT, payload: {
                                              title: 'Exclusão do usuário', 
                                              text: 'Usuário excluído com sucesso'
                                            }
                                          })
    yield reloadUsers()
  } catch (error) {
    yield put({type: Types.DELETE_USER_FAILURE, payload: error.message})
  }
}

function* newUser(action) {
  try {
    action.payload.type = 'menager'
    const response = yield call(Api.newUser, action.payload)
    yield put({type: Types.NEW_USER_SUCCESS, payload: response})
    yield reloadUsers()
    yield put({type: AppTypes.SHOW_ALERT, payload: {
      title: 'Cadastrar gestor', 
      text: response
    }
  })
  } catch (error) {
    yield put({type: Types.NEW_USER_FAILURE, payload: error.message})
  }
}

function* sendAccess(action) {
  try {
    const response = yield call(Api.sendAccess, action.payload)
    yield put({type: Types.SEND_ACCESS_SUCCESS, payload: response})
    yield put({type: AppTypes.SHOW_ALERT, payload: {
                  title: 'Acesso do usuário', 
                  text: 'Novos acessos enviados com sucesso'
                }
              })
    yield reloadUsers()
  } catch (error) {
    yield put({type: Types.SEND_ACCESS_FAILURE, payload: error.message})
  }
}

function* saveMyData(action) {
  try {
    const response = yield call(Api.updateUser, action.payload)
    yield put({type: Types.SAVE_MY_DATA_SUCCESS, payload: response})
    yield put({type: AuthTypes.GET_AUTHENTICATED_USER})
    yield reloadUsers()
  } catch (error) {
    yield put({type: Types.SAVE_MY_DATA_FAILURE, payload: error.message})
  }
}

function* changePassword(action) {
  try {
    const response = yield call(Api.updateUser, action.payload)
    yield put({type: Types.CHANGE_PASSWORD_SUCCESS, payload: response})
    yield reloadUsers()
  } catch (error) {
    yield put({type: Types.CHANGE_PASSWORD_FAILURE, payload: error.message})
  }
}

function* changeProfilePicture(action) {
  try {
    const response = yield call(Api.changeProfilePicture, action.payload)
    yield put({type: Types.CHANGE_PROFILE_PICTURE_SUCCESS, payload: response})
    yield put({type: AuthTypes.GET_AUTHENTICATED_USER})
    yield reloadUsers()
  } catch (error) {
    yield put({type: Types.CHANGE_PROFILE_PICTURE_FAILURE, payload: error.message})
  }
}

export default function* () {
  yield all([
    takeLatest(Types.GET_MENAGER_USERS, getMenagerUsers),
    takeLatest(Types.GET_TUTOR_USERS, getTutorUsers),
    takeLatest(Types.GET_USERS_NO_ACCESS, getUsersNoAccess),
    takeLatest(Types.ENABLE_USER, enableUser),
    takeLatest(Types.DISABLE_USER, disableUser),
    takeLatest(Types.CHANGE_USER_ROLE_TO_MENAGER, changeUserRoleToMenager),
    takeLatest(Types.CHANGE_USER_ROLE_TO_TUTOR, changeUserRoleToTutor),
    takeLatest(Types.DELETE_USER, deleteUser),
    takeLatest(Types.NEW_USER, newUser),
    takeLatest(Types.SEND_ACCESS, sendAccess),    
    takeLatest(Types.SAVE_MY_DATA, saveMyData),
    takeLatest(Types.CHANGE_PASSWORD, changePassword),
    takeLatest(Types.CHANGE_PROFILE_PICTURE, changeProfilePicture),

  ])
}