import React, { useRef } from 'react';


import './style.sass'


const Slider = (props) => {
    
    let sliderRef = useRef(null)

    // const perPage = props.perPage || null;
    // const [paginationButtons, setPaginationButtons] = useState([])

    // useEffect(() => {
    //     setActualPage(1)
    //     paginationUpdate()
    //     window.addEventListener('resize', paginationUpdate, false)
    // }, [props.children])

    // const goToPage = (page) => {
    //     if(page > totalPages){
    //         // props.onChangePage({page, totalPages})
    //     }else{
    //         setActualPage(page)
    //         // props.onChangePage({page, totalPages: totalPages})
    //     }
    // }

    // const previousPage = () => {
    //     if(actualPage !== 1)
    //     goToPage((actualPage - 1))
    // }
    
    // const nextPage = () => {
    //     if(actualPage !== totalPages)
    //         goToPage((actualPage + 1))
    // }
    
    // const paginationUpdate = () => {
    //     if(!sliderRef.current) return
    //     let mask = sliderRef.current.getElementsByClassName('mask')[0]
    //     let content = sliderRef.current.getElementsByClassName('content')[0]

    //     let maskWidth = mask.offsetWidth
    //     // let inPageCount = Math.floor(maskWidth / perPage);
    //     // let padding = 0;
    //     // if(perPage){
    //     //     let spaceWidth = (mask.offsetWidth / perPage );
    //     //     let itemWidth = content.children[0].children[0].offsetWidth;
    //     //     padding = (spaceWidth - itemWidth);
    //     //     console.log('55 >>', mask.offsetWidth, content.children[0].children[0].offsetWidth, (spaceWidth - itemWidth) / 2)
    //     // }
    //     let contentWidth = 0
        
    //     setTimeout(() => {
    //         // for (let item of content.children) {
    //         //     if(item.className === "evasion-container") {
    //         //         // item.style.width = mask.offsetWidth +'px';
    //         //         contentWidth += mask.offsetWidth
    //         //     }
    //         //     // else if(perPage) {
    //         //     //     console.log('item.offsetWidth', item.offsetWidth, maskWidth)
    //         //     //     contentWidth = Math.ceil(content.children.length / perPage) * maskWidth;
    //         //     //     break;
    //         //     // }
    //         //     else {
    //         //         let perPage = Math.floor(maskWidth/item.offsetWidth)
    //         //         // contentWidth += item.offsetWidth
    //         //         contentWidth = Math.ceil(content.children.length / perPage) * maskWidth
    //         //     }
    //         // }

    //         for (let item of content.children) {
    //             if(item.className == "evasion-container") 
    //                 contentWidth += mask.offsetWidth
    //             else{
    //                 let perPage = Math.floor(maskWidth/item.offsetWidth)
    //                 contentWidth = Math.ceil(content.children.length / perPage) * maskWidth
    //             }

    //         }

    //         setActualPage(1)
    //         let totalPages = Math.ceil(contentWidth/mask.offsetWidth)
    //         // let paginationButtons = []
    //         for (let i=1; i <= totalPages; i++) {
    //             let active = false
    //             if(i === actualPage)
    //                 active = true
                    
    //             // paginationButtons.push(<button type="button" className={`btn ${(active?'active':'')}`} ></button>)
    //         }

    //         setTotalPages(Math.ceil(contentWidth/mask.offsetWidth))
    //         setMaskWidth(mask.offsetWidth)
    //         setContentWidth(contentWidth)
    //         // setPaginationButtons(paginationButtons)
    //     }, 1500)
    // }

    // const onResize = () => {
    //     setShowNavigationButtons(contentRef.current.offsetWidth > maskRef.current.offsetWidth)
    // }   

    return (
        <div ref={sliderRef} className={`slider ${props.className}`}>
            {/* <Fab className="prev" size="small" onClick={previousPage}>
                <PrevIcon/>
            </Fab> */}
            <div className="mask">
                <div 
                    className="content" 
                    // style={{
                    //     width: contentWidth,
                    //     marginLeft: -(maskWidth * (actualPage-1))
                    //     }}
                        >
                    {props.children}
                </div>
            </div>
            {/* <Fab className="next" size="small" onClick={nextPage}>
                <NextIcon/>
            </Fab> */}
            {/* <div className="pagination">
                {[...Array(totalPages)].map((item, key) => (
                    <button 
                        type="button" 
                        className={`btn ${((actualPage === (key+1))?'active':'')}`} 
                        onClick={() => {goToPage(key+1)}}
                        key={key} />
                ))}
            </div> */}
        </div>
    )
}

export default Slider