/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Menu,
  Typography,
  IconButton,
  Grid,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert"

import CloseIcon from '@material-ui/icons/Close'
import AlertIcon from '@material-ui/icons/Warning'
import SeeMoreIcon from '@material-ui/icons/Visibility'

import { Creators as NotificationActions } from "../../store/ducks/notification";
import { Creators as AppActions } from '../../store/ducks/app';

import "./style.sass";

const NotificationsMenu = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [openNotificationId, setOpenNotificationId] = useState(null);

  const notifications = useSelector((state) => state.notification.list);

  const expandNotification = (notification) => {
    setOpenNotificationId(notification._id === openNotificationId ? null : notification._id)
    if(!notification.read)
      dispatch(NotificationActions.readNotification(notification._id))
  };

  const deleteNotification = (notification) => {
    dispatch(NotificationActions.deleteNotification(notification._id))
  };

  const showData = (data) => {
    dispatch(AppActions.setSelectedTutors(data.tutors))
    dispatch(AppActions.setSelectedCourses(data.courseIds))
    dispatch(AppActions.setSelectedClasses(data.classIds))
    dispatch(AppActions.setSelectedStudents(data.studentIds))
    history.push('/')
  };

  useEffect(() => {
    dispatch(NotificationActions.getNotifications());
  }, []);

  return (
    <Menu
      anchorEl={props.anchorEl}
      className="notifications-menu"
      keepMounted
      open={Boolean(props.anchorEl)}
      onClose={props.onClose}
    >

      {notifications.length === 0 && (
        <Alert severity="warning">Nenhuma notificação</Alert>
      )}
      
      {notifications &&
        notifications.map((notification) => (
          <Accordion
            key={notification._id}
            expanded={openNotificationId === notification._id}
            onChange={() => expandNotification(notification)}
          >
            <AccordionSummary>
              <div className="icon-column">
                <AlertIcon className="alert-icon"/>
              </div>
              <div className="text">
                <Typography variant="h6">{notification.title}</Typography>
                <IconButton 
                  size="small" 
                  className="read-button"
                  onClick={(event) => {event.stopPropagation(); deleteNotification(notification);}}>
                  <CloseIcon/>
                </IconButton>
              </div>
            </AccordionSummary>
            <AccordionDetails className="details">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    {notification.body}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => showData(notification.data)}>
                      <SeeMoreIcon/>
                      Ver no dashboard
                  </Button>
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => showData(notification.data)}>
                      <AlertIcon/>
                      Visualizar alerta
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
    </Menu>
  );
};

NotificationsMenu.defaultProps = {
  anchorEl: null,
  onClose: () => {},
};

export default NotificationsMenu;
