import request from "request-promise";
import axios from "axios";

const _apiRequest = ({ method, path, data }) => {
  let options = {
    method: method,
    uri: process.env.REACT_APP_API_HOST + path,
    json: true,
  };

  if (method === "get") options.qs = data;
  else options.body = data;

  if (window.localStorage.getItem("token")) {
    options.auth = {
      bearer: window.localStorage.getItem("token"),
    };
  }

  return request(options)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      let response = new Error(error.error);
      response.code = error.statusCode;
      throw response;
    });
};

const backendRequest = axios.create({
  baseURL: process.env.REACT_APP_API_NEW_HOST,
  headers: { Authorization: "Bearer " + window.localStorage.getItem("token") },
});

backendRequest.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Auth
const login = async (payload) =>
  await backendRequest.post("/auth/login", payload);
const getAuthenticatedUser = async (payload) =>
  await backendRequest.get("/auth/authenticated");

// Clients
const getClients = async () => await backendRequest.get("/companies");
const createCompany = async (payload) =>
  await backendRequest.post("/companies", payload);
const getCompanyById = async (payload) =>
  await backendRequest.get(`/companies/${payload}`);
const updateCompany = async (payload) =>
  await backendRequest.patch(`/companies/${payload.id}`, payload);
const getFeatureFlags = async () => await backendRequest.get("/feature-flags");

// Tutor
const getTutors = (payload) =>
  _apiRequest({ method: "post", path: `/tutor`, data: payload });
const getAllTutors = (payload) =>
  _apiRequest({ method: "post", path: `/tutor/all`, data: payload });
// const getTutorsEvasion = (payload) =>
//     _apiRequest({method: 'post', path: `/tutor/evasion`, data: payload})

// Course
const getCourses = (payload) =>
  _apiRequest({ method: "post", path: `/course`, data: payload });
const getAllCourses = (payload) =>
  _apiRequest({ method: "post", path: `/course/all`, data: payload });
// const getCoursesEvasion = (payload) =>
//     _apiRequest({method: 'post', path: `/course/evasion`, data: payload})

// Class
const getClasses = (payload) =>
  _apiRequest({ method: "post", path: `/class`, data: payload });
const getAllClasses = (payload) =>
  _apiRequest({ method: "post", path: `/class/all`, data: payload });

// Subjects
const getSubjects = (payload) =>
  _apiRequest({ method: "post", path: `/subject`, data: payload });
const getAllSubjects = (payload) =>
  _apiRequest({ method: "post", path: `/subject/all`, data: payload });

// Student
const getStudents = (payload) =>
  _apiRequest({ method: "post", path: `/student`, data: payload });
const getDisability = (payload) =>
  _apiRequest({ method: "get", path: `/student/disability`, data: payload });
const getStudentsReport = (payload) =>
  _apiRequest({ method: "get", path: `/student/report`, data: payload });
// const getStudentsEvasion = (payload) =>
//     _apiRequest({method: 'post', path: `/student/evasion`, data: payload})

// User
const getUsers = async (payload) =>
  await backendRequest.get("/users", { params: payload });
const newUser = async (payload) => await backendRequest.post("/users", payload);
const deleteUser = async (payload) =>
  await backendRequest.delete(`/users/${payload}`);
const updateUser = async (payload) =>
  await backendRequest.patch(`/users/${payload.id}`, payload.data);
const sendAccess = async (payload) =>
  await backendRequest.post("/users/send-access", payload);

// _apiRequest({method: 'delete', path: `/user/${payload}`})

// const enableUser = (payload) =>
//     _apiRequest({method: 'patch', path: `/user/${payload}/enable`})
// const disableUser = (payload) =>
//     _apiRequest({method: 'patch', path: `/user/${payload}/disable`})
// const changeUserRoleToMenager = (payload) =>
//     _apiRequest({method: 'patch', path: `/user/${payload}/change-to-admin`})
// const changeUserRoleToTutor = (payload) =>
//     _apiRequest({method: 'patch', path: `/user/${payload}/change-to-tutor`})
// const sendAccess = (payload) =>
//     _apiRequest({method: 'post', path: `/user/send-access`, data: payload})
// const saveMyData = (payload) =>
//     _apiRequest({method: 'post', path: `/user/me`, data: payload})
// const changePassword = (payload) =>
//     _apiRequest({method: 'post', path: `/user/me/password`, data: payload})
// const changeProfilePicture = (payload) =>
//     _apiRequest({method: 'post', path: `/user/me/picture`, data: payload})

// Metrics
const getMetrics = (payload) =>
  _apiRequest({ method: "post", path: "/metrics", data: payload });

// Indicators
const getIndicators = (payload) =>
  _apiRequest({ method: "post", path: "/indicators", data: payload });

// Period Chart & Batchs
const getBatchs = (payload) => _apiRequest({ method: "get", path: "/batch" });
const getBatchInfo = (payload) =>
  _apiRequest({
    method: "post",
    path: `/batch/${payload.batchId}`,
    data: payload,
  });

// Chatbot
const chatbot = (payload) =>
  _apiRequest({ method: "post", path: "/chatbot", data: payload });

// Notification
const getNotifications = (payload) =>
  _apiRequest({ method: "get", path: "/notification" });
const readNotification = (payload) =>
  _apiRequest({ method: "get", path: `/notification/${payload}/read` });
const deleteNotification = (payload) =>
  _apiRequest({ method: "delete", path: `/notification/${payload}` });

//Alert
const getAlertActions = (payload) =>
  _apiRequest({ method: "get", path: "/alert-action", data: payload });
const changeAlertActions = (payload) =>
  _apiRequest({
    method: "put",
    path: `/alert-action/${payload.id}`,
    data: payload,
  });
const getAlerts = (payload) =>
  _apiRequest({ method: "get", path: "/alert", data: payload });
const saveAlert = (payload) =>
  _apiRequest({ method: "post", path: "/alert", data: payload });
const deleteAlert = (payload) =>
  _apiRequest({ method: "delete", path: `/alert/${payload}` });
const getRuleFields = (payload) =>
  _apiRequest({ method: "get", path: `/alert/rule-fields` });
const enableAlert = (payload) =>
  _apiRequest({ method: "patch", path: `/alert/${payload}/enable` });
const disableAlert = (payload) =>
  _apiRequest({ method: "patch", path: `/alert/${payload}/disable` });
const remonitorAlert = (payload) =>
  _apiRequest({ method: "patch", path: `/alert/${payload}/remonitor` });
const duplicateAlert = (payload) =>
  _apiRequest({ method: "patch", path: `/alert/${payload}/duplicate` });

//Chat
const sendMessage = (payload) =>
  _apiRequest({ method: "post", path: "/chatbot", data: payload });

//BatchLogs
const getBatchlogs = (payload) =>
  _apiRequest({ method: "get", path: "/batchlogs" });

//Consultant
const getQuantitative = (payload) =>
  _apiRequest({ method: "post", path: "/quantitative", data: payload });

//Classification
const getClassification = (payload) =>
  _apiRequest({ method: "get", path: "/classification", data: payload });

//Feedback
const getFeedbackTipOrQuestion = (payload) =>
  _apiRequest({ method: "get", path: "/feedback/tip_or_question" });
const confirmFeedbackTipDelivered = (payload) =>
  _apiRequest({
    method: "put",
    path: `/feedback/tip/${payload}/confirm_delivered`,
  });
const saveFeedbackAnswer = (payload) =>
  _apiRequest({
    method: "put",
    path: `/feedback/${payload._id}`,
    data: payload,
  });

export default {
  // Auth
  login,
  getAuthenticatedUser,
  // Clients
  getClients,
  createCompany,
  getCompanyById,
  updateCompany,
  getFeatureFlags,

  // Tutor
  getTutors,
  getAllTutors,
  // getTutorsEvasion,
  // Course
  getCourses,
  getAllCourses,
  // getCoursesEvasion,
  // Class
  getClasses,
  getAllClasses,
  // Subject
  getSubjects,
  getAllSubjects,
  // Student
  getStudents,
  getDisability,
  getStudentsReport,
  // getStudentsEvasion,
  // User
  getUsers,
  newUser,
  updateUser,
  deleteUser,
  sendAccess,

  // enableUser,
  // disableUser,
  // changeUserRoleToMenager,
  // changeUserRoleToTutor,
  // saveMyData,
  // changePassword,
  // changeProfilePicture,

  // Indicators
  getIndicators,
  // Metrics
  getMetrics,
  // Batchs
  getBatchs,
  getBatchInfo,
  // Chatbot
  chatbot,
  // Notification
  getNotifications,
  readNotification,
  deleteNotification,
  //Alerts
  getAlertActions,
  changeAlertActions,
  getAlerts,
  saveAlert,
  deleteAlert,
  getRuleFields,
  enableAlert,
  disableAlert,
  remonitorAlert,
  duplicateAlert,
  //Chat
  sendMessage,
  //BatchLogs
  getBatchlogs,
  //Quantitative
  getQuantitative,
  //Classification
  getClassification,
  //Feedback
  getFeedbackTipOrQuestion,
  confirmFeedbackTipDelivered,
  saveFeedbackAnswer,
};
