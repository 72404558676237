/* eslint-disable react-hooks/exhaustive-deps */
import React, {  useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'

import {
  Fab,
} from '@material-ui/core'
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";

import { Creators as AppActions } from '../../store/ducks/app'

import './style.sass'

const SpeechButton = (props) => {
  const dispatch = useDispatch()

  const appSpeechActive = useSelector(state => state.app.appSpeechActive)
  const fieldSpeechName = useSelector(state => state.app.fieldSpeechName)
  
  const {
    transcript,
    listening,
  } = useSpeechRecognition()

  const startListening = () => {
    dispatch(AppActions.setFieldSpeech(props.fieldName))
    if(!appSpeechActive)
      SpeechRecognition.startListening()
  }
  
  const stopListening = () => {
    dispatch(AppActions.setFieldSpeech(null))
    if(!appSpeechActive)
      SpeechRecognition.stopListening()
  }
    
  useEffect(() => {
    if(fieldSpeechName === props.fieldName)
      props.callback(transcript)
  }, [transcript]);
    
  useEffect(() => {
    if(!listening)
      dispatch(AppActions.setFieldSpeech(null))
  }, [listening]);
    
  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return null
  }

  return (
    <div className="speech-button">
      {(fieldSpeechName !== props.fieldName) && (
        <Fab
        size="small" 
        onClick={startListening}>
          <MicIcon/>
        </Fab>
      )}
      {(fieldSpeechName === props.fieldName) && (
        <Fab
          size="small"
          onClick={stopListening}>
          {/* <MicOffIcon className="stop-icon"/> */}
          <MicIcon className="listening" />
        </Fab>
      )}
    </div>
  )
}

SpeechButton.defaultProps = {
  callback: () => {},
}

export default SpeechButton